import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { PlantDetailsComponent } from './plant-details/plant-details.component';
import { ResourcesComponent } from './resources/resources.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { PressComponent } from './press/press.component';
import { ContactComponent } from './contact/contact.component';
import { DonateComponent } from './donate/donate.component';
import { NurseryListComponent } from './nursery-list/nursery-list.component';
import { LinksComponent } from './links/links.component';
import { CommitteeComponent } from './about/committee/committee.component';
import { CriteriaComponent } from './about/criteria/criteria.component';
import { GroupsComponent } from './about/groups/groups.component';
import { StaffComponent } from './about/staff/staff.component';
import { PlantNotListedComponent } from './plant-not-listed/plant-not-listed.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { TermsComponent } from './terms/terms.component';
import { PosterComponent } from './poster/poster.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SearchnavComponent } from './searchnav/searchnav.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { AddNurseryComponent } from './add-nursery/add-nursery.component';
import { AddPlantComponent } from './add-plant/add-plant.component';
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'logout', component: HomeComponent
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'about/committee', component: CommitteeComponent
  },
  {
    path: 'about/criteria', component: CriteriaComponent
  },
  {
    path: 'about/groups', component: GroupsComponent
  },
  {
    path: 'about/staff', component: StaffComponent
  },
  {
    path: 'search', component: SearchComponent
  },
  {
    path: 'searchnav', component: SearchnavComponent
  },
  {
    path: 'search/results', component: SearchResultsComponent
  },
  {
    path: 'search/plant-details/:plantid', component: PlantDetailsComponent
  },
  {
    path: 'resources', component: ResourcesComponent
  },
  {
    path: 'search/advanced', component: AdvancedSearchComponent
  },
  {
    path: 'press', component: PressComponent
  },
  {
    path: 'press/terms', component: TermsComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'poster', component: PosterComponent
  },
  {
    path: 'thankyou', component: ThankyouComponent
  },
  {
    path: 'donate', component: DonateComponent
  },
  {
    path: 'resources/nurserylist', component: NurseryListComponent
  },
  {
    path: 'resources/links', component: LinksComponent
  },
  {
    path: 'resources/glossary', component: GlossaryComponent
  },
  {
    path: 'search/plantNotListed', component: PlantNotListedComponent
  },
  {
    path: 'search/print-view/:plantid', component: PrintViewComponent
  },
  {
    path: 'plantlists/view/:plantid', component: PlantDetailsComponent
  },
  {
    path: 'nursery/add', component: AddNurseryComponent
  },
  {
    path: 'nursery/edit/:nurseryId', component: AddNurseryComponent
  },
  {
    path: 'plant/add', component: AddPlantComponent
  },
  {
    path: 'plant/edit/:plantId', component: AddPlantComponent
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
