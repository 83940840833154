import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Nursery } from '../models/nursery_model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth.service';
import { NurseryService } from '../services/nursery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { HttpParams } from '@angular/common/http';
import { CommonName, PlantData, Synonym } from '../models/plant.model';
import { PlantService } from '../services/plant.service';
import { FileHolder } from '../image-upload/image-upload.component';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';

@Component({
  selector: 'app-add-plant',
  templateUrl: './add-plant.component.html',
  styleUrls: ['./add-plant.component.scss']
})
export class AddPlantComponent implements OnInit, AfterViewInit {
  plantForm: FormGroup;
  advForm: FormGroup;
  imageForm: FormGroup;
  cnameForm: FormGroup;
  synForm: FormGroup;
  submitted = false;
  imagesubmitted = false;
  cnamesubmitted = false;
  synsubmitted = false;
  currentPlantId = 0;
  currentPlant: PlantData;
  modalTitle = '';
  modalMessage = '';
  closeResult = '';
  saveButtonTitle = 'Add';
  active = 1;
  synonyms: Synonym[] = [];
  commonNames: CommonName[] = [];
  commonNamesArray = [];
  imageArray = [];
  botanicalName = '';
  primaryImage = '';
  landscapeImage = '';
  detailImage = '';
  primaryImageId = -1;
  landscapeImageId = -1;
  detailImageId = -1;
  uploadFailed = false;
  adUploaded = false;
  adPath = '';
  pdfSrc = '';
  uploadedPDF = false;
  uploadSrc = '';
  uploadResponse = '';
  showCatError = false;
  showCommonForm = false;
  editingCommon = false;
  showSynForm = false;
  editingSyn = false;

  waterData: Array<any> = [
    { name: 'Frequent', value: 'frequent' },
    { name: 'Regular', value: 'regular' },
    { name: 'Occasional', value: 'occasional' },
    { name: 'Drought-Tolerant', value: 'drought-tolerant' }
  ];
  sunData: Array<any> = [
    { name: 'Full Sun', value: 'full_sun' },
    { name: 'Light Shade', value: 'light_shade' },
    { name: 'Dappled Shade', value: 'dappled_shade' },
    { name: 'Open Shade', value: 'open_shade' },
    { name: 'Deep Shade', value: 'deep_shade' }
  ];
  soilData: Array<any> = [
    { name: 'Clay', value: 'clay' },
    { name: 'Wet', value: 'wet' },
    { name: 'Moist', value: 'moist' },
    { name: 'Dry', value: 'dry' },
    { name: 'Sandy', value: 'sandy' },
    { name: 'Well-Drained', value: 'well-drained'}
  ];

  habitData: Array<any> = [
    { name: 'Clumping', value: 'clumping'},
    { name: 'Columnar', value: 'columnar'},
    { name: 'Compact', value: 'compact'},
    { name: 'Mounding', value: 'mounding'},
    { name: 'Narrow', value: 'narrow'},
    { name: 'Pyramidal', value: 'pyramidal'},
    { name: 'Round', value: 'round'},
    { name: 'Spreading', value: 'spreading'},
    { name: 'Upright', value: 'upright'},
    { name: 'Vase Shaped', value: 'vase-shaped'},
    { name: 'Vining', value: 'vining'},
    { name: 'Weeping', value: 'weeping'}
  ];
  wildlifeData: Array<any> = [
    { name: 'Bees', value: 'bees'},
    { name: 'Birds', value: 'birds'},
    { name: 'Butterflies', value: 'butterflies'},
    { name: 'Hummingbirds', value: 'hummingbirds'}
  ];
  pestData: Array<any> = [
    { name: 'Aphids', value: 'aphids'},
    { name: 'Rabbits', value: 'rabbits'},
    { name: 'Deer', value: 'deer'},
    { name: 'Slug', value: 'slug'},
    { name: 'Snail', value: 'snail'}
  ];
  flowerColorData: Array<any> = [
    {value : 'black', name: 'Black'},
    {value : 'blue', name: 'Blue'},
    {value : 'brown', name: 'Brown'},
    {value : 'cream', name: 'Cream'},
    {value : 'green', name: 'Green'},
    {value : 'lavender', name: 'Lavender'},
    {value : 'orange', name: 'Orange'},
    {value : 'pink', name: 'Pink'},
    {value : 'purple', name: 'Purple'},
    {value : 'red', name: 'Red'},
    {value : 'rose', name: 'Rose'},
    {value : 'violet', name: 'Violet'},
    {value : 'white', name: 'White'},
    {value : 'yellow', name: 'Yellow'}
  ];
  flowerTimeData: Array<any> = [
    {value : 'early_winter', name: 'Early Winter'},
    {value : 'winter', name: 'Winter'},
    {value : 'late_winter', name: 'Late Winter'},
    {value : 'late_winter_early_spring', name: 'Winter-Spring'},
    {value : 'early_spring', name: 'Early Spring'},
    {value : 'spring', name: 'Spring'},
    {value : 'late_spring', name: 'Late Spring'},
    {value : 'late_spring_early_summer', name: 'Spring-Summer'},
    {value : 'early_summer', name: 'Early Summer'},
    {value : 'summer', name: 'Summer'},
    {value : 'late_summer', name: 'Late Summer'},
    {value : 'late_summer_early_fall', name: 'Summer-Fall'},
    {value : 'early_fall', name: 'Early Fall'},
    {value : 'fall', name: 'Fall'},
    {value : 'late_fall', name: 'Late Fall'},
    {value : 'late_fall_early_winter', name: 'Fall-Winter'}
  ];
  romanticSeasonsData: Array<any> = [
    {value : 'early_winter', name: 'Early Winter'},
    {value : 'winter', name: 'Winter'},
    {value : 'late_winter', name: 'Late Winter'},
    {value : 'late_winter_early_spring', name: 'Winter-Spring'},
    {value : 'early_spring', name: 'Early Spring'},
    {value : 'spring', name: 'Spring'},
    {value : 'late_spring', name: 'Late Spring'},
    {value : 'late_spring_early_summer', name: 'Spring-Summer'},
    {value : 'early_summer', name: 'Early Summer'},
    {value : 'summer', name: 'Summer'},
    {value : 'late_summer', name: 'Late Summer'},
    {value : 'late_summer_early_fall', name: 'Summer-Fall'},
    {value : 'early_fall', name: 'Early Fall'},
    {value : 'fall', name: 'Fall'},
    {value : 'late_fall', name: 'Late Fall'},
    {value : 'late_fall_early_winter', name: 'Fall-Winter'}
  ];
  foliageColorData: Array<any> = [
    {value : 'black', name: 'Black'},
    {value : 'blue', name: 'Blue'},
    {value : 'bronze', name: 'Bronze'},
    {value : 'burgundy', name: 'Burgundy'},
    {value : 'chartreuse', name: 'Chartreuse'},
    {value : 'colorful', name: 'Colorful'},
    {value : 'dark green', name: 'Dark Green'},
    {value : 'gold', name: 'Gold'},
    {value : 'green', name: 'Green'},
    {value : 'purple', name: 'Purple'},
    {value : 'red', name: 'Red'},
    {value : 'silver', name: 'Silver'},
    {value : 'variegated', name: 'Variegated'},
    {value : 'white', name: 'White'}
  ];
  foliageTextureData: Array<any> = [
    {value: 'bold', name: 'Bold'},
    {value: 'fine', name: 'Fine'},
    {value: 'frilly', name: 'Frilly'},
    {value: 'shiny', name: 'Shiny'},
    {value: 'unique', name: 'Unique'}
  ];
  designUseData: Array<any> = [
    {value: 'containers', name: 'Containers'},
    {value: 'street tree', name: 'Street Tree'},
    {value: 'perennial border', name: 'Perennial Border'},
    {value: 'woodland', name: 'Woodland'},
    {value: 'groundcover', name: 'Groundcover'},
    {value: 'hedge', name: 'Hedge'},
    {value: 'cut flower - dried', name: 'Cut Flower - Dried'},
    {value: 'cut flower - fresh', name: 'Cut Flower - Fresh'},
    {value: 'focal point', name: 'Focal Point'},
    {value: 'structural', name: 'Structural'},
    {value: 'specimen', name: 'Specimen'},
    {value: 'mass-planting', name: 'Mass-Planting'},
    {value: 'hedge_privacy', name: 'Hedge - Privacy'},
    {value: 'hedge_low', name: 'Hedge - Low'},
    {value: 'hedge_formal', name: 'Hedge - Formal'},
    {value: 'hedge_informal', name: 'Hedge - Informal'},
    {value: 'color', name: 'Color'},
    {value: 'form', name: 'Form'},
    {value: 'texture', name: 'Texture'},
    {value: 'powerline_friendly_trees', name: 'Powerline Friendly Trees'},
    {value: 'urban_sturdy', name: 'Urban Sturdy'},
    {value: 'snug_spaces', name: 'Snug Spaces'}
  ];
  scentData: Array<any> = [
    {value: 'flowers', name: 'Fragrant Flowers'},
    {value: 'foliage', name: 'Fragrant Foliage'},
    {value: 'floral', name: 'Floral Scent'},
    {value: 'fresh', name: 'Fresh Scent'},
    {value: 'spicy', name: 'Spicy Scent'},
    {value: 'woodsy', name: 'Woodsy Scent'}
  ];
  springData: Array<any> = [
    {value: 'spring_flowers', name: 'Spring Flowers'},
    {value: 'spring_foliage', name: 'Spring Foliage'},
    {value: 'march', name: 'March'},
    {value: 'april', name: 'April'},
    {value: 'may', name: 'May'}
  ];
  brightData: Array<any> = [
    {value: 'dappled_shade_regular_water', name: 'Dappled Shade Regular Water'},
    {value: 'deep_shade_regular_water', name: 'Deep Shade Regular Water'},
    {value: 'dappled_shade_occasional_water', name: 'Dappled Shade Occasional Water'},
    {value: 'deep_shade_occasional_water', name: 'Deep Shade Occasional Water'},
    {value: 'dappled_shade_drought_tolerant', name: 'Dappled Shade Drought Tolerant'},
    {value: 'deep_shade_drought_tolerant', name: 'Deep Shade Drought Tolerant'}
  ];
  themeData: Array<any> = [
    {value: 'foliage', name: 'Foliage'},
    {value: 'sun_drought', name: 'Sun Drought'},
    {value: 'shade', name: 'Shade'},
    {value: 'small_urban', name: 'Small Urban'},
    {value: 'fragrance', name: 'Fragrance'},
    {value: 'summer_interest', name: 'Summer Interest'},
    {value: 'autumn_interest', name: 'Autumn Interest'},
    {value: 'winter_interest', name: 'Winter Interest'},
    {value: 'spring_interest', name: 'Spring Interest'},
    {value: 'bright_ideas', name: 'Bright Ideas'},
    {value: 'perfect_containers', name: 'Perfect Containers'},
    {value: 'flora_romantica', name: 'Flora Romantica'}
  ];
  typeData: Array<any> = [
    {value: '1', name: 'Primary'},
    {value: '3', name: 'Landscape'},
    {value: '2', name: 'Detail'}
  ];
  private infoModalRef: NgbModalRef;
  private errorModalRef: NgbModalRef;
  private confirmModalRef: NgbModalRef;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private plantService: PlantService,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private confirmationDialogService: ConfirmationDialogService,
              private router: Router) {
    this.plantForm = this.fb.group({
      publish: ['No'],
      plantId: [{value: '', disabled: true}],
      plantFamily: [''],
      familyCommonName: [''],
      genus: ['', Validators.required],
      crossGenus: [''],
      crossSpecificEpithet: ['No'],
      specificEpithet: [''],
      infraspecificEpithetDesignator: [''],
      infraspecificEpithet: ['', Validators.maxLength(50)],
      crossSpecies: ['', Validators.maxLength(50)],
      cultivar: ['', Validators.maxLength(200)],
      tradeName: ['', Validators.maxLength(55)],
      trademarkName: ['', Validators.maxLength(50)],
      registeredName: ['', Validators.maxLength(55)],
      plantPatentNumber: ['', Validators.maxLength(10)],
      plantPatentNumberAppliedFor: ['', Validators.maxLength(11)],
      plantBreedersRights: [''],
      plantNameGroup: ['', Validators.maxLength(200)],
      formField: ['', Validators.maxLength(150)],
      pruningGroup: ['', Validators.maxLength(55)],
      nativeToGppRegion: ['', Validators.maxLength(22)],
      plantType: [''],
      foliageType: [''],
      growthHabit: [''],
      sunExposure: ['', Validators.maxLength(255)],
      waterRequirements: ['', Validators.maxLength(255)],
      soilRequirements: ['', Validators.maxLength(255)],
      flowerShowy: [''],
      flowerTime: [''],
      romanticSeasons: [''],
      fruitAttractive: [''],
      fragrance: ['', Validators.maxLength(45)],
      seasonalInterest: ['', Validators.maxLength(200)],
      barkInterest: [''],
      plantWidthAt10: ['', Validators.maxLength(11)],
      plantHeightAt10: ['', Validators.maxLength(11)],
      plantWidthMax: ['', Validators.maxLength(11)],
      plantHeightMax: ['', Validators.maxLength(11)],
      zoneLow: ['', Validators.maxLength(2)],
      zoneHigh: ['', Validators.maxLength(2)],
      growingNotes: [''],
      cultureNotes: [''],
      qualities: [''],
      plantCombinations: [''],
      colorContrast: [''],
      colorPartners: [''],
      nominatedBy: ['', Validators.maxLength(100)],
      nominatedForYear: ['', Validators.maxLength(4)],
      committee: [''],
      advisoryGroup: [''],
      plantEvaluationOrTrial: [''],
      referencesNameValidation: [''],
      status: ['', Validators.maxLength(45)],
      evaluationAvailable: ['', Validators.maxLength(5)],
      gppHistory: [''],
      gppYear: ['', Validators.maxLength(4)],
      programmedSearch: ['', Validators.maxLength(22)],
      geekNotes: [''],
      sort: ['', Validators.maxLength(150)]
    });
    this.advForm = this.fb.group({
      waterCheckArray: this.fb.array([]),
      sunCheckArray: this.fb.array([]),
      soilCheckArray: this.fb.array([]),
      habitCheckArray: this.fb.array([]),
      wildlifeCheckArray: this.fb.array([]),
      pestResistanceCheckArray: this.fb.array([]),
      flowerTimeCheckArray: this.fb.array([]),
      flowerColorCheckArray: this.fb.array([]),
      foliageColorCheckArray: this.fb.array([]),
      foliageTextureCheckArray: this.fb.array([]),
      designUseCheckArray: this.fb.array([]),
      scentCheckArray: this.fb.array([]),
      springCheckArray: this.fb.array([]),
      themeCheckArray: this.fb.array([]),
      brightCheckArray: this.fb.array([]),
      romanticSeasonsCheckArray: this.fb.array([])
    });

    this.imageForm = this.fb.group({
      filename: ['', Validators.required],
      description: ['', Validators.maxLength(255)],
      copyright: ['', Validators.maxLength(255)],
      photographer: ['', Validators.maxLength(255)],
      season: [''],
      typeArray: this.fb.array([], Validators.min(1))
    });
    this.cnameForm = this.fb.group({
      commonName: ['', Validators.required],
      cnameId: [''],
      plantId: ['', Validators.required]
    });
    this.synForm = this.fb.group({
      family: [''],
      familyCommonName: [''],
      genus: ['', Validators.required],
      crossGenus: [''],
      crossSpecificEpithet: [''],
      specificEpithet: [''],
      infraspecificEpithetDesignator: [''],
      infraspecificEpithet: ['', Validators.maxLength(50)],
      crossSpecies: ['', Validators.maxLength(50)],
      cultivar: ['', Validators.maxLength(200)],
      tradeName: ['', Validators.maxLength(55)],
      trademarkName: ['', Validators.maxLength(50)],
      registeredName: ['', Validators.maxLength(55)],
      plantId: ['', Validators.required],
      synonymId: ['']
    })
    this.route.paramMap.subscribe((params) => {
      this.ngZone.run(() => {
        this.currentPlantId = +params.get('plantId'); // (+) converts string 'id' to a number
        if (this.currentPlantId > 0) {
          this.plantService.getRawPlantById(this.currentPlantId)
            .subscribe((res) => {
              this.ngZone.run(() => {
                if (res.hasOwnProperty('rawplant')) {
                  this.saveButtonTitle = 'Save';
                  this.currentPlant = res.rawplant;
                  this.botanicalName = this.plantService.render_botanical_name(null, null, this.currentPlant);
                  if (this.currentPlant.publish === 'Yes') {
                    this.f.publish.setValue('Yes');
                  } else {
                    this.f.publish.setValue('No');
                  }
                  // set all of the other fields
                  this.f.plantId.setValue(this.currentPlant.id);
                  this.f.plantFamily.setValue(this.currentPlant.family);
                  this.f.familyCommonName.setValue(this.currentPlant.family_common_name);
                  this.f.genus.setValue(this.currentPlant.genus);
                  this.f.crossGenus.setValue(this.currentPlant.cross_genus);
                  this.f.crossSpecificEpithet.setValue(this.currentPlant.cross_specific_epithet);
                  this.f.specificEpithet.setValue(this.currentPlant.specific_epithet);
                  this.f.infraspecificEpithetDesignator.setValue(this.currentPlant.infraspecific_epithet_designator);
                  this.f.infraspecificEpithet.setValue(this.currentPlant.infraspecific_epithet);
                  this.f.crossSpecies.setValue(this.currentPlant.cross_species);
                  this.f.cultivar.setValue(this.currentPlant.cultivar);
                  this.f.tradeName.setValue(this.currentPlant.trade_name);
                  this.f.trademarkName.setValue(this.currentPlant.trademark_name);

                  this.f.registeredName.setValue(this.currentPlant.registered_name);
                  this.f.plantPatentNumber.setValue(this.currentPlant.plant_patent_number);
                  this.f.plantPatentNumberAppliedFor.setValue(this.currentPlant.plant_patent_number_applied_for);
                  this.f.plantBreedersRights.setValue(this.currentPlant.plant_breeders_rights);
                  this.f.plantNameGroup.setValue(this.currentPlant.plantname_group);
                  this.f.formField.setValue(this.currentPlant.form);
                  this.f.pruningGroup.setValue(this.currentPlant.division_pruning_group);
                  this.f.nativeToGppRegion.setValue(this.currentPlant.native_to_gpp_region);
                  this.f.plantType.setValue(this.currentPlant.plant_type);
                  this.f.foliageType.setValue(this.currentPlant.foliage_type);

                  this.f.growthHabit.setValue(this.currentPlant.growth_habit);
                  this.f.sunExposure.setValue(this.currentPlant.sun_exposure);
                  this.f.waterRequirements.setValue(this.currentPlant.water_requirements);
                  this.f.soilRequirements.setValue(this.currentPlant.soil_requirements);
                  this.f.flowerShowy.setValue(this.currentPlant.flower_showy);
                  this.f.flowerTime.setValue(this.currentPlant.flower_time);
                  this.f.romanticSeasons.setValue(this.currentPlant.romantic_seasons);
                  this.f.fruitAttractive.setValue(this.currentPlant.fruit_seedhead_attractive);
                  this.f.fragrance.setValue(this.currentPlant.fragrance);
                  this.f.seasonalInterest.setValue(this.currentPlant.seasonal_interest);
                  this.f.barkInterest.setValue(this.currentPlant.bark_interest);

                  this.f.plantWidthAt10.setValue(this.currentPlant.plant_width_at_10);
                  this.f.plantHeightAt10.setValue(this.currentPlant.plant_height_at_10);
                  this.f.plantWidthMax.setValue(this.currentPlant.plant_width_max);
                  this.f.plantHeightMax.setValue(this.currentPlant.plant_height_max);
                  this.f.zoneLow.setValue(this.currentPlant.zone_low);
                  this.f.zoneHigh.setValue(this.currentPlant.zone_high);
                  this.f.growingNotes.setValue(this.currentPlant.growing_notes);
                  this.f.cultureNotes.setValue(this.currentPlant.culture_notes);
                  this.f.qualities.setValue(this.currentPlant.qualities);
                  this.f.plantCombinations.setValue(this.currentPlant.plant_combinations);

                  this.f.colorContrast.setValue(this.currentPlant.color_contrast);
                  this.f.colorPartners.setValue(this.currentPlant.color_partners);
                  this.f.nominatedBy.setValue(this.currentPlant.nominator);
                  this.f.nominatedForYear.setValue(this.currentPlant.nominated_for_year);
                  this.f.committee.setValue(this.currentPlant.committee);
                  this.f.advisoryGroup.setValue(this.currentPlant.advisory_group);
                  this.f.plantEvaluationOrTrial.setValue(this.currentPlant.eval_trial);
                  this.f.referencesNameValidation.setValue(this.currentPlant.gpp_references);
                  this.f.status.setValue(this.currentPlant.status);
                  this.f.evaluationAvailable.setValue(this.currentPlant.evaluation_available);

                  this.f.gppHistory.setValue(this.currentPlant.gpp_history);
                  this.f.gppYear.setValue(this.currentPlant.gpp_year);
                  this.f.programmedSearch.setValue(this.currentPlant.programmed_search);
                  this.f.geekNotes.setValue(this.currentPlant.geek_notes);
                  this.f.sort.setValue(this.currentPlant.sort);
                  this.synonyms = this.currentPlant.synonyms;
                  this.commonNames = this.currentPlant.commonNames;
                  this.commonNames.forEach((aCn) => {
                    this.commonNamesArray.push(aCn.common_name);
                  });
                  this.imageArray = res.images;
                  this.primaryImage = '';
                  this.primaryImageId = -1;
                  this.landscapeImage = '';
                  this.landscapeImageId = -1;
                  this.detailImage = '';
                  this.detailImageId = -1;
                  this.imageArray.forEach((aImage) => {
                    if (aImage.category === 'primary') {
                      this.primaryImage = aImage.imageSource;
                      this.primaryImageId = aImage.imageId;
                    } else if (aImage.category === 'landscape') {
                      this.landscapeImage = aImage.imageSource;
                      this.landscapeImageId = aImage.imageId;
                    } else if (aImage.category === 'detail') {
                      this.detailImage = aImage.imageSource;
                      this.detailImageId = aImage.imageId;
                    }
                  });
                  this.setupChecks(this.currentPlant.plantWater, 'waterCheckArray');
                  this.setupChecks(this.currentPlant.plantSun, 'sunCheckArray');
                  this.setupChecks(this.currentPlant.plantSoil, 'soilCheckArray');
                  this.setupChecks(this.currentPlant.plantGrowthHabit, 'habitCheckArray');
                  this.setupChecks(this.currentPlant.plantWildlife, 'wildlifeCheckArray');
                  this.setupChecks(this.currentPlant.plantPestResistance, 'pestResistanceCheckArray');
                  this.setupChecks(this.currentPlant.plantFlowerColor, 'flowerColorCheckArray');
                  this.setupChecks(this.currentPlant.plantFlowerTime, 'flowerTimeCheckArray');
                  this.setupChecks(this.currentPlant.plantFoliageColor, 'foliageColorCheckArray');
                  this.setupChecks(this.currentPlant.plantFoliageTexture, 'foliageTextureCheckArray');
                  this.setupChecks(this.currentPlant.plantDesignUse, 'designUseCheckArray');
                  this.setupChecks(this.currentPlant.plantScent, 'scentCheckArray');
                  this.setupChecks(this.currentPlant.plantSpring, 'springCheckArray');
                  this.setupChecks(this.currentPlant.plantTheme, 'themeCheckArray');
                  this.setupChecks(this.currentPlant.plantBright, 'brightCheckArray');
                  this.setupChecks(this.currentPlant.plantRomanticSeasons, 'romanticSeasonsCheckArray');

                  // save the plantId in the common name form
                  this.cnf.plantId.setValue(this.currentPlantId);
                  this.synf.plantId.setValue(this.currentPlantId);
                }
              });
            });
        }
      });
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.addEventListener('dragover', (e: DragEvent) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e: DragEvent) => {
      e.preventDefault();
    }, false);
  }

  onUploadFinished(file: FileHolder) {
    // check the response
    if (file.serverResponse.response.status === 200) {
      this.uploadFailed = false;
      this.adUploaded = true;
      this.adPath = file.serverResponse.response.body.replace(/\n/g, '');
      this.uploadedPDF = false;
      this.uploadSrc = file.src;
      this.imageForm.get('filename').setValue(this.adPath);
    } else {
      this.adUploaded = false;
      this.adPath = '';
      this.uploadResponse = file.serverResponse.response.error;
      this.uploadFailed = true;
    }
  }

  get f() {
    return this.plantForm.controls;
  }

  get imf() {
    return this.imageForm.controls;
  }

  get cnf() {
    return this.cnameForm.controls;
  }

  get synf() {
    return this.synForm.controls;
  }

  deletePrimary() {
    this.deleteImage(this.primaryImageId);
  }
  deleteLandscape() {
    this.deleteImage(this.landscapeImageId);
  }
  deleteDetail() {
    this.deleteImage(this.detailImageId);
  }

  deleteImage(imageId) {
    this.plantService.deleteImage(imageId).subscribe(res => {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.infoModalRef = this.modalService.open(InfoModalComponent);
            this.infoModalRef.componentInstance.infoTitle = 'Image Deleted.';
            this.infoModalRef.componentInstance.infoMessage = 'The image was deleted successfully.';
          }, 500);
          if (res.hasOwnProperty('images')) {
            this.imageArray = res.images;
            this.primaryImage = '';
            this.primaryImageId = -1;
            this.landscapeImage = '';
            this.landscapeImageId = -1;
            this.detailImage = '';
            this.detailImageId = -1;
            this.imageArray.forEach((aImage) => {
              if (aImage.category === 'primary') {
                this.primaryImage = aImage.imageSource;
                this.primaryImageId = aImage.imageId;
              } else if (aImage.category === 'landscape') {
                this.landscapeImage = aImage.imageSource;
                this.landscapeImageId = aImage.imageId;
              } else if (aImage.category === 'detail') {
                this.detailImage = aImage.imageSource;
                this.detailImageId = aImage.imageId;
              }
            });
          }
        });
      },
      error => {
        setTimeout(() => {
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = 'delete a image. Error Message was: ' + error;
          this.errorModalRef.componentInstance.longErrorMessage = '';
        }, 500);
      });

  }
  setupChecks(plantCharacteristic, checkArrayName) {
    const checkArray: FormArray = this.advForm.get(checkArrayName) as FormArray;
    plantCharacteristic.forEach((aChar) => {
      const name = aChar.name;
      checkArray.push(new FormControl(name));
    });
  }

  saveImage() {
    this.imagesubmitted = true;
    const val = this.imageForm.value;
    if ((this.imageForm.valid) && (this.typeCount() > 0)) {
      let formData = new HttpParams();
      Object.keys(this.imageForm.controls).forEach(key => {
        const abstractControl = this.imageForm.get(key);
        if ((abstractControl.value != null) && (abstractControl.value.length > 0)) {
          formData = formData.append(key, abstractControl.value);
        }
      });
      const cats = this.getCategoriesSelected();
      formData = formData.append('categories', cats);
      this.plantService.saveImage(this.currentPlantId, formData).subscribe(res => {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.infoModalRef = this.modalService.open(InfoModalComponent);
            this.infoModalRef.componentInstance.infoTitle = 'Image Saved.';
            this.infoModalRef.componentInstance.infoMessage = 'The image was added successfully.';
          }, 500);
          if (res.hasOwnProperty('images')) {
            this.imageArray = res.images;
            this.primaryImage = '';
            this.primaryImageId = -1;
            this.landscapeImage = '';
            this.landscapeImageId = -1;
            this.detailImage = '';
            this.detailImageId = -1;
            this.imageArray.forEach((aImage) => {
              if (aImage.category === 'primary') {
                this.primaryImage = aImage.imageSource;
                this.primaryImageId = aImage.imageId;
              } else if (aImage.category === 'landscape') {
                this.landscapeImage = aImage.imageSource;
                this.landscapeImageId = aImage.imageId;
              } else if (aImage.category === 'detail') {
                this.detailImage = aImage.imageSource;
                this.detailImageId = aImage.imageId;
              }
            });
          }
        });
      },
      error => {
        setTimeout(() => {
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = 'add new image. Error Message was: ' + error;
          this.errorModalRef.componentInstance.longErrorMessage = '';
        }, 500);
      });
    }
  }

  addNewCommon() {
    this.cnf.commonName.setValue('');
    this.showCommonForm = true;
  }

  addNewSyn() {
    this.synForm.reset();
    this.synf.plantId.setValue(this.currentPlantId);
    this.showSynForm = true;
  }
  saveCName() {
    this.cnamesubmitted = true;
    const val = this.cnameForm.value;
    if (this.cnameForm.valid) {
      let formData = new HttpParams()
      Object.keys(this.cnameForm.controls).forEach(key => {
        const abstractControl = this.cnameForm.get(key);
        formData = formData.append(key, abstractControl.value);
      });
      const cnameId = this.cnf.cnameId.value;
      if (this.editingCommon) {
        this.plantService.updateCommonName(formData, cnameId).subscribe(res => {
            this.ngZone.run(() => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Common Name Changes Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The Common Name was updated successfully.';
              }, 500);
              if (res.hasOwnProperty('commonNames')) {
                this.commonNames = res.commonNames;
                this.commonNamesArray = [];
                this.commonNames.forEach((aCn) => {
                  this.commonNamesArray.push(aCn.common_name)
                });
              }
              this.showCommonForm = false;
              this.editingCommon = false;
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'update Common Name. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });

      } else {
        this.plantService.addNewCommonName(formData).subscribe(res => {
            this.ngZone.run(() => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Common Name Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The Common Name was added successfully.';
              }, 500);
              if (res.hasOwnProperty('commonNames')) {
                this.commonNames = res.commonNames;
                this.commonNamesArray = [];
                this.commonNames.forEach((aCn) => {
                  this.commonNamesArray.push(aCn.common_name)
                });
              }
              this.showCommonForm = false;
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add new Common Name. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      }
    }
  }

  editCommon(synonym) {
    const currentCommonArray = this.commonNames.filter((aCommon) => aCommon.common_name === synonym);
    if (currentCommonArray.length > 0) {
      const currentCommon = currentCommonArray[0];
      this.cnf.commonName.setValue(currentCommon.common_name);
      this.cnf.cnameId.setValue(currentCommon.id);
      this.showCommonForm = true;
      this.editingCommon = true;
    }
  }
  deleteCommon(synonym) {
    const currentCommonArray = this.commonNames.filter((aCommon) => aCommon.common_name === synonym);
    if (currentCommonArray.length > 0) {
      this.plantService.deleteCommonName(currentCommonArray[0].id).subscribe(res => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.infoModalRef = this.modalService.open(InfoModalComponent);
              this.infoModalRef.componentInstance.infoTitle = 'Common Name Deleted.';
              this.infoModalRef.componentInstance.infoMessage = 'The Common Name was deleted successfully.';
            }, 500);
            if (res.hasOwnProperty('commonNames')) {
              this.commonNames = res.commonNames;
              this.commonNamesArray = [];
              this.commonNames.forEach((aCn) => {
                this.commonNamesArray.push(aCn.common_name)
              });
            }
            this.showCommonForm = false;
          });
        },
        error => {
          setTimeout(() => {
            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
            this.errorModalRef.componentInstance.errorMessage = 'delete common name. Error Message was: ' + error;
            this.errorModalRef.componentInstance.longErrorMessage = '';
          }, 500);
        });
    }

  }
  editSyn(synonym) {
    const currentSynArray = this.synonyms.filter((aSyn) => aSyn.id === synonym.id);
    if (currentSynArray.length > 0) {
      const currentSyn = currentSynArray[0];
      this.synf.synonymId.setValue(currentSyn.id);
      this.synf.family.setValue(currentSyn.family);
      this.synf.familyCommonName.setValue(currentSyn.family_common_name);
      this.synf.genus.setValue(currentSyn.genus);
      this.synf.crossGenus.setValue(currentSyn.cross_genus);
      this.synf.crossSpecificEpithet.setValue(currentSyn.cross_specific_epithet);
      this.synf.specificEpithet.setValue(currentSyn.specific_epithet);
      this.synf.infraspecificEpithetDesignator.setValue(currentSyn.infraspecific_epithet_designator);
      this.synf.infraspecificEpithet.setValue(currentSyn.infraspecific_epithet);
      this.synf.crossSpecies.setValue(currentSyn.cross_species);
      this.synf.cultivar.setValue(currentSyn.cultivar);
      this.synf.tradeName.setValue(currentSyn.trade_name);
      this.synf.trademarkName.setValue(currentSyn.trademark_name);
      this.synf.registeredName.setValue(currentSyn.registered_name);
      this.synf.plantId.setValue(this.currentPlantId);
      this.showSynForm = true;
      this.editingSyn = true;
    }
  }
  deleteSyn(synonym) {
    const currentSynArray = this.synonyms.filter((aSyn) => aSyn.id === synonym.id);
    if (currentSynArray.length > 0) {
      this.plantService.deleteSynonym(currentSynArray[0].id).subscribe(res => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.infoModalRef = this.modalService.open(InfoModalComponent);
              this.infoModalRef.componentInstance.infoTitle = 'Synonym Deleted.';
              this.infoModalRef.componentInstance.infoMessage = 'The Synonym was deleted successfully.';
            }, 500);
            if (res.hasOwnProperty('synonyms')) {
              this.synonyms = res.synonyms;
            }
            this.showSynForm = false;
          });
        },
        error => {
          setTimeout(() => {
            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
            this.errorModalRef.componentInstance.errorMessage = 'delete synonym. Error Message was: ' + error;
            this.errorModalRef.componentInstance.longErrorMessage = '';
          }, 500);
        });
    }
  }

  renderName(synonym) {
    return this.plantService.render_botanical_name(null, null, synonym);
  }

  saveSyn() {
    this.synsubmitted = true;
    const val = this.synForm.value;
    if (this.synForm.valid) {
      let formData = new HttpParams()
      Object.keys(this.synForm.controls).forEach(key => {
        const abstractControl = this.synForm.get(key);
        if ((abstractControl.value != null) && ((key === 'plantId') || (abstractControl.value.length > 0))) {
          formData = formData.append(key, abstractControl.value);
        }
      });
      if (this.editingSyn) {
        const synId = this.synf.synonymId.value;
        this.plantService.updateSynonym(formData, synId).subscribe(res => {
            this.ngZone.run(() => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Synonym Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The Synonym was updated successfully.';
              }, 500);
              if (res.hasOwnProperty('synonyms')) {
                this.synonyms = res.synonyms;
              }
              this.showSynForm = false;
              this.editingSyn = false;
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'update synonym. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      } else {
        this.plantService.addNewSynonym(formData).subscribe(res => {
            this.ngZone.run(() => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Synonym Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The Synonym was added successfully.';
              }, 500);
              if (res.hasOwnProperty('synonyms')) {
                this.synonyms = res.synonyms;
              }
              this.showSynForm = false;
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add new synonym. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      }
    }
  }

  typeCount() {
    const checkArray: FormArray = this.imageForm.get('typeArray') as FormArray;
    if (checkArray.length > 0) {
      this.showCatError = false;
    } else {
      this.showCatError = true;
    }
    return checkArray.length;
  }

  isChecked(checkArrayName, value) {
    const checkArray: FormArray = this.advForm.get(checkArrayName) as FormArray;
    let isChecked = false;
    checkArray.controls.forEach((item:  FormControl) => {
      if (item.value === value) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  isCategoryChecked(value) {
    const checkArray: FormArray = this.imageForm.get('typeArray') as FormArray;
    let isChecked = false;
    checkArray.controls.forEach((item:  FormControl) => {
      if (item.value === value) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  categoryAlreadyHasAnImage(value) {
    if (value === '1') {
      return this.primaryImage.length > 0;
    } else if (value === '3') {
      return this.landscapeImage.length > 0;
    } else
      return this.detailImage.length > 0;
  }

  getCategoriesSelected() {
    const checkArray: FormArray = this.imageForm.get('typeArray') as FormArray;
    const catArray = [];
    Object.keys(checkArray.controls).forEach(key => {
      const abstractControl = checkArray.get(key);
      catArray.push(abstractControl.value)
    });

    return catArray.join(',');
  }
  onCategoryCheckboxChange(e) {
    const checkArray: FormArray = this.imageForm.get('typeArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onCheckboxChange(e, checkArrayName) {
    const checkArray: FormArray = this.advForm.get(checkArrayName) as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  save() {
    const val = this.plantForm.value;
    this.submitted = true;
    console.log(val);
    if (this.plantForm.valid) {
      let formData = new HttpParams();

      Object.keys(this.plantForm.controls).forEach(key => {
        const abstractControl = this.plantForm.get(key);
        if ((abstractControl.value != null) && (abstractControl.value.length > 0)) {
          formData = formData.append(key, abstractControl.value);
        }
      });
      formData = formData.append('waterAdv', this.getAdvancedParams('waterCheckArray'));
      formData = formData.append('sunAdv', this.getAdvancedParams('sunCheckArray'));
      formData = formData.append('soilAdv', this.getAdvancedParams('soilCheckArray'));
      formData = formData.append('habitAdv', this.getAdvancedParams('habitCheckArray'));
      formData = formData.append('wildlifeAdv', this.getAdvancedParams('wildlifeCheckArray'));
      formData = formData.append('pestAdv', this.getAdvancedParams('pestResistanceCheckArray'));
      formData = formData.append('flowerTimeAdv', this.getAdvancedParams('flowerTimeCheckArray'));
      formData = formData.append('flowerColorAdv', this.getAdvancedParams('flowerColorCheckArray'));
      formData = formData.append('foliageColorAdv', this.getAdvancedParams('foliageColorCheckArray'));
      formData = formData.append('foliageTextureAdv', this.getAdvancedParams('foliageTextureCheckArray'));
      formData = formData.append('designUseAdv', this.getAdvancedParams('designUseCheckArray'));
      formData = formData.append('scentAdv', this.getAdvancedParams('scentCheckArray'));
      formData = formData.append('springAdv', this.getAdvancedParams('springCheckArray'));
      formData = formData.append('themeAdv', this.getAdvancedParams('themeCheckArray'));
      formData = formData.append('brightAdv', this.getAdvancedParams('brightCheckArray'));
      formData = formData.append('romanticSeasonsAdv', this.getAdvancedParams('romanticSeasonsCheckArray'));
      if (this.currentPlantId > 0) {
        this.plantService.updatePlant(this.currentPlantId, formData).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Plant Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The plant data was updated successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'update plant data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      } else {
        this.plantService.addNewPlant(formData).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Plant Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The plant data was added successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add new plant data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      }
    }
  }

  getAdvancedParams(checkArrayName) {
    const checkArray: FormArray = this.advForm.get(checkArrayName) as FormArray;
    let valueString = '';
    checkArray.controls.forEach((item: FormControl) => {
      valueString += item.value + ',';
    });
    if (valueString.length > 0) {
      valueString = valueString.substring(0, valueString.length -1);
    }
    return valueString;
  }
  deletePlant() {
    this.confirmationDialogService.confirm('Plant Delete', 'delete this plant', 'lg')
      .then((confirmed) => {
      console.log('user selected ' + confirmed);
      if (confirmed) {
        this.plantService.deletePlant(this.currentPlantId).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Plant Deleted.';
                this.infoModalRef.componentInstance.infoMessage = 'The plant data was deleted successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'delete plant data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      }
    })
      .catch(() => {
        console.log('canceled out');
      });
  }
}
