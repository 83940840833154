<div class="home-image" style="background-image: url(../../assets/plants/arisaema-sikokianum.jpg)">	  <h1>About Great Plant Picks</h1>
</div> 
<div class="wrapper">      
<div class="breadcrumbs">
<ul>
        <li><a  id="current">About GPP</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCriteria()">Selection Criteria</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCommittee()">Selection Committees</a></li>
        <li><a [routerLink]="" (click)="gotoAboutGroups()">Advisors</a></li>
        <li><a [routerLink]="" (click)="gotoAboutStaff()">GPP Staff</a></li>
</ul>
      </div><!-- end breadcrumbs -->
<div class="content">
      
<div class="text-wrap">      
      <h2>Our Mission</h2>
      <p>Great Plant Picks is an educational program committed to building a comprehensive palette of outstanding plants for maritime Pacific Northwest gardens. Great Plant Picks is one of the educational outreach programs for the Elisabeth C. Miller Botanical Garden, providing a forum for sharing horticultural information with the wider gardening community.</p>
      <h2>Goals &amp; Objectives</h2>
      
        <h3>Create a comprehensive list of hardy, reliable plants for home gardeners and industry professionals specifically focused on the maritime Northwest.</h3>
	<ul>
        <li>Assemble selection committees with representation from Western British Columbia, Western Oregon and Western Washington with representation from a wide variety of horticultural backgrounds.</li>
        <li>Develop plant assessment programs and expert resources for the in-depth research of a plant&#8217;s suitability for the region.</li>
      </ul>
      <h3>Provide easily accessible information on these selected plants.</h3>
	<ul>
        <li>Furnish information on the best cultural practices with accurate characteristics on the selected plants.</li>
        <li>Network with the horticultural community to distribute GPP information.</li>
      </ul>
      <h3>Provide and clarify accurate nomenclature and identification of the selected plants.</h3>
	<ul>
        <li>Investigate through the most current resources the proper botanical names of the selected plants.</li>
        <li>Make available information to help the average person acquire the right plant.</li>
        <li>Use the highest quality photographs to show the best features of the selected plants.</li>
      </ul>
	</div>
    </div>
</div>
