<div class="home-image" style="background-image: url(../../assets/plants/Crocus-Goldilocks-RAS.jpg)">
  <h1>Helpful Plant Lists</h1>
</div>
<div class="breadcrumbs">
  <ul>
    <li><a [routerLink]="" id="current" (click)="gotoSearch()">Helpful Plant Lists/Search by Name</a></li>
    <li><a [routerLink]="" (click)="gotoAdvSearch()">Plant Search</a></li>
    <li><a [routerLink]="" (click)="gotoPlantNotListed()">Why Can&#8217;t I Find My Plant?</a></li>
  </ul>
</div><!-- end breadcrumbs -->
<div class="content view">
  <h1>Find Your Great Plant Pick</h1>
  <div class="row">
    <div class="col-md-12 mx-auto text-center">
      <div class="ng-autocomplete d-none d-sm-inline-flex mb-3" style="width: 900px;display: inline-flex;justify-content: center;">
        <span style="align-self: center;margin-right: 10px;">Search by plant name:</span>
        <ng-autocomplete
          [data]="options"
          [placeHolder]="placeholder"
          [searchKeyword]="keyword"
          (selected)='selectEvent($event)'
          (inputChanged)='onChangeSearch($event)'
          (inputFocused)='onFocused($event)'
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.common_name" class="text-left"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <button #searchButton class="search-button" (click)="basicSearch()">Search</button>
      </div>
      <div class="d-block d-sm-none text-center">
        <span>Search by plant name:</span>
        <input class="input w-100" [formControl]="basicSearchTermsInput"
               placeholder="Enter botanical or common name">
        <button class="search-button" (click)="basicSearchMobile()">Search</button>
      </div>
    </div>
  </div>

  <p class="refine">To Refine Your Search, <a [routerLink]="" (click)="gotoAdvSearch()"
    title="To Refine Your Search, Go To Advanced Search Options">Go To
    Advanced Search Options</a></p>

  <div class="row">
    <div class="col-md-3">
      <nav class="navbar navbar-expand-md fixed-left">
        <h2>Helpful Lists</h2>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#plantListTarget"
                aria-controls="plantListTarget" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span><span class="toggler-label">Choose a List</span>
        </button>
        <div class="collapse navbar-collapse fixed-left-list" id="plantListTarget">

          <p class="leftNavItem first" [ngStyle]="highlightMe('greatPlantPicksByType')"><a [routerLink]="" (click)="navigate('greatPlantPicksByType')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">All Great Plant Picks</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('perfectPlantsForGreatContainers')"><a [routerLink]="" (click)="navigate('perfectPlantsForGreatContainers')"
                                    data-toggle="collapse" data-target="#plantListTarget.show">Containers</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('deerResistantPlants')"><a [routerLink]="" (click)="navigate('deerResistantPlants')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Deer Resistant Plants</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('droughtTolerantInSun')"><a [routerLink]="" (click)="navigate('droughtTolerantInSun')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Drought Tolerant Plants</a></p>
		 <p class="leftNavItem" [ngStyle]="highlightMe('floraRomantica')"><a [routerLink]="" (click)="navigate('floraRomantica')"
                                    data-toggle="collapse" data-target="#plantListTarget.show">Flora Romantica</a></p>		
		  <p class="leftNavItem" [ngStyle]="highlightMe('plantsWithStrikingFoliage')"><a [routerLink]="" (click)="navigate('plantsWithStrikingFoliage')"
                                    data-toggle="collapse" data-target="#plantListTarget.show">Foliage
            Fashion</a></p>	

          <p class="leftNavItem" [ngStyle]="highlightMe('plantsThatMakeScents')"><a [routerLink]="" (click)="navigate('plantsThatMakeScents')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Scent-sational Plants</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('miscellaneousLists')"><a [routerLink]="" (click)="navigate('miscellaneousLists')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Miscellaneous Lists</a></p>
		<p class="leftNavItem" [ngStyle]="highlightMe('droughtTolerantInShade')"><a [routerLink]="" (click)="navigate('droughtTolerantInShade')"
                                    data-toggle="collapse" data-target="#plantListTarget.show">Plants for a Better Planet</a></p>
          <p class="leftNavItem" [ngStyle]="highlightMe('nativeToGppRegion')"><a [routerLink]="" (click)="navigate('nativeToGppRegion')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Natives - Pacific Northwest</a></p>
          <p class="leftNavItem" [ngStyle]="highlightMe('pollinators')"><a [routerLink]="" (click)="navigate('pollinators')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Pollinator Plants</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('aGardenForAllSeasons')"><a [routerLink]="" (click)="navigate('aGardenForAllSeasons')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Seasonal Plantings</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('plantsForShade')"><a [routerLink]="" (click)="navigate('plantsForShade')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Shade Gardening</a></p>

          <p class="leftNavItem" [ngStyle]="highlightMe('smallSpacesBigImpact')"><a [routerLink]="" (click)="navigate('smallSpacesBigImpact')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Small Gardens</a></p>
			
		   <p class="leftNavItem" [ngStyle]="highlightMe('snugSpacesNarrowSpaces')"><a [routerLink]="" (click)="navigate('snugSpacesNarrowSpaces')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Snug or Narrow Spaces</a></p>	
			<p class="leftNavItem" [ngStyle]="highlightMe('urbanSturdy')"><a [routerLink]="" (click)="navigate('urbanSturdy')" data-toggle="collapse"
                                    data-target="#plantListTarget.show">Urban Sturdy</a></p>
        </div>
      </nav>
    </div>
    <div class="col-md-7 offset-1">
      <h1>Browse the GPP Plant Lists</h1>
      <p class="last">Select a list or use one of the search options above.</p>
		
		<div class="group" *ngIf="selectedList == 'greatPlantPicksByType'">
        <img class="featured alignright" src="../../assets/search/lilium-martagon-gpp-by-type.jpg">
        <p class="uc">Great Plant Picks by Type</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Great Plant Picks')">View All Great Plant Picks</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Bamboos','plantType','bamboo')">All Bamboos</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Bulbs','plantType','bulb')">All Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Conifers','plantType','conifer')">All Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Ferns','plantType','fern')">All Ferns</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Grasses','plantType','grass')">All Grasses</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Perennials','plantType','perennial')">All Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shrubs','plantType','shrub')">All Shrubs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Trees','plantType','tree')">All Trees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Vines','plantType','vine')">All Vines</a></li>
        </ul>
        <p class="uc">Great Plant Picks by Year</p>
        <div class="twocol">
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2001','gppYear','2001')">2001</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2002','gppYear','2002')">2002</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2003','gppYear','2003')">2003</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2004','gppYear','2004')">2004</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2005','gppYear','2005')">2005</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2006','gppYear','2006')">2006</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2007','gppYear','2007')">2007</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2008','gppYear','2008')">2008</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2009','gppYear','2009')">2009</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2010','gppYear','2010')">2010</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2011','gppYear','2011')">2011</a></p>
		      <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2012','gppYear','2012')">2012</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2013','gppYear','2013')">2013</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2014','gppYear','2014')">2014</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2015','gppYear','2015')">2015</a></p>
				  <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2016','gppYear','2016')">2016</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2017','gppYear','2017')">2017</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2018','gppYear','2018')">2018</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2019','gppYear','2019')">2019</a></p>
          <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2020','gppYear','2020')">2020</a></p>
		<p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2021','gppYear','2021')">2021</a></p>
			<p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2022','gppYear','2022')">2022</a></p>
      <p><a [routerLink]="" (click)="gotoListByOptions('Great Plant Picks by Year - 2023','gppYear','2023')">2023</a></p>
        </div>
      </div>


		<div class="group" *ngIf="selectedList == 'plantsWithStrikingFoliage'">
        <img class="featured alignright" alt="" title="Foliage Fashion 2021 Great Plant Picks poster'"
             src="assets/posters/2021-gpp-poster.jpg">
        <p class="uc">FOLIAGE FASHION</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Fantastic Foliage Selections','foliage')">All Foliage Fashion Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Bold Foliage','foliageTexture','bold', 'theme', 'foliage')">Brazen & Bold Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Dazzle with Color','foliageColor','colorful', 'theme', 'foliage')">All Dazzle with Color Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Shiny Foliage','foliageTexture','shiny', 'theme', 'foliage')">Shiny Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Unique Foliage','foliageTexture','unique', 'theme', 'foliage')">Unique Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Fine Foliage','foliageTexture','fine', 'theme', 'foliage')">All Elegant & Delicate Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Burgundy Foliage','foliageColor','burgundy', 'theme', 'foliage')">Burgundy Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Gold Foliage','foliageColor','gold', 'theme', 'foliage')">Gold Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Purple Foliage','foliageColor','purple', 'theme', 'foliage')">Purple Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - Silver Foliage','foliageColor','silver', 'theme', 'foliage')">Silver Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Fantastic Foliage Selections - White Foliage','foliageColor','white', 'theme', 'foliage')">White Foliage</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'droughtTolerantInShade'">
        <img class="featured alignright" alt="" title="Plants for a Better Planet Poster"
             src="assets/search/better-planet.jpg">
        <p class="uc"><strong>PLANTS FOR A BETTER PLANET</strong></p>

        <p class="uc">Drought Tolerant in Sun:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Drought Tolerant Plants for Sun', 'sun_drought')">All Drought Tolerant Plants for Sun</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Bulbs', 'plantType','bulb', 'theme', 'sun_drought')">Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Conifers', 'plantType','conifer', 'theme', 'sun_drought')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Grasses', 'plantType','grass', 'theme', 'sun_drought')">Grasses</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Perennials', 'plantType','perennial', 'theme', 'sun_drought')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Shrubs', 'plantType','shrub', 'theme', 'sun_drought')">Shrubs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Trees', 'plantType','tree', 'theme', 'sun_drought')">Trees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Vines', 'plantType','vine', 'theme', 'sun_drought')">Vines</a></li>
        </ul>
        <p class="uc">Drought Tolerant in Shade:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('water', 'drought-tolerant', 'sun', 'dappled_shade')">All Drought Tolerant Plants for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Bulbs','plantType','bulb', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Conifers','plantType','conifer', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Perennials','plantType','perennial', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Shrubs','plantType','shrub', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Shrubs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Trees','plantType','tree', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Trees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Drought Tolerant Plants for Shade - Vines','plantType','vine', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Vines</a></li>
        </ul>
        <p class="uc">Pollinators</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Birds','wildlife','birds')">Plants for Birds</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Bees','wildlife','bees')">Plants for Bees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Butterflies','wildlife','butterflies')">Plants for Butterflies</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Hummingbirds','wildlife','hummingbirds')">Plants for Hummingbirds</a></li>
        </ul>
        <p class="uc">Pacific Northwest Natives</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives','nativeToGppRegion','yes')">All GPP Natives</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Conifers','nativeToGppRegion','yes', 'plantType', 'conifer')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Perennials','nativeToGppRegion','yes', 'plantType', 'perennial')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Shrubs','nativeToGppRegion','yes', 'plantType', 'shrub')">Shrubs</a></li>
        </ul>
      </div>

      
      <div class="group" *ngIf="selectedList == 'perfectPlantsForGreatContainers'">
        <img class="featured alignright" src="../../assets/search/2018-GPP-Poster.jpg">
        <p class="uc">Plants for Great Containers</p>
        <p><a [routerLink]="" (click)="gotoListByTheme('All Plants Suitable for Containers','perfect_containers')">All Plants Suitable for Containers</a></p>
        <p class="uc">By Plant Type:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Conifers','plantType','conifer', 'theme', 'perfect_containers')">Conifers for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Ferns','plantType','fern', 'theme', 'perfect_containers')">Ferns for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Grasses','plantType','grass', 'theme', 'perfect_containers')">Grasses for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Perennials','plantType','perennial', 'theme', 'perfect_containers')">Perennials for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Shrubs','plantType','shrub', 'theme', 'perfect_containers')">Shrubs for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Trees','plantType','tree', 'theme', 'perfect_containers')">Trees for Containers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants Suitable for Containers - Vines','plantType','vine', 'theme', 'perfect_containers')">Vines for Containers</a></li>
        </ul>
        <p class="uc">By Color, Form, or Texture:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('Bright Colors','designUse','color', 'theme', 'perfect_containers')">Bright Colors (Foliage or Flowers)</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Stylish Forms','designUse','form', 'theme', 'perfect_containers')">Stylish Forms</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Tactile Textures','designUse','texture', 'theme', 'perfect_containers')">Tactile Textures</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'deerResistantPlants'">
        <img class="featured alignright" src="../../assets/search/deer-resistant.jpg">
        <p class="uc">Deer Resistant Plants</p>
        <p><strong><a [routerLink]="" (click)="gotoGlossaryDeer()">Deer Resistance Guidelines and
          Definitions</a></strong></p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections','pestResistance','deer')">All Deer Resistant Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Bamboo','plantType','bamboo','pestResistance', 'deer')">Bamboo</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Bulbs','plantType','bulb','pestResistance', 'deer')">Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Conifers','plantType','conifer','pestResistance', 'deer')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Ferns','plantType','fern','pestResistance', 'deer')">Ferns</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Grasses','plantType','grass','pestResistance', 'deer')">Grasses</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Perennials','plantType','perennial','pestResistance', 'deer')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Deer Resistant Selections - Trees','plantType','tree','pestResistance', 'deer')">Trees</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'droughtTolerantInSun'">
        <img class="featured alignright" alt="" title="Plants for a Better Planet Poster"
             src="assets/search/better-planet.jpg">
        <p class="uc">Drought Tolerant Plants</p>
        <p><strong><a [routerLink]="" (click)="gotoGlossaryWater()">Watering Guidelines and Definitions</a></strong></p>
        <p class="uc">Drought Tolerant in Sun:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Drought Tolerant Plants for Sun','sun_drought')">All Drought Tolerant Plants for Sun</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Bulbs','plantType','bulb', 'theme', 'sun_drought')">Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Conifers','plantType','conifer', 'theme', 'sun_drought')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Grasses','plantType','grass', 'theme', 'sun_drought')">Grasses</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Perennials','plantType','perennial', 'theme', 'sun_drought')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Shrubs','plantType','shrub', 'theme', 'sun_drought')">Shrubs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Trees','plantType','tree', 'theme', 'sun_drought')">Trees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Sun - Vines','plantType','vine', 'theme', 'sun_drought')">Vines</a></li>
        </ul>
        <p class="uc">Drought Tolerant in Shade:</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade','water', 'drought-tolerant', 'sun', 'dappled_shade')">All Drought Tolerant Plants for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Bulbs','plantType','bulb', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Bulbs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Conifers','plantType','conifer', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Perennials','plantType','perennial', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Shrubs','plantType','shrub', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Shrubs</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Trees','plantType','tree', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Trees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Drought Tolerant Plants for Shade - Vines','plantType','vine', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Vines</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'plantsThatMakeScents'">
        <img class="featured alignright" alt="" title="Scent-sational Plants Poster"
             src="../assets/posters/2023-gpp-poster.jpg">
        <p class="uc">Scent-sational Plants</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Plants that make SCENTS Selections','fragrance')">All Scent-sational Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Fragrant Flowers','scent','flowers', 'theme', 'fragrance')">Fragrant Flowers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Fragrant Foliage','scent','foliage', 'theme', 'fragrance')">Fragrant Foliage</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Floral Scent','scent','floral', 'theme', 'fragrance')">Floral Scent</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Fresh Scent','scent','fresh', 'theme', 'fragrance')">Fresh Scent</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Spicy Scent','scent','spicy', 'theme', 'fragrance')">Spicy Scent</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Plants that make SCENTS Selections - Woodsy Scent','scent','woodsy', 'theme', 'fragrance')">Woodsy Scent</a></li>
        </ul>
      </div>
		 <div class="group" *ngIf="selectedList == 'floraRomantica'">
        <img class="featured alignright" alt="" title="Flora Romantica Poster"
             src="../assets/posters/2024-gpp-poster.jpg">
        <p class="uc" style="margin-bottom:12px;">Flora Romantica</p>
			 	<p class="uc">Winter Wonderland</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Winter','romanticSeasons','winter', 'theme', 'flora_romantica')">Winter</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Winter-Spring','romanticSeasons','winter-spring', 'theme', 'flora_romantica')">Winter-Spring</a></li>
				</ul>
        <p class="uc">Sweetheart Spring</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Spring','romanticSeasons','spring', 'theme', 'flora_romantica')">Spring</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Spring-Summer','romanticSeasons','spring-summer', 'theme', 'flora_romantica')">Spring-Summer</a></li>
				</ul>
	<p class="uc">Sultry Summer</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Summer','romanticSeasons','summer', 'theme', 'flora_romantica')">Summer</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Summer-Autumn','romanticSeasons','summer-fall', 'theme', 'flora_romantica')">Summer-Autumn</a></li>
				</ul>		
	<p class="uc">Autumn Ardor</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Autumn','romanticSeasons','fall', 'theme', 'flora_romantica')">Autumn</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Autumn-Winter','romanticSeasons','fall-winter', 'theme', 'flora_romantica')">Autumn-Winter</a></li>
				</ul>		 
      </div>

      <div class="group" *ngIf="selectedList == 'miscellaneousLists'">
        <img class="featured alignright" alt="" title="Fuchsia Dying Embers"
             src="assets/search/fuchsia-dying-embers.jpg">
        <p class="uc">Miscellaneous Lists</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('Powerline Friendly Trees','plantType','tree', 'designUse', 'powerline_friendly_trees')">Powerline Friendly Trees <span class="nowrap pad">(maturing to 25' or less)</span></a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Clematis','genus','clematis')">Clematis</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Epimediums','genus','epimedium')">Epimediums</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Evergreen Azaleas','common','evergreen_azalea')">Evergreen Azaleas</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Groundcovers','designUse','groundcover')">Groundcovers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Hydrangeas','genus','hydrangea')">Hydrangeas</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Rhododendrons','genus','rhododendron')">Rhododendrons</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Roses','genus','rosa')">Roses</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'nativeToGppRegion'">
        <img class="featured alignright" alt="" title="Ribes sanguineum" src="assets/search/ribes-sanguineum.jpg">
        <p class="uc">Pacific Northwest Natives</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives','nativeToGppRegion','yes')">All GPP Natives</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Conifers','nativeToGppRegion','yes', 'plantType', 'conifer')">Conifers</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Perennials','nativeToGppRegion','yes', 'plantType', 'perennial')">Perennials</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All GPP Natives - Shrubs','nativeToGppRegion','yes', 'plantType', 'shrub')">Shrubs</a></li>
        </ul>
      </div>



      <div class="group" *ngIf="selectedList == 'pollinators'">
        <img class="featured alignright" alt="" title="Birds and Bees 2016 GPP poster"
             src="assets/search/pollinator-plants.jpg">
        <p class="uc">Pollinators</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Birds','wildlife','birds')">Plants for Birds</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Bees','wildlife','bees')">Plants for Bees</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Butterflies','wildlife','butterflies')">Plants for Butterflies</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Plants for Hummingbirds','wildlife','hummingbirds')">Plants for Hummingbirds</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'aGardenForAllSeasons'">
        <img class="featured alignright" alt="" title="Garden for All Seasons 2019 GPP poster"
             src="assets/search/garden-for-all-seasons.jpg">
        <p class="uc">A Garden for All Seasons</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('Winter Plants','winter_interest')">Winter</a></li>
          <li><a [routerLink]="" (click)="gotoListByTheme('Spring Plants','spring_interest')">Spring</a></li>
          <li><a [routerLink]="" (click)="gotoListByTheme('Summer Plants','summer_interest')">Summer</a></li>
          <li><a [routerLink]="" (click)="gotoListByTheme('Fall Plants','autumn_interest')">Fall</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'plantsForShade'">
        <img class="featured alignright" alt="" title="Bright Ideas for Shade 2017 GPP poster"
             src="assets/search/bright-ideas-for-shade.jpg">
        <p class="uc">Plants for Shade</p>
        <p><strong><a [routerLink]="" (click)="gotoGlossaryShade()">Shade Guidelines and Definitions</a></strong></p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Shade Gardening Selections','shade')">All Shade Gardening Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Bulbs','plantType','bulb', 'theme', 'shade')">Bulbs for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Conifers','plantType','conifer', 'theme', 'shade')">Conifers for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Grasses','plantType','grass', 'theme', 'shade')">Grasses for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Perennials','plantType','perennial', 'theme', 'shade')">Perennials for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Shrubs','plantType','shrub', 'theme', 'shade')">Shrubs for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Trees','plantType','tree', 'theme', 'shade')">Trees for Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Light Shade','sun','light_shade', 'theme', 'shade')">Plants for Light Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Open Shade','sun','open_shade', 'theme', 'shade')">Plants for Open Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Dappled Shade','sun','dappled_shade', 'theme', 'shade')">Plants for Dappled Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Deep Shade','sun','deep_shade', 'theme', 'shade')">Plants for Deep Shade</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Shade Gardening Selections - Dry Shade','water','drought-tolerant', 'theme', 'shade')">Plants for Dry Shade</a></li>
        </ul>
      </div>

      <div class="group" *ngIf="selectedList == 'smallSpacesBigImpact'">
        <img class="featured alignright" alt="" title="Smart Plants for Small Spaces poster"
             src="../../assets/search/2022-gpp-poster.jpg">
        <p><strong>Smart Plants for Small Spaces</strong></p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByTheme('All Small Spaces&#8212;Big Impact! Selections','small_urban')">All Smart Plants for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Bulbs for Small Spaces','plantType','bulb', 'theme', 'small_urban')">Bulbs for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Conifers for Small Spaces','plantType','conifer', 'theme', 'small_urban')">Conifers for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Grasses for Small Spaces','plantType','grass', 'theme', 'small_urban')">Grasses for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Perennials for Small Spaces','plantType','perennial', 'theme', 'small_urban')">Perennials for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Shrubs for Small Spaces','plantType','shrub', 'theme', 'small_urban')">Shrubs for Small Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('Trees for Small Spaces','plantType','tree', 'theme', 'small_urban')">Trees for Small Spaces</a></li>
        </ul>
      </div>
		<div class="group" *ngIf="selectedList == 'snugSpacesNarrowSpaces'">
        <img class="featured alignright" src="../../assets/search/2022-gpp-poster.jpg">
        <p class="uc">Smart Plants for Small Spaces</p>
			<p><strong>Snug Spaces</strong> &#8211; smaller, compact, or narrow plants for garden beds or spaces that are on the tight side.</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections','designUse','snug_spaces')">All Snug/Narrow Spaces Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Bulbs','plantType','bulb','designUse', 'snug_spaces')">Bulbs for Snug Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Conifers','plantType','conifer','designUse', 'snug_spaces')">Conifers for Snug Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Ferns','plantType','fern','designUse', 'snug_spaces')">Ferns for Snug Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Grasses','plantType','grass','designUse', 'snug_spaces')">Grasses for Snug Spaces</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Perennials','plantType','perennial','designUse', 'snug_spaces')">Perennials for Snug Spaces</a></li>
		<li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Shrubs','plantType','shrub','designUse', 'snug_spaces')">Shrubs for Snug Spaces</a></li>	
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Trees','plantType','tree','designUse', 'snug_spaces')">Trees for Snug Spaces</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections - Vines','plantType','vine','designUse', 'snug_spaces')">Vines for Snug Spaces</a></li>
        </ul>
      </div>
		<div class="group" *ngIf="selectedList == 'urbanSturdy'">
        <img class="featured alignright" src="../../assets/search/2022-gpp-poster.jpg">
        <p class="uc">Smart Plants for Small Spaces</p>
			<p><strong>Urban Sturdy</strong> &#8211; minimal maintenance plants for worry-free city gardeners.</p>
        <ul>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Snug Spaces/Narrow Spaces Selections','designUse','urban_sturdy')">All Urban Sturdy Selections</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Bulbs','plantType','bulb','designUse', 'urban_sturdy')">Bulbs for Urban Sturdy</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Conifers','plantType','conifer','designUse', 'urban_sturdy')">Conifers for Urban Sturdy</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Ferns','plantType','fern','designUse', 'urban_sturdy')">Ferns for Urban Sturdy</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Grasses','plantType','grass','designUse', 'urban_sturdy')">Grasses for Urban Sturdy</a></li>
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Perennials','plantType','perennial','designUse', 'urban_sturdy')">Perennials for Urban Sturdy</a></li>
		<li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Shrubs','plantType','shrub','designUse', 'urban_sturdy')">Shrubs for Urban Sturdy</a></li>	
          <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Trees','plantType','tree','designUse', 'urban_sturdy')">Trees for Urban Sturdy</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('All Urban Sturdy Selections - Vines','plantType','vine','designUse', 'urban_sturdy')">Vines for Urban Sturdy</a></li>	
        </ul>
			<p><strong><a [routerLink]="" (click)="navigate('snugSpacesNarrowSpaces')" data-toggle="collapse"
           data-target="#plantListTarget.show">Snug Spaces</a></strong> &#8211; smaller, compact, or narrow plants for garden beds or spaces that are on the tight side.</p>
			<p><strong><a [routerLink]="" (click)="navigate('perfectPlantsForGreatContainers')"
               data-toggle="collapse" data-target="#plantListTarget.show">Captivating Containers</a></strong> &#8211; plants that will provide bountiful beauty to garden pots.</p>
      </div>
    </div>
  </div>

</div><!-- end content -->
