import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  @ViewChild('topContent') topElement: ElementRef;
  @ViewChild('pdfContent') pdfElement: ElementRef;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoDonatePdf() {
    // scroll to pdf
    this.pdfElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }
  goBackToTop() {
    // scroll to top
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }
}
