<div id="wrapper">

  <img id="print-logo" alt="Great Plant Picks logo" title="Great Plant Picks" src="../assets/logo-print.gif">

  <div id="contents"><!-- print display for PUBLIC plant list and search function -->

    <div class="printview" id="printview">
      <input class="print-this" type="button" (click)="printWindow()" value="Print Fact Sheet"/>
      <input class="print-this" type="button" (click)="backToDetails()" value="Back to Web View"/>
    </div>
    <div id="content" class="view">
      <div class="printhead">
        <h2 [innerHtml]="botanicalName">
        </h2>
        <h2 class='common'*ngFor="let commonName of currentPlant.commonNames">{{ commonName.common_name }}</h2>

      </div><!-- end head -->
      <div class="plantinfo">


        <h3>Outstanding Qualities</h3>
        <p>{{ currentPlant.qualities }}</p>
        <div *ngIf="showCombos">
        <h3>Colors &amp; Combos</h3>
        <p><em>Great Plant Combinations:</em></p>
        <ul>
          <li *ngFor="let aLink of comboArray;">
            <a (click)="gotoPlantDetails(aLink.plantId)" [routerLink]="">{{ aLink.name }}</a>
          </li>
        </ul>
        <p><em>Great Color Contrasts:</em> {{ currentPlant.color_contrast}}</p>
        <p><em>Great Color Partners:</em> {{ currentPlant.color_partners}}</p>
        </div>
        <h3 class="drop">Culture Notes</h3>
        {{ currentPlant.culture_notes }}
        <div class="clear"></div>
        <div id="imageview">

          <img *ngIf="imageSource !== null" [src]="imageSource" alt="" id="main-img"/>
          <p *ngIf="imageThumbSource" [innerHTML]="imageThumbSource"></p>
        </div><!-- end image view -->

        <div class="quickfacts">
          <h3>Quick Facts</h3>
          <p><em>Plant Type:</em>  {{ currentPlant.plant_type }}</p>
          <p *ngIf="currentPlant.growth_habit != null"><strong>Growth Habit:</strong> {{ currentPlant.growth_habit }}
          </p>
          <p><em>Foliage Type:</em> {{ currentPlant.foliage_type }} </p>
          <p><em>Plant Height (10-year):</em> {{ feet_to_feet_inches(currentPlant.plant_height_at_10) }}
            ({{ feet_to_meters2(currentPlant.plant_height_at_10) }} meters)</p>
          <p><em>Plant Width/Spread (10-year):</em> {{ feet_to_feet_inches(currentPlant.plant_width_at_10) }}
            ({{ feet_to_meters2(currentPlant.plant_width_at_10) }} meters)</p>
          <p *ngIf="maxCategories"><em>Plant Height-Mature:</em> {{ feet_to_feet_inches(currentPlant.plant_height_max)}}
            ({{ feet_to_meters2(currentPlant.plant_height_max) }} meters)</p>
          <p *ngIf="maxCategories"><em>Plant Width-Mature:</em> {{ feet_to_feet_inches(currentPlant.plant_width_max)}}
            ({{ feet_to_meters2(currentPlant.plant_width_max) }} meters)</p>
          <p><em>Hardiness:</em> USDA Zones {{ currentPlant.zone_low }} to {{currentPlant.zone_high}}</p>
          <p *ngIf="flowerColorString.length > 0"><em>Flower Color:</em> {{ flowerColorString }} </p>
          <p *ngIf="currentPlant.flower_time != null && currentPlant.flower_time.length > 0"><strong>Flowering
            Time:</strong> {{ currentPlant.flower_time }}</p>
          <p><em>Sun/Light Exposure:</em> {{ currentPlant.sun_exposure}}</p>
          <p><em>Water Requirements:</em> {{ currentPlant.water_requirements}}</p>
          <p *ngIf="currentPlant.seasonal_interest != null && currentPlant.seasonal_interest.length > 0"><strong>Seasonal
            Interest:</strong> {{ currentPlant.seasonal_interest }}</p>
          <p *ngIf="wildlifeString.length > 0"><strong>Wildlife Associations:</strong> {{ wildlifeString }}</p>
          <p *ngIf="pestString.length > 0"><strong>Resistant to:</strong> {{ pestString }}</p>
        </div><!-- end quickfacts -->
      </div><!-- end plantinfo -->

    </div><!-- end content -->

  </div><!-- end contents -->
</div><!-- end wrapper -->
