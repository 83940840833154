import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoHome() { this.router.navigateByUrl('/'); }
  gotoAbout() { this.router.navigateByUrl('/about'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoResources() { this.router.navigateByUrl('/resources/links'); }
  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }

  gotoPoster() {
    this.router.navigateByUrl('/poster');
  }
}
