<div class="home-image" style="background-image: url(../../assets/plants/aster-frikartii-monch.jpg)">	  
</div> 
<div class="wrapper">
    <div class="text-wrap">
     
      <h1>Welcome to Great Plant Picks!</h1>
      <p><em>Great Plant Picks</em> (GPP) is the primary educational program of the <a href="https://www.millergarden.org/">Elisabeth C. Miller Botanical Garden</a> which debuted in 2001 with the first recommendations for a comprehensive palette of outstanding plants for the maritime Pacific Northwest. To date over 1,000 exceptional plants have been selected for gardeners living west of the Cascade Mountains from Eugene, Oregon, USA to Vancouver, British Columbia, Canada.</p>
	</div>
	
<div class="link-container">
	<h1>Looking for a plant?</h1>
	<a [routerLink]="" (click)="gotoAdvSearch()"><div class="grid third box-links bg-image link1">
                	<h3>Plant Search</h3>				        			
		</div></a>
	<a [routerLink]="" (click)="gotoSearch()"><div class="grid third box-links bg-image link2">
                	<h3>Helpful Plant Lists</h3>				        			
		</div></a>
	<a [routerLink]="" (click)="gotoResources()"><div class="grid third box-links bg-image link3">
                	<h3>Resources &amp; Links</h3>				        			
		</div></a>
	</div>
	<div class="poster-block">
		 <h1>&#8220;FLORA ROMANTICA&#8221; posters now available!</h1>
		<a [routerLink]="" (click)="gotoPoster()">
       <img class="poster" alt="" title="Scent-sational Plants: Link to Poster Page" src="../assets/posters/2023-gpp-poster.jpg">
			</a>
      <!-- end poster -->
       <a [routerLink]="" (click)="gotoPoster()">
        <img class="poster middle" alt="" title="Flora Romantica: Link to Poster Page" src="../assets/posters/2024-gpp-poster.jpg">
      </a><!-- end poster -->
		<a [routerLink]="" (click)="gotoPoster()">
		<img class="poster" alt="" title="Smart Plants for Small Spaces poster: Link to Poster Page" src="assets/posters/2022-gpp-poster.jpg">		
			 </a>
      <!-- end poster -->
	</div><!-- .posterblock -->
	<div class="text-wrap">
		      <p>The <strong>&#8220;Flora Romantica&#8221;</strong> poster, and posters from previous years, are available by mail for free except we request reimbursement for postage &amp; handling. To order, please visit our <a [routerLink]="" (click)="gotoPoster()">Posters</a> page.</p>

    </div><!-- end textwrap -->

</div><!-- end wrapper -->
