<div class="home-image" style="background-image: url(../../assets/plants/Crocus-Goldilocks-RAS.jpg)">
</div>
    <div class="breadcrumbs">
<ul>
     <li><a [routerLink]="" id="current" (click)="gotoSearch()">Plant Lists/Search by Name</a></li>
      <li><a [routerLink]="" (click)="gotoAdvSearch()">Advanced Search</a></li>
      <li><a [routerLink]="" (click)="gotoPlantNotListed()">Why Can&#8217;t I Find My Plant?</a></li>
		</ul>
    </div><!-- end breadcrumbs -->
<div class="content view">
<h1>Find Your Great Plant Pick</h1>
  <div class="row mx-auto text-center mb-3">
    <div class="col-md-12">
      <div class="ng-autocomplete d-none d-sm-inline-flex" style="width: 900px;">
        <span style="align-self: center;margin-right: 10px;">Search by plant name:</span>
        <ng-autocomplete
          [data]="options"
          [placeHolder]="placeholder"
          [searchKeyword]="keyword"
          (selected)='selectEvent($event)'
          (inputChanged)='onChangeSearch($event)'
          (inputFocused)='onFocused($event)'
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.common_name" class="text-left"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
        <button #searchButton class="search-button" (click)="basicSearch()">Search</button>
      </div>
    </div>
  </div>

      <h3>To Refine Your Search, <a [routerLink]="" (click)="gotoAdvSearch()" title="To Refine Your Search, Go To Advanced Search Options">Go To Advanced Search Options</a></h3>
    <h4>Browse the GPP Plant Lists</h4>
    <div class="threecol">
		<div class="group">
	<h3>PLANTS FOR A BETTER PLANET</h3>
	 <p><strong><a [routerLink]="" (click)="gotoGlossary()">Watering Guidelines and Definitions</a></strong></p>
	 <h3>Drought Tolerant in Sun</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('sun_drought')">All Drought Tolerant Plants for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb', 'theme', 'sun_drought')">Bulbs for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'theme', 'sun_drought')">Conifers for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass', 'theme', 'sun_drought')">Grasses for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'theme', 'sun_drought')">Perennials for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'theme', 'sun_drought')">Shrubs for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'theme', 'sun_drought')">Trees for Sun</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','vine', 'theme', 'sun_drought')">Vines for Sun</a></li>
      </ul>
		</div>

		<div class="group">
			<h3>Drought Tolerant in Shade</h3>
			<ul>
				<li>All Drought Tolerant Plants for Shade</li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Bulbs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Conifers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Perennials</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Shrubs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Trees</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','vine', 'water', 'drought-tolerant', 'sun', 'dappled_shade')">Vines</a></li>
				</ul>
			</div>
		<div class="group">
	 <h3>Pollinators</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('wildlife','birds')">Plants for Birds</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('wildlife','bees')">Plants for Bees</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('wildlife','butterflies')">Plants for Butterflies</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('wildlife','hummingbirds')">Plants for Hummingbirds</a></li>
      </ul>
		</div>
		<div class="group">
			<h3>Pacific Northwest Natives</h3>
			<ul>
				  <li><a [routerLink]="" (click)="gotoListByOptions('nativeToGppRegion','yes')">All GPP Natives</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('nativeToGppRegion','yes', 'plantType', 'conifer')">Conifers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('nativeToGppRegion','yes', 'plantType', 'perennial')">Perennials</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('nativeToGppRegion','yes', 'plantType', 'shrub')">Shrubs</a></li>
				</ul>
			</div>
		<div class="group">
      <h3>A Garden for All Seasons</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('winter_interest')">Winter</a></li>
        <li><a [routerLink]="" (click)="gotoListByTheme('spring_interest')">Spring</a></li>
        <li><a [routerLink]="" (click)="gotoListByTheme('summer_interest')">Summer</a></li>
        <li><a [routerLink]="" (click)="gotoListByTheme('autumn_interest')">Fall</a></li>
      </ul>
			</div>

<div class="group">
      <h3>Perfect Plants for Great Containers</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('perfect_containers')">All Plants Suitable for Containers</a></li>
      </ul>
      <p><strong>By Plant Type:</strong></p>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'theme', 'perfect_containers')">Conifers for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','fern', 'theme', 'perfect_containers')">Ferns for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass', 'theme', 'perfect_containers')">Grasses for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'theme', 'perfect_containers')">Perennials for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'theme', 'perfect_containers')">Shrubs for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'theme', 'perfect_containers')">Trees for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','vine', 'theme', 'perfect_containers')">Vines for Containers</a></li>
      </ul>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('designUse','color', 'theme', 'perfect_containers')">Bright Colors (Foliage or Flowers)</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('designUse','form', 'theme', 'perfect_containers')">Stylish Forms</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('designUse','texture', 'theme', 'perfect_containers')">Tactile Textures</a></li>
      </ul>
</div>
			<div class="group">
      <h3>Bright Ideas for Shade</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('bright_ideas')">All Bright Ideas Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','dappled_shade_regular_water', 'theme', 'bright_ideas')">Dappled Shade &amp; Regular Water</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','deep_shade_regular_water', 'theme', 'bright_ideas')">Deep Shade &amp; Regular Water</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','dappled_shade_occasional_water', 'theme', 'bright_ideas')">Dappled Shade &amp; Occasional Water</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','deep_shade_occasional_water', 'theme', 'bright_ideas')">Deep Shade &amp; Occasional Water</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','dappled_shade_drought_tolerant', 'theme', 'bright_ideas')">Dappled Shade &amp; Drought Tolerant</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('bright','deep_shade_drought_tolerant', 'theme', 'bright_ideas')">Deep Shade &amp; Drought Tolerant</a></li>
      </ul>
      <p><strong>By Plant Type:</strong></p>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb', 'theme', 'bright_ideas')">Bright Ideas for Shade: Bulbs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'theme', 'bright_ideas')">Bright Ideas for Shade: Conifers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass', 'theme', 'bright_ideas')">Bright Ideas for Shade: Grasses</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'theme', 'bright_ideas')">Bright Ideas for Shade: Perennials</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'theme', 'bright_ideas')">Bright Ideas for Shade: Shrubs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'theme', 'bright_ideas')">Bright Ideas for Shade: Trees</a></li>
      </ul>
</div>
     <div class="group">

      <h3>Powerline Friendly Trees</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'designUse', 'powerline_friendly_trees')">Trees maturing to 25' or less</a></li>
      </ul>
		 </div>
			<div class="group">
      <h3>Fantastic Foliage&#8212;Plants with Striking Foliage</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('foliage')">All Fantastic Foliage Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','bold', 'theme', 'foliage')">Bold Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','shiny', 'theme', 'foliage')">Shiny Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','unique', 'theme', 'foliage')">Unique Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','fine', 'theme', 'foliage')">Fine Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','burgundy', 'theme', 'foliage')">Burgundy Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','gold', 'theme', 'foliage')">Gold Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','purple', 'theme', 'foliage')">Purple Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','silver', 'theme', 'foliage')">Silver Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('foliageTexture','white', 'theme', 'foliage')">White Foliage</a></li>
      </ul>
</div>
     <div class="group">
      <h3>Deer Resistant Plants</h3>
      <p><strong><a [routerLink]="" (click)="gotoGlossary()">Deer Resistance Guidelines and Definitions</a></strong></p>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('pestResistance','deer')">All Deer Resistant Selections</a></li>
      </ul>
		 </div>
			<div class="group">
      <h3>Plants that CELEBRATE SPRING</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('spring_interest')">All CELEBRATE SPRING Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('spring','spring_flowers', 'theme', 'spring_interest')">Spring Flowers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('spring','spring_foliage', 'theme', 'spring_interest')">Spring Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('spring','march', 'theme', 'spring_interest')">March</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('spring','april', 'theme', 'spring_interest')">April</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('spring','may', 'theme', 'spring_interest')">May</a></li>
      </ul>
</div>
			<div class="group">
      <h3>Small Spaces&#8212;Big Impact!</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('small_urban')">All Small Space Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb', 'theme', 'small_urban')">Bulbs for Small Spaces</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'theme', 'small_urban')">Conifers for Small Spaces</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass', 'theme', 'small_urban')">Grasses for Small Spaces</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'theme', 'small_urban')">Perennials for Small Spaces</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'theme', 'small_urban')">Shrubs for Small Spaces</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'theme', 'small_urban')">Trees for Small Spaces</a></li>
      </ul>
</div>

<div class="group">
      <h3>Great Plant Picks by Type</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('unpublished','0')">View All Great Plant Picks</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bamboo')">All Bamboos</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb')">All Bulbs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer')">All Conifers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','fern')">All Ferns</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass')">All Grasses</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial')">All Perennials</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub')">All Shrubs</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree')">All Trees</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','vine')">All Vines</a></li>
      </ul>
			</div>
			<div class="group">
      <h3>Made in the Shade&#8212;Plants for Shade</h3>
      <p><strong><a [routerLink]="" (click)="gotoGlossary()">Shade Guidelines and Definitions</a></strong></p>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('shade')">All Made in the Shade Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','bulb', 'theme', 'shade')">Bulbs for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','conifer', 'theme', 'shade')">Conifers for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','grass', 'theme', 'shade')">Grasses for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','perennial', 'theme', 'shade')">Perennials for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','shrub', 'theme', 'shade')">Shrubs for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('plantType','tree', 'theme', 'shade')">Trees for Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('sun','light_shade', 'theme', 'shade')">Plants for Light Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('sun','open_shade', 'theme', 'shade')">Plants for Open Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('sun','dappled_shade', 'theme', 'shade')">Plants for Dappled Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('sun','deep_shade', 'theme', 'shade')">Plants for Deep Shade</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('water','drought-tolerant', 'theme', 'shade')">Plants for Dry Shade</a></li>
      </ul>
			</div>
<div class="group">
      <h3>Plants that make SCENTS</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByTheme('fragrance')">All Plants that make SCENTS Selections</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','flowers', 'theme', 'fragrance')">Fragrant Flowers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','foliage', 'theme', 'fragrance')">Fragrant Foliage</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','floral', 'theme', 'fragrance')">Floral Scent</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','fresh', 'theme', 'fragrance')">Fresh Scent</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','spicy', 'theme', 'fragrance')">Spicy Scent</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('scent','woodsy', 'theme', 'fragrance')">Woodsy Scent</a></li>
      </ul>
	</div>
			<div class="group">
      <h3>Flora Romantica</h3>
				<p class="uc">Winter Wonderland</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Winter','romanticSeasons','winter', 'theme', 'flora_romantica')">Winter</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Winter-Spring','romanticSeasons','winter-spring', 'theme', 'flora_romantica')">Winter-Spring</a></li>
				</ul>
        <p class="uc">Sweetheart Spring</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Spring','romanticSeasons','spring', 'theme', 'flora_romantica')">Spring</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Spring-Summer','romanticSeasons','spring-summer', 'theme', 'flora_romantica')">Spring-Summer</a></li>
				</ul>
	<p class="uc">Sultry Summer</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Summer','romanticSeasons','summer', 'theme', 'flora_romantica')">Summer</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Summer-Autumn','romanticSeasons','summer-fall', 'theme', 'flora_romantica')">Summer-Autumn</a></li>
				</ul>		
	<p class="uc">Autumn Ardor</p>
      <ul>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Autumn','romanticSeasons','fall', 'theme', 'flora_romantica')">Autumn</a></li>
		  <li><a [routerLink]="" (click)="gotoListByOptions('Flora Romantica - Autumn-Winter','romanticSeasons','fall-winter', 'theme', 'flora_romantica')">Autumn-Winter</a></li>
				</ul>		 
</div>
			<div class="group">
<h3>Miscellaneous Lists</h3>
      <ul>
        <li><a [routerLink]="" (click)="gotoListByOptions('genus','clematis')">Clematis</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('designUse','containers')">Plants for Containers</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('common','evergreen_azalea')">Evergreen Azaleas</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('genus','rhododendron')">Rhododendrons</a></li>
        <li><a [routerLink]="" (click)="gotoListByOptions('genus','rosa')">Roses</a></li>
      </ul>
				</div>
			<div class="group">
      <h3>Great Plant Picks by Year</h3>

      <div class="twocol">
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2001')">2001</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2002')">2002</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2003')">2003</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2004')">2004</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2005')">2005</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2006')">2006</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2007')">2007</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2008')">2008</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2009')">2009</a></p>
		    <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2010')">2010</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2011')">2011</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2013')">2013</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2014')">2014</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2015')">2015</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2017')">2017</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2018')">2018</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2019')">2019</a></p>
		    <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2020')">2020</a></p>
        <p><a [routerLink]="" (click)="gotoListByOptions('gppYear','2021')">2021</a></p>
      </div>
</div>
    </div><!-- end columns -->

  </div><!-- end content -->
