import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PlantService } from '../services/plant.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  advForm: FormGroup;
  qString: string;
  gotOne: boolean;

  constructor(private fb: FormBuilder,
              private router: Router,
              private plantService: PlantService) { }

  ngOnInit() {
    this.advForm = this.fb.group({
      plant_type: [''],
      zone_low: [''],
      foliage_type: [''],
      foliage_color: [''],
	    design_use: [''],
      flower_time: [''],
      flower_color: [''],
      pest_resistance: [''],
      wildlife: [''],
      plant_height_min: [''],
      plant_height_at_10: [''],
      plant_width_min: [''],
      plant_width_at_10: [''],
      growth_habit: [''],
      sun: [''],
      soil: [''],
      water: [''],
      scent: [''],
      spring: [''],
      romantic_seasons: ['']
    });
  }

  findPlant() {
    this.qString = '';
    this.gotOne = false;
    this.makeQString('plantType', this.advForm.controls.plant_type.value);
    this.makeQString('zoneLowMax', this.advForm.controls.zone_low.value);
    this.makeQString('foliageType', this.advForm.controls.foliage_type.value);
    this.makeQString('foliageColor', this.advForm.controls.foliage_color.value);
    this.makeQString('flowerTime', this.advForm.controls.flower_time.value);
    this.makeQString('flowerColor', this.advForm.controls.flower_color.value);
    this.makeQString('pestResistance', this.advForm.controls.pest_resistance.value);
    this.makeQString('designUse', this.advForm.controls.design_use.value);
    this.makeQString('wildlife', this.advForm.controls.wildlife.value);
    this.makeQString('plantHeightMin', this.advForm.controls.plant_height_min.value);
    this.makeQString('plantHeightAtTen', this.advForm.controls.plant_height_at_10.value);
    this.makeQString('plantWidthMin', this.advForm.controls.plant_width_min.value);
    this.makeQString('plantWidthAtTen', this.advForm.controls.plant_width_at_10.value);
    this.makeQString('growthHabit', this.advForm.controls.growth_habit.value);
    this.makeQString('sun', this.advForm.controls.sun.value);
    this.makeQString('soil', this.advForm.controls.soil.value);
    this.makeQString('water', this.advForm.controls.water.value);
    this.makeQString('scent', this.advForm.controls.scent.value);
    this.makeQString('spring', this.advForm.controls.spring.value);
    this.makeQString('romanticSeasons', this.advForm.controls.romantic_seasons.value);
    this.plantService.advancedSearchOptions = encodeURI(this.qString);
    this.plantService.searchCommonOptions = '';
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }

  makeQString(term, value) {
    if (value.length > 0) {
      this.qString += '&' + term + '=' + value;
    }
  }

  gotoPlantNotListed() {
    this.router.navigateByUrl('/search/plantNotListed');
  }
  gotoSearch() {
    this.router.navigateByUrl('/searchnav');
  }
  gotoAdvSearch() {
    this.router.navigateByUrl('/search/advanced');
  }
}
