<div class="home-image" style="background-image: url(../../assets/plants/tulipa-spring-green.jpg)">	  <h1>Press</h1>
</div> 
<div class="wrapper">

     <div class="breadcrumbs">
<ul>
      <li><a [routerLink]="" id="current" (click)="gotoPress()">Press</a></li>
	  <li><a [routerLink]="" (click)="gotoPoster()">Posters</a></li>
      <li><a [routerLink]="" (click)="gotoTerms()">Image &amp; Logo Use Guidelines</a></li>
    </ul>
  </div><!-- end breadcrumbs -->
<div class="text-wrap">
  <h2>Outstanding plant selections for the maritime Pacific Northwest</h2>
  <h1 style="margin-bottom:0;">FLORA ROMANTICA</h1>
	<p style="text-align:center; font-size:21px; padding-bottom:12px;"><strong>Seasons of the Romantic Garden</strong></p>
	<p style="text-align:center; font-size:18px; padding-bottom:12px;"><strong>Winter Wonderland &#x2740; Sweetheart Spring &#x2740; Sultry Summer &#x2740; Autumn Ardor</strong></p>
	<p>Each season brings a distinctive impression to a garden when it comes to romance. Sometimes it's obvious as with roses in late spring or hydrangeas in midsummer, but winter often conveys a different sense. Whether it's enjoying snowdrops during a walk with your sweetie or sitting together inside watching the snow fall on your evergreens, the garden can be a place for romance.</p>

<p>On the <a href="https://greatplantpicks.org/searchnav">Helpful Plant Lists</a> page, you will find outstanding plants that will grow well and bring a touch of romance to gardens, landscapes and containers. You will discover the wide variety of plants that have been vetted by Pacific Northwest green industry experts!</p>
  
    <p>If you are a journalist, garden writer, or newsletter editor, Great Plant Picks can supply <a [routerLink]="" (click)="gotoTerms()">photos</a> and information for more than 1,000 unbeatable garden plants for the maritime Pacific Northwest. For more information on this year's theme, "FLORA ROMANTICA", or other Great Plant Picks contact Rick Peterson at <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a>.</p>

    <p>Are you, your publication, your garden group, or nursery business on the GPP email list? If not, please send your email address to <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a> for the latest press releases and GPP news information.</p>
    
  <img class="aligncenter size-full" alt="" title="Scent-sational Plants Poster" src="../assets/posters/2024-gpp-poster.jpg">
</div><!-- .text-wrap -->
</div><!-- end wrapper -->
