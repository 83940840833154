<div class="home-image" style="background-image: url(../../assets/plants/campanula-lactiflora.jpg)">	 
	<h1>GPP Selection Criteria</h1>
</div> 
<div class="wrapper">

     <div class="breadcrumbs">
<ul>
        <li><a [routerLink]="" (click)="gotoAbout()">About GPP</a></li>
        <li><a [routerLink]="" id="current">Selection Criteria</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCommittee()">Selection Committees</a></li>
        <li><a [routerLink]="" (click)="gotoAboutGroups()">Advisors</a></li>
        <li><a [routerLink]="" (click)="gotoAboutStaff()">GPP Staff</a></li>
</ul>
      </div><!-- end breadcrumbs -->
      
     <div class="content">
		 <div class="text-wrap">
 
        <h1>All Great Plant Picks selections should:</h1>
			 <ul>
        <li>Be hardy in USDA zones 7 and 8 (0&#176; to 10&#176;F/-18&#176; to -12&#176;C).</li>
        <li>Be long-lived.</li>
        <li>Be vigorous and easy to grow by a gardener of average means and experience. Plants requiring specialist knowledge should not be considered.</li>
        <li>Be reasonably disease and pest resistant.</li>
        <li>Have a long season of interest and preferably multiple seasons of interest.</li>
        <li>Be available from at least two retail plant sources in Canada and the United States.</li>
        <li>Be adaptable to a variety of soil and fertility conditions.</li>
        <li>Not require excessive moisture (with the exception of aquatic plants).</li>
        <li>Not be invasive or overly vigorous in colonizing the garden or larger environment.</li>
      </ul>
      <h3>In addition, Great Plant Picks selections should follow these guidelines:</h3>
			 <ul>
        <li>Perennials should be of good constitution and low in maintenance. Plants requiring staking, vigorous deadheading, etc. should not be considered.</li>
        <li>Trees and shrubs should require little pruning and nominal training to achieve their best form (excluding plant used for hedges).
        </li>
        <li>Bulbs should be considered long term perennials, lasting at least two years.</li>
        <li>Variegated plants should be stable and not excessively revert.</li>
      </ul>

      <p><strong>The Great Plant Picks program recommends ornamental plants only. Fruits, vegetables, annuals, and tender perennials will not be considered at this time.</strong></p>
			 </div><!-- .text-wrap -->
  	</div><!-- .content -->
	</div><!-- .wrapper -->