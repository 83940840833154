import { Component, Inject } from '@angular/core';
import { RollbarService } from './services/rollbar.service';
import * as Rollbar from 'rollbar';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gpp-ang';
  gaId = environment.gaId;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private  router: Router) {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.gaId,
            {
              page_path: event.urlAfterRedirects
            }
          );
        }
      }
    );

  }
}
