import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseryService } from '../services/nursery.service';
import { HttpParams } from '@angular/common/http';
import { Nursery } from '../models/nursery_model';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-add-nursery',
  templateUrl: './add-nursery.component.html',
  styleUrls: ['./add-nursery.component.scss']
})
export class AddNurseryComponent implements OnInit {
  nurseryForm: FormGroup;
  submitted = false;
  currentNurseryId = 0;
  currentNursery: Nursery;
  modalTitle = '';
  modalMessage = '';
  closeResult = '';
  private infoModalRef: NgbModalRef;
  private errorModalRef: NgbModalRef;
  private confirmModalRef: NgbModalRef;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private nurseryService: NurseryService,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private router: Router) {
    this.nurseryForm = this.fb.group({
      nurseryName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      areaCode: ['', Validators.compose([Validators.required, Validators.maxLength(3)])],
      prefix: ['', Validators.compose([Validators.required, Validators.maxLength(3)])],
      number: ['', Validators.compose([Validators.required, Validators.maxLength(4)])],
      sales: ['', Validators.required],
      webAddress: ['', Validators.required],
      contactName: [''],
      contactEmail: [''],
      contactPhone: [''],
      publish: ['', Validators.required]
    });
    this.route.paramMap.subscribe((params) => {
      this.ngZone.run(() => {
        this.currentNurseryId = +params.get('nurseryId'); // (+) converts string 'id' to a number
        this.nurseryService.getNurseryById(this.currentNurseryId)
          .subscribe((res) => {
            this.ngZone.run(() => {
              if (res.hasOwnProperty('nursery')) {
                this.currentNursery = res.nursery;
                this.f.nurseryName.setValue(this.currentNursery.nursery_name);
                this.f.address.setValue(this.currentNursery.street_address);
                this.f.city.setValue(this.currentNursery.city);
                this.f.state.setValue(this.currentNursery.state_province);
                this.f.zipCode.setValue(this.currentNursery.zip_postal_code);
                this.f.areaCode.setValue(this.currentNursery.phone_area_code);
                this.f.prefix.setValue(this.currentNursery.phone_prefix);
                this.f.number.setValue(this.currentNursery.phone_number);
                if (this.currentNursery.retail === 'yes') {
                  this.f.sales.setValue('Retail');
                } else {
                  this.f.sales.setValue('Wholesale');
                }
                this.f.webAddress.setValue(this.currentNursery.website_url);
                this.f.contactName.setValue(this.currentNursery.contact_name);
                this.f.contactEmail.setValue(this.currentNursery.contact_email);
                this.f.contactPhone.setValue(this.currentNursery.contact_phone);
                if (this.currentNursery.publish === 'yes') {
                  this.f.publish.setValue('Yes');
                } else {
                  this.f.publish.setValue('No');
                }
              }
            });
          });
      });
    });
  }

  ngOnInit() {
  }

  get f() {
    return this.nurseryForm.controls;
  }

  delete() {
    setTimeout(() => {
      this.confirmModalRef = this.modalService.open(ConfirmModalComponent);
      this.confirmModalRef.result.then((answer) => {
        this.nurseryService.deleteNursery(this.currentNurseryId).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.result.then((result) => {
                  this.router.navigateByUrl('resources/nurserylist');
                });
                this.infoModalRef.componentInstance.infoTitle = 'Nursery Deleted.';
                this.infoModalRef.componentInstance.infoMessage = 'The nursery data was deleted successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'delete nursery data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      });
      this.confirmModalRef.componentInstance.confirmTitle = 'Delete Nursery';
      this.confirmModalRef.componentInstance.confirmMessage = ' delete this nursery from the database';
    }, 500);
  }
  submit() {
    const val = this.nurseryForm.value;
    this.submitted = true;
    console.log(val);
    if (this.nurseryForm.valid) {
      const formData = new HttpParams()
        .set('nurseryName', val.nurseryName)
        .set('address', val.address)
        .set('city', val.city)
        .set('state', val.state)
        .set('zipCode', val.zipCode)
        .set('areaCode', val.areaCode)
        .set('prefix', val.prefix)
        .set('number', val.number)
        .set('sales', val.sales)
        .set('webAddress', val.webAddress)
        .set('contactName', val.contactName)
        .set('contactEmail', val.contactEmail)
        .set('contactPhone', val.contactPhone)
        .set('publish', val.publish);

      if (this.currentNurseryId > 0) {
        this.nurseryService.updateNursery(this.currentNurseryId, formData).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.result.then((result) => {
                  this.router.navigateByUrl('resources/nurserylist');
                });
                this.infoModalRef.componentInstance.infoTitle = 'Nursery Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The nursery data was updated successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'update nursery data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      } else {
        this.nurseryService.addNewNursery(formData).subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.result.then((result) => {
                  this.router.navigateByUrl('resources/nurserylist');
                });
                this.infoModalRef.componentInstance.infoTitle = 'Nursery Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'The nursery data was added successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add new nursery data. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
      }
    }
  }

}
