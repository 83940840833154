<div class="home-image" style="background-image: url(../../assets/plants/clematis-helsingborg.jpg)">
	<h1>Plant Search</h1>
</div>
<div class="wrapper">
       <div class="breadcrumbs">
<ul>
        <li><a [routerLink]="" (click)="gotoSearch()">Helpful Plant Lists/Search by Name</a></li>
        <li><a [routerLink]="" (click)="gotoAdvSearch()" id="current">Plant Search</a></li>
        <li><a [routerLink]="" (click)="gotoPlantNotListed()">Why Can&#8217;t I Find My Plant?</a></li>
</ul>
</div> <!-- end breadcrumbs -->
<div class="content advsearch">
      <h2>Great Plant Picks Advanced Search</h2>
      <h5>Search by plant attributes</h5>
      <p class="note">Select those that apply&#8212;we recommend 1-4 selections to start</p>
      <form [formGroup]="advForm" method="post" accept-charset="utf-8" class="adv-search">
        <div class="control d-none d-sm-block">
        <button type="button" class="searchButton" (click)="findPlant()">Find My Plant</button>
      </div>
        <div class="twocol">
          <fieldset>
            <p class="searchlabel"><label for="plant_type">I'm looking for a (leave blank if no preference):</label></p>
              <label class="choice"><input type="radio" name="plant_type" value="bamboo" formControlName="plant_type">Bamboo</label>
              <label class="choice"><input type="radio" name="plant_type" value="bulb" formControlName="plant_type">Bulb</label>
              <label class="choice"><input type="radio" name="plant_type" value="conifer" formControlName="plant_type">Conifer</label>
              <label class="choice"><input type="radio" name="plant_type" value="fern" formControlName="plant_type">Fern</label>
              <label class="choice"><input type="radio" name="plant_type" value="grass" formControlName="plant_type">Grass</label>
              <label class="choice"><input type="radio" name="plant_type" value="perennial" formControlName="plant_type">Perennial</label>
              <label class="choice"><input type="radio" name="plant_type" value="shrub" formControlName="plant_type">Shrub</label>
              <label class="choice"><input type="radio" name="plant_type" value="tree" formControlName="plant_type">Tree</label>
              <label class="choice"><input type="radio" name="plant_type" value="vine" formControlName="plant_type">Vine</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="zone_low">Hardy to (USDA zone minimum, leave blank if no preference):</label></p>
            <p class="adsearch">USDA Zones 7 and 8 generally cover the maritime Pacific Northwest.</p>
             <label class="choice"><input type="radio" name="zone_low" value="3" formControlName="zone_low">zone 3 (-40&#176;F to -30&#176;F)</label>
              <label class="choice"><input type="radio" name="zone_low" value="4" formControlName="zone_low">zone 4 (-30&#176;F to -20&#176;F)</label>
              <label class="choice"><input type="radio" name="zone_low" value="5" formControlName="zone_low">zone 5 (-20&#176;F to -10&#176;F)</label>
              <label class="choice"><input type="radio" name="zone_low" value="6" formControlName="zone_low">zone 6 (-10&#176;F to 0&#176;F)</label>
             <label class="choice"> <input type="radio" name="zone_low" value="7" formControlName="zone_low">zone 7 (0&#176;F to 10&#176;F)</label>
              <label class="choice"><input type="radio" name="zone_low" value="8" formControlName="zone_low">zone 8 (10&#176;F to 20&#176;F)</label>
              <label class="choice"><input type="radio" name="zone_low" value="9" formControlName="zone_low">zone 9 (20&#176;F to 30&#176;F)</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="foliage_type">Foliage Type (leave blank if no preference):</label></p>
              <label class="choice"><input type="radio" name="foliage_type" value="deciduous" formControlName="foliage_type">Deciduous</label>
              <label class="choice"><input type="radio" name="foliage_type" value="evergreen" formControlName="foliage_type">Evergreen</label>
              <label class="choice"><input type="radio" name="foliage_type" value="semi-evergreen" formControlName="foliage_type">Semi-Evergreen</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="foliage_color">Foliage Color (leave blank if no preference):</label></p>
              <label class="choice"><input type="radio" name="foliage_color" value="black" formControlName="foliage_color">Black</label>
              <label class="choice"><input type="radio" name="foliage_color" value="blue" formControlName="foliage_color">Blue</label>
              <label class="choice"><input type="radio" name="foliage_color" value="bronze" formControlName="foliage_color">Bronze</label>
              <label class="choice"><input type="radio" name="foliage_color" value="burgundy" formControlName="foliage_color">Burgundy</label>
              <label class="choice"><input type="radio" name="foliage_color" value="chartreuse" formControlName="foliage_color">Chartreuse</label>
            <label class="choice"><input type="radio" name="foliage_color" value="colorful" formControlName="foliage_color">Colorful</label>
            <label class="choice"><input type="radio" name="foliage_color" value="dark green" formControlName="foliage_color">Dark Green</label>
            <label class="choice"><input type="radio" name="foliage_color" value="gold" formControlName="foliage_color">Gold</label>
            <label class="choice"><input type="radio" name="foliage_color" value="green" formControlName="foliage_color">Green</label>
            <label class="choice"><input type="radio" name="foliage_color" value="purple" formControlName="foliage_color">Purple</label>
            <label class="choice"><input type="radio" name="foliage_color" value="red" formControlName="foliage_color">Red</label>
            <label class="choice"><input type="radio" name="foliage_color" value="silver" formControlName="foliage_color">Silver</label>
            <label class="choice"><input type="radio" name="foliage_color" value="variegated" formControlName="foliage_color">Variegated</label>
            <label class="choice"><input type="radio" name="foliage_color" value="white" formControlName="foliage_color">White</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="flower_time">Flower Time (leave blank if no preference):</label></p>
              <label class="choice"><input type="radio" name="flower_time" value="winter" formControlName="flower_time">Winter</label>
              <label class="choice"><input type="radio" name="flower_time" value="winter-spring" formControlName="flower_time">Winter-Spring</label>
              <label class="choice"><input type="radio" name="flower_time" value="spring" formControlName="flower_time">Spring</label>
            <label class="choice"><input type="radio" name="flower_time" value="spring-summer" formControlName="flower_time">Spring-Summer</label>
              <label class="choice"><input type="radio" name="flower_time" value="summer" formControlName="flower_time">Summer</label>
              <label class="choice"><input type="radio" name="flower_time" value="summer-fall" formControlName="flower_time">Summer-Fall</label>
              <label class="choice"><input type="radio" name="flower_time" value="fall" formControlName="flower_time">Fall</label>
            <label class="choice"><input type="radio" name="flower_time" value="fall-winter" formControlName="flower_time">Fall-Winter</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="flower_color">Flower Color (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="flower_color" value="black" formControlName="flower_color">Black</label>
              <label class="choice"><input type="radio" name="flower_color" value="blue" formControlName="flower_color">Blue</label>
              <label class="choice"><input type="radio" name="flower_color" value="brown" formControlName="flower_color">Brown</label>
              <label class="choice"><input type="radio" name="flower_color" value="cream" formControlName="flower_color">Cream</label>
              <label class="choice"><input type="radio" name="flower_color" value="green" formControlName="flower_color">Green</label>
              <label class="choice"><input type="radio" name="flower_color" value="lavender" formControlName="flower_color">Lavender</label>
           <label class="choice"><input type="radio" name="flower_color" value="orange" formControlName="flower_color">Orange</label>
              <label class="choice"><input type="radio" name="flower_color" value="pink" formControlName="flower_color">Pink</label>
              <label class="choice"><input type="radio" name="flower_color" value="purple" formControlName="flower_color">Purple</label>
              <label class="choice"><input type="radio" name="flower_color" value="red" formControlName="flower_color">Red</label>
              <label class="choice"><input type="radio" name="flower_color" value="rose" formControlName="flower_color">Rose</label>
              <label class="choice"><input type="radio" name="flower_color" value="violet" formControlName="flower_color">Violet</label>
              <label class="choice"><input type="radio" name="flower_color" value="white" formControlName="flower_color">White</label>
              <label class="choice"><input type="radio" name="flower_color" value="yellow" formControlName="flower_color">Yellow</label>
          </fieldset>
			<fieldset>
            <p class="searchlabel"><label for="design_use">Design Use (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="design_use" value="containers" formControlName="design_use">Containers</label>
			<label class="choice"><input type="radio" name="design_use" value="street tree" formControlName="design_use">Street Tree</label>
			<label class="choice"><input type="radio" name="design_use" value="hedge" formControlName="design_use">Hedge</label>
			<label class="choice"><input type="radio" name="design_use" value="hedge_privacy" formControlName="design_use">Hedge - Privacy</label>
			<label class="choice"><input type="radio" name="design_use" value="hedge_low" formControlName="design_use">Hedge - Low (under 6')</label>
			<label class="choice"><input type="radio" name="design_use" value="hedge_formal" formControlName="design_use">Hedge - Formal (sheared)</label>
			<label class="choice"><input type="radio" name="design_use" value="hedge_informal" formControlName="design_use">Hedge - Informal (unsheared)</label>
			<label class="choice"><input type="radio" name="design_use" value="powerline_friendly_trees" formControlName="design_use">Powerline Friendly Trees</label>
        <label class="choice"><input type="radio" name="design_use" value="urban_sturdy" formControlName="design_use">Urban Sturdy</label>
        <label class="choice"><input type="radio" name="design_use" value="snug_spaces" formControlName="design_use">Snug Spaces</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="pest_resistance">Pest Resistance (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="pest_resistance" value="deer" formControlName="pest_resistance">Deer</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="wildlife">The Birds &amp; The Bees (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="wildlife" value="birds" formControlName="wildlife">Birds</label>
              <label class="choice"><input type="radio" name="wildlife" value="bees" formControlName="wildlife">Bees</label>
              <label class="choice"><input type="radio" name="wildlife" value="butterflies" formControlName="wildlife">Butterflies</label>
              <label class="choice"><input type="radio" name="wildlife" value="hummingbirds" formControlName="wildlife">Hummingbirds</label>
          </fieldset>

          <fieldset>
            <p class="searchlabel"><label>Plant Height (in feet, leave blank if no preference):</label></p>
            Minimum: <select id="plant_height_min" type="text" name="plant_height_min" formControlName="plant_height_min">
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
          </select>
            Maximum: <select id="plant_height_at_10" type="text" name="plant_height_at_10" formControlName="plant_height_at_10">
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
            <option value="55">55</option>
            <option value="60">60</option>
            <option value="200">or larger</option>
          </select>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label>Plant Width (in feet, leave blank if no preference):</label></p>
            Minimum: <select id="plant_width_min" type="text" name="plant_width_min" formControlName="plant_width_min">
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
          </select>
            Maximum: <select id="plant_width_at_10" type="text" name="plant_width_at_10" formControlName="plant_width_at_10">
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>
            <option value="200">or more</option>
          </select>
          </fieldset>

          <fieldset>
            <p class="searchlabel"><label for="growth_habit">Growth Habit (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="growth_habit" value="clumping" formControlName="growth_habit">Clumping</label>
              <label class="choice"><input type="radio" name="growth_habit" value="columnar" formControlName="growth_habit">Columnar</label>
              <label class="choice"><input type="radio" name="growth_habit" value="compact" formControlName="growth_habit">Compact</label>
              <label class="choice"><input type="radio" name="growth_habit" value="mounding" formControlName="growth_habit">Mounding</label>
              <label class="choice"><input type="radio" name="growth_habit" value="narrow" formControlName="growth_habit">Narrow</label>
              <label class="choice"><input type="radio" name="growth_habit" value="pyramidal" formControlName="growth_habit">Pyramidal</label>
            <label class="choice"><input type="radio" name="growth_habit" value="round" formControlName="growth_habit">Round</label>
            <label class="choice"><input type="radio" name="growth_habit" value="spreading" formControlName="growth_habit">Spreading</label>
            <label class="choice"><input type="radio" name="growth_habit" value="upright" formControlName="growth_habit">Upright</label>
            <label class="choice"><input type="radio" name="growth_habit" value="vase-shaped" formControlName="growth_habit">Vase Shaped</label>
          <label class="choice"><input type="radio" name="growth_habit" value="vining" formControlName="growth_habit">Vining</label>
            <label class="choice"><input type="radio" name="growth_habit" value="weeping" formControlName="growth_habit">Weeping</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="sun">Sun Requirements (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="sun" value="full_sun" formControlName="sun">Full Sun (6 or more hours of sun)</label>
              <label class="choice"><input type="radio" name="sun" value="light_shade" formControlName="sun">Light Shade (4-6 hours of sun)</label>
              <label class="choice"><input type="radio" name="sun" value="dappled_shade" formControlName="sun">Dappled Shade (2-4 hours of sun under a canopy of trees)</label>
              <label class="choice"><input type="radio" name="sun" value="open_shade" formControlName="sun">Open Shade (Bright light, but no direct sun, no overhanging trees)</label>
              <label class="choice"><input type="radio" name="sun" value="deep_shade" formControlName="sun">Deep Shade (No direct sun under a canopy of trees)</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="soil">Soil Type (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="soil" value="clay" formControlName="soil">Clay</label>
              <label class="choice"><input type="radio" name="soil" value="wet" formControlName="soil">Wet</label>
              <label class="choice"><input type="radio" name="soil" value="Moist" formControlName="soil">Moist</label>
              <label class="choice"><input type="radio" name="soil" value="dry" formControlName="soil">Dry</label>
              <label class="choice"><input type="radio" name="soil" value="sandy" formControlName="soil">Sandy</label>
            <label class="choice"><input type="radio" name="soil" value="well-drained" formControlName="soil">Well-Drained</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="water">Water Requirements (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="water" value="frequent" formControlName="water">Frequent Watering (Water two-three times per week during dry weather)</label>
              <label class="choice"><input type="radio" name="water" value="regular" formControlName="water">Regular Watering (Water once per week during dry weather)</label>
            <label class="choice"><input type="radio" name="water" value="occasional" formControlName="water">Occasional Watering (Water every 10-14 days during dry weather)</label>
            <label class="choice"><input type="radio" name="water" value="drought-tolerant" formControlName="water">Drought Tolerant Once Established (Once established, water once per month)</label>
          </fieldset>
          <fieldset>
            <p class="searchlabel"><label for="scent">Fragrance (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="scent" value="flowers" formControlName="scent">Fragrant Flowers</label>
              <label class="choice"><input type="radio" name="scent" value="foliage" formControlName="scent">Fragrant Foliage</label>
              <label class="choice"><input type="radio" name="scent" value="floral" formControlName="scent">Floral Scent</label>
            <label class="choice"><input type="radio" name="scent" value="fresh" formControlName="scent">Fresh Scent</label>
              <label class="choice"><input type="radio" name="scent" value="spicy" formControlName="scent">Spicy Scent</label>
              <label class="choice"><input type="radio" name="scent" value="woodsy" formControlName="scent">Woodsy Scent</label>
          </fieldset>
          <!--<fieldset>
            <p class="searchlabel"><label for="spring">Spring Interest (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="spring" value="spring_flowers" formControlName="spring">Spring Flowers</label>
              <label class="choice"><input type="radio" name="spring" value="spring_foliage" formControlName="spring">Spring Foliage</label>
            <label class="choice"><input type="radio" name="spring" value="march" formControlName="spring">March</label>
              <label class="choice"><input type="radio" name="spring" value="april" formControlName="spring">April</label>
              <label class="choice"><input type="radio" name="spring" value="may" formControlName="spring">May</label>
          </fieldset>-->
          <fieldset>
            <p class="searchlabel"><label for="romantic_seasons">Romantic Seasons (leave blank if no preference):</label></p>
            <label class="choice"><input type="radio" name="romantic_seasons" value="winter" formControlName="romantic_seasons">Winter</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="winter-spring" formControlName="romantic_seasons">Winter-Spring</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="spring" formControlName="romantic_seasons">Spring</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="spring-summer" formControlName="romantic_seasons">Spring-Summer</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="summer" formControlName="romantic_seasons">Summer</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="summer-fall" formControlName="romantic_seasons">Summer-Fall</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="fall" formControlName="romantic_seasons">Fall</label>
            <label class="choice"><input type="radio" name="romantic_seasons" value="fall-winter" formControlName="romantic_seasons">Fall-Winter</label>
          </fieldset>

        </div>
        <div class="control">
          <button type="button" class="searchButton" (click)="findPlant()">Find My Plant</button>
        </div>
      </form>
    </div>
</div><!-- .wrapper -->
