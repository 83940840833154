<div class="wrapper nobanner">
  <h1 class="text-center">The page you are looking for has been moved or changed.</h1>
	<div class="text-wrap">
	<p>No, you're not in the wrong place! The Great Plant Picks website has been revamped, and some of the page names have changed or relocated.</p>
	<p>To find plant lists, please visit <a href="https://www.greatplantpicks.org/searchnav">Helpful Plant Lists</a>, this is where you'll find the lists of all Great Plant Picks, drought tolerant plants, plants for shade or sun, and more. Or, make a selection from the menu above. And thank you for visiting Great Plant Picks!</p>
	<p>Check out:</p>
	<ul>
		<li><a href="https://www.greatplantpicks.org/searchnav">Helpful Lists</a></li>
		<li><a href="https://www.greatplantpicks.org/search/advanced">Advanced Search</a></li>
		
		</ul>
		</div>
</div>
