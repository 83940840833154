<div class="home-image results-page" style="background-image: url(../../assets/plants/Helianthemum-Primrose.jpg)">
</div>
  <div id="content" class="view">

  <h2>Great Plant Picks Search Results</h2>

    <div *ngIf="noData">
      <p class="flash">Sorry, no plants meet your criteria.  Please try again.<br />
    </div>

    <p class="center">Found {{ totalFound }} plants <span class="click">(click column to sort)</span></p>
    <h3 class="text-center">{{ searchCategory }}</h3>
  <span class='clear-search'>
    <a [routerLink]="" (click)="gotoAdvSearch()">Back to Advanced Search</a> | <a [routerLink]="" (click)="gotoSearch()">Back to Lists</a>
  </span>
    <div class="row">
      <div class="col-md-12 mx-auto text-center">
        <div class="ng-autocomplete d-none d-sm-inline-flex mb-3" style="width: 900px;display: inline-flex;justify-content: center;">
          <span style="align-self: center;margin-right: 10px;">Search by plant name:</span>
          <ng-autocomplete
            [data]="options"
            [placeHolder]="placeholder"
            [searchKeyword]="keyword"
            (selected)='selectEvent($event)'
            (inputChanged)='onChangeSearch($event)'
            (inputFocused)='onFocused($event)'
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.common_name" class="text-left"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <ng-template #plantMenu let-item>
            <section class="plant-menu">
              <div (click)="openInNewTab(item)">Open in New Tab</div>
            </section>
          </ng-template>
          <button #searchButton class="search-button" (click)="basicSearch()">Search</button>
        </div>
        <div class="d-block d-sm-none text-center">
          <span>Search by plant name:</span>
          <input class="input w-100" [formControl]="basicSearchTermsInput"
                 placeholder="Enter botanical or common name">
          <button class="search-button" (click)="basicSearchMobile()">Search</button>
        </div>
      </div>
    </div>

    <p class="mt-2 mb-3 text-center">
      <strong>Actions:</strong>
      <a class="btn btn-info ml-3 text-white" href="{{emailHref}}">Email this list</a>
      <a class="btn btn-info ml-3" (click)="copyLink()">Copy this link</a>

    </p>
    <p><strong>Please note that “Height” represents the height at maturity.</strong></p>
<div class="table-wrap">
      <!-- DATATABLE  SAVE DZ class table table-hover table-bordered display pl-2 pr-2 py-0 w-100 -->
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
             class="nursery responsive-sm table-striped w-auto"
             style="cursor: pointer;">
      </table>
</div>
</div>

<!-- end content -->
