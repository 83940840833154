<div class="home-image" style="background-image: url(../../assets/gpp-booth-2019.jpg)">	
	<h1>Great Plant Picks Staff</h1>
</div> 
<div class="wrapper">
     <div class="breadcrumbs">
<ul>

       <li><a [routerLink]="" (click)="gotoAbout()">About GPP</a></li>
       <li><a [routerLink]="" (click)="gotoAboutCriteria()">Selection Criteria</a></li>
       <li><a [routerLink]="" (click)="gotoAboutCommittee()">Selection Committees</a></li>
       <li><a [routerLink]="" (click)="gotoAboutGroups()">Advisors</a></li>
        <li><a [routerLink]="" id="current">GPP Staff</a></li>
</ul>
      </div><!-- end breadcrumbs -->
      
      
 <div class="content">
		 <div class="text-wrap">
	  <h2>Richie Steffen, Miller Garden Executive Director</h2>
      <img class="alignleft" title="Richie Steffen, Miller Garden Executive Director" src="assets/richie-steffen-2020.jpg">
      <p>Anyone who is acquainted with Richie knows he has horticultural exuberance! He loves plants and he willingly shares his vast knowledge of the plant kingdom. Richie joined the Miller Garden in 2000, bringing with him a variety of horticultural expertise. After moving from Maryland to Seattle in 1989, he worked at Sky Nursery in Shoreline, the Rhododendron Species Botanical Garden in Federal Way, and as a part-time instructor for the horticulture program at Edmonds Community College before beginning work at the Miller Garden. In May 2017 Richie became director of the Miller Garden and he oversees all aspects of the Garden, including the plant collections, educational programs, and staff.</p>
      <p>He also currently serves as a board member of the Northwest Horticultural Society as well as board member and president of the Hardy Fern Foundation.</p>
	 <p>Richie is always on the hunt for what is new in horticulture throughout the country by travelling, plant collecting, visiting gardens and networking with other horticulture professionals. He regularly lectures and writes and is always ready to share his love for plants. Richie also enjoys photography and his photos can be seen in many regional publications as well as the websites for the Miller Garden and Great Plant Picks.</p>
       
      <h2>Rick Peterson, Education &amp; Events Manager</h2>
	 <img class="alignleft" title="Rick Peterson, Great Plant Picks Manager, with Rhododendron parmalutaum 'Ocelot'" src="assets/rick-peterson.jpg">
      <p>Rick became the Great Plant Picks Manager in October 2009 to oversee the primary educational program of the Miller Garden. In 2017 he assumed the additional responsibilities for garden events and scheduling garden tours. Prior to working at the Garden he spent 24 years as garden manager at the Rhododendron Species Foundation in Federal Way, Washington, including ten years as co-executive director.</p>
    </div><!-- end content -->
</div>
	</div><!-- .wrapper -->