<div class="wrapper" style="margin-top: 160px;">
  <div class="text-wrap">

    <h1>Great Plant Picks Admin</h1>
    <p style="text-align:center;">Use this form to add a new plant or edit an existing plant. To edit, go to "Helpful Lists" and find your plant. Open the plant fact sheet and select "Edit This Plant".</p>
  </div>

  <div class="link-container bg-light p-3" style="box-sizing: border-box;">
    <form [formGroup]="plantForm">
      <div class="row mt-2">
        <div class="col-md-12 text-center">
          <h3 *ngIf="currentPlantId > 0" class="font-weight-bold"><span [innerHTML]="botanicalName"></span></h3>
          <h3 *ngIf="currentPlantId === 0" class="font-weight-bold">Plant Data for new plant</h3>
        </div>
      </div>
      <fieldset>
        <div class="row mb-2">
          <div class="col-md-12 text-right">
            <div class="d-inline-flex">
              <span style="align-self: center;margin-right: 10px;">Publish:</span>
              <select formControlName="publish" class="form-control form-control-sm">
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
              <button class="btn btn-primary ml-2"
                      (click)="save()">{{ saveButtonTitle }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs bg-light">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Name</a>
                <ng-template ngbNavContent>
                  <p>Plant Data Basic Info</p>
                  <div class="p-3">
                    <div class="twocol">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Plant Id:</label>
                        </div>
                        <div class="col-md-8">
                          <input class="w-100 form-control form-control-sm" name="plantId"
                                 formControlName="plantId">
                        </div>
                      </div>
                      <div class="row d-none">
                        <div class="col-md-4">
                          <label>Plant Family:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="1" class="w-100 form-control form-control-sm" name="plantFamily"
                                 formControlName="plantFamily">
                        </div>
                      </div>
                      <div class="row d-none">
                        <div class="col-md-4">
                          <label>Family Common Name:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="2" class="w-100 form-control form-control-sm" name="familyCommonName"
                                 formControlName="familyCommonName">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Genus:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="3" class="w-100 form-control form-control-sm" name="genus" formControlName="genus"
                                 [ngClass]="{ 'is-invalid': submitted && f.genus.errors }">
                          <div *ngIf="submitted && f.genus.errors" class="invalid-feedback">
                            <div *ngIf="f.genus.errors.required">This field is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Cross Genus:</label>
                        </div>
                        <div class="col-md-8">
                          <select tabindex="4" formControlName="crossGenus" class="w-100 form-control form-control-sm">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Cross Specific Epithet:</label>
                        </div>
                        <div class="col-md-8">
                          <select tabindex="5" formControlName="crossSpecificEpithet" class="form-control form-control-sm">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Specific Epithet:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="6" class="w-100 form-control form-control-sm" name="specificEpithet"
                                 formControlName="specificEpithet">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Infraspecific Epithet Designator:</label>
                        </div>
                        <div class="col-md-8">
                          <select tabindex="7" formControlName="infraspecificEpithetDesignator" class="w-100 form-control form-control-sm">
                            <option value="">None</option>
                            <option value="var.">var.</option>
                            <option value="ssp.">ssp.</option>
                            <option value="f.">f.</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Infraspecific Epithet:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="8" class="w-100 form-control form-control-sm" name="infraspecificEpithet"
                                 formControlName="infraspecificEpithet" [ngClass]="{ 'is-invalid': submitted && f.infraspecificEpithet.errors }">
                          <div *ngIf="submitted && f.infraspecificEpithet.errors" class="invalid-feedback">
                            <div *ngIf="f.infraspecificEpithet.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row d-none">
                        <div class="col-md-4">
                          <label>Cross Species:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="9" class="w-100 form-control form-control-sm" name="crossSpecies"
                                 formControlName="crossSpecies" [ngClass]="{ 'is-invalid': submitted && f.crossSpecies.errors }">
                          <div *ngIf="submitted && f.crossSpecies.errors" class="invalid-feedback">
                            <div *ngIf="f.crossSpecies.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Cultivar:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="10" class="w-100 form-control form-control-sm" name="cultivar" formControlName="cultivar" [ngClass]="{ 'is-invalid': submitted && f.cultivar.errors }">
                          <div *ngIf="submitted && f.cultivar.errors" class="invalid-feedback">
                            <div *ngIf="f.cultivar.errors.maxlength">This field is limited to 200 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Trade Name:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="11" class="w-100 form-control form-control-sm" name="tradeName" formControlName="tradeName" [ngClass]="{ 'is-invalid': submitted && f.tradeName.errors }">
                          <div *ngIf="submitted && f.tradeName.errors" class="invalid-feedback">
                            <div *ngIf="f.tradeName.errors.maxlength">This field is limited to 55 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Trademark Name:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="12" class="w-100 form-control form-control-sm" name="trademarkName"
                                 formControlName="trademarkName" [ngClass]="{ 'is-invalid': submitted && f.trademarkName.errors }">
                          <div *ngIf="submitted && f.trademarkName.errors" class="invalid-feedback">
                            <div *ngIf="f.trademarkName.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Registered Name:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="13" class="w-100 form-control form-control-sm" name="registeredName"
                                 formControlName="registeredName" [ngClass]="{ 'is-invalid': submitted && f.registeredName.errors }">
                          <div *ngIf="submitted && f.registeredName.errors" class="invalid-feedback">
                            <div *ngIf="f.registeredName.errors.maxlength">This field is limited to 55 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Plant Patent Number:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="14" class="w-100 form-control form-control-sm" name="plantPatentNumber"
                                 formControlName="plantPatentNumber" [ngClass]="{ 'is-invalid': submitted && f.plantPatentNumber.errors }">
                          <div *ngIf="submitted && f.plantPatentNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.plantPatentNumber.errors.maxlength">This field is limited to 10 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>PPAF:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="15" class="w-100 form-control form-control-sm" name="plantPatentNumberAppliedFor"
                                 formControlName="plantPatentNumberAppliedFor" [ngClass]="{ 'is-invalid': submitted && f.plantPatentNumberAppliedFor.errors }">
                          <div *ngIf="submitted && f.plantPatentNumberAppliedFor.errors" class="invalid-feedback">
                            <div *ngIf="f.plantPatentNumberAppliedFor.errors.maxlength">This field is limited to 11 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Plant Breeders Rights:</label>
                        </div>
                        <div class="col-md-8">
                          <select tabindex="16" formControlName="plantBreedersRights" class="form-control form-control-sm">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Plant Name Group:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="17" class="w-100 form-control form-control-sm" name="plantNameGroup"
                                 formControlName="plantNameGroup" [ngClass]="{ 'is-invalid': submitted && f.plantNameGroup.errors }">
                          <div *ngIf="submitted && f.plantNameGroup.errors" class="invalid-feedback">
                            <div *ngIf="f.plantNameGroup.errors.maxlength">This field is limited to 200 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Form:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="18" class="w-100 form-control form-control-sm" name="formField"
                                 formControlName="formField" [ngClass]="{ 'is-invalid': submitted && f.formField.errors }">
                          <div *ngIf="submitted && f.formField.errors" class="invalid-feedback">
                            <div *ngIf="f.formField.errors.maxlength">This field is limited to 150 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>Horticultural Classification or Pruning Group:</label>
                        </div>
                        <div class="col-md-8">
                          <input tabindex="19" class="w-100 form-control form-control-sm" name="pruningGroup"
                                 formControlName="pruningGroup" [ngClass]="{ 'is-invalid': submitted && f.pruningGroup.errors }">
                          <div *ngIf="submitted && f.pruningGroup.errors" class="invalid-feedback">
                            <div *ngIf="f.pruningGroup.errors.maxlength">This field is limited to 55 characters</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Quick Facts</a>
                <ng-template ngbNavContent>
                  <div class="twocol">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Native To GPP Region:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="1" class="w-100 form-control form-control-sm" name="nativeToGppRegion"
                               formControlName="nativeToGppRegion" [ngClass]="{ 'is-invalid': submitted && f.nativeToGppRegion.errors }">
                        <div *ngIf="submitted && f.nativeToGppRegion.errors" class="invalid-feedback">
                          <div *ngIf="f.nativeToGppRegion.errors.maxlength">This field is limited to 22 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Plant Type:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="2" formControlName="plantType" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="bamboo">Bamboo</option>
                          <option value="bulb">Bulb</option>
                          <option value="conifer">Conifer</option>
                          <option value="fern">Fern</option>
                          <option value="grass">Grass</option>
                          <option value="perennial">Perennial</option>
                          <option value="shrub">Shrub</option>
                          <option value="tree">Tree</option>
                          <option value="vine">Vine</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Foliage Type:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="3" formControlName="foliageType" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="deciduous">Deciduous</option>
                          <option value="evergreen">Evergreen</option>
                          <option value="semi-evergreen">Semi-Evergreen</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Growth Habit:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="4" formControlName="growthHabit" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="clumping">Clumping</option>
                          <option value="columnar">Columnar</option>
                          <option value="compact">Compact</option>
                          <option value="mounding">Mounding</option>
                          <option value="narrow">Narrow</option>
                          <option value="oval">Oval</option>
                          <option value="pyramidal">Pyramidal</option>
                          <option value="round">Round</option>
                          <option value="spreading">Spreading</option>
                          <option value="upright">Upright</option>
                          <option value="vase-shaped">Vase Shaped</option>
                          <option value="vining">Vining</option>
                          <option value="weeping">Weeping</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Sun Exposure:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="5" class="w-100 form-control form-control-sm" name="sunExposure"
                               formControlName="sunExposure" [ngClass]="{ 'is-invalid': submitted && f.sunExposure.errors }">
                        <div *ngIf="submitted && f.sunExposure.errors" class="invalid-feedback">
                          <div *ngIf="f.sunExposure.errors.maxlength">This field is limited to 255 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Watering Requirements:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="6" class="w-100 form-control form-control-sm" name="waterRequirements"
                               formControlName="waterRequirements" [ngClass]="{ 'is-invalid': submitted && f.waterRequirements.errors }">
                        <div *ngIf="submitted && f.waterRequirements.errors" class="invalid-feedback">
                          <div *ngIf="f.waterRequirements.errors.maxlength">This field is limited to 255 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none">
                      <div class="col-md-4">
                        <label>Soil Requirements:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="7" class="w-100 form-control form-control-sm" name="soilRequirements"
                               formControlName="soilRequirements" [ngClass]="{ 'is-invalid': submitted && f.soilRequirements.errors }">
                        <div *ngIf="submitted && f.soilRequirements.errors" class="invalid-feedback">
                          <div *ngIf="f.soilRequirements.errors.maxlength">This field is limited to 255 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Flower Showy:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="8" formControlName="flowerShowy" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Flower Time:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="9" formControlName="flowerTime" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="fall">Fall</option>
                          <option value="fall-winter">Fall-Winter</option>
                          <option value="winter">Winter</option>
                          <option value="winter-spring">Winter-Spring</option>
                          <option value="spring">Spring</option>
                          <option value="spring-summer">Spring-Summer</option>
                          <option value="summer">Summer</option>
                          <option value="summer-fall">Summer-Fall</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Fruit/Seedhead Attractive:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="10" formControlName="fruitAttractive" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Fragrance:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="11" class="w-100 form-control form-control-sm" name="fragrance"
                               formControlName="fragrance" [ngClass]="{ 'is-invalid': submitted && f.fragrance.errors }">
                        <div *ngIf="submitted && f.fragrance.errors" class="invalid-feedback">
                          <div *ngIf="f.fragrance.errors.maxlength">This field is limited to 45 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Seasonal Interest:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="12" class="w-100 form-control form-control-sm" name="seasonalInterest"
                               formControlName="seasonalInterest" [ngClass]="{ 'is-invalid': submitted && f.seasonalInterest.errors }">
                        <div *ngIf="submitted && f.seasonalInterest.errors" class="invalid-feedback">
                          <div *ngIf="f.seasonalInterest.errors.maxlength">This field is limited to 200 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Bark Interest:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="13" formControlName="barkInterest" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Plant Width at 10:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="14" class="w-100 form-control form-control-sm" name="plantWidthAt10"
                               formControlName="plantWidthAt10" [ngClass]="{ 'is-invalid': submitted && f.plantWidthAt10.errors }">
                        <div *ngIf="submitted && f.plantWidthAt10.errors" class="invalid-feedback">
                          <div *ngIf="f.plantWidthAt10.errors.maxlength">This field is limited to 11 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Plant Height at 10:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="15" class="w-100 form-control form-control-sm" name="plantHeightAt10"
                               formControlName="plantHeightAt10" [ngClass]="{ 'is-invalid': submitted && f.plantHeightAt10.errors }">
                        <div *ngIf="submitted && f.plantHeightAt10.errors" class="invalid-feedback">
                          <div *ngIf="f.plantHeightAt10.errors.maxlength">This field is limited to 11 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Plant Width Max:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="16" class="w-100 form-control form-control-sm" name="plantWidthMax"
                               formControlName="plantWidthMax" [ngClass]="{ 'is-invalid': submitted && f.plantWidthMax.errors }">
                        <div *ngIf="submitted && f.plantWidthMax.errors" class="invalid-feedback">
                          <div *ngIf="f.plantWidthMax.errors.maxlength">This field is limited to 11 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Plant Height Max:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="17" class="w-100 form-control form-control-sm" name="plantHeightMax"
                               formControlName="plantHeightMax" [ngClass]="{ 'is-invalid': submitted && f.plantHeightMax.errors }">
                        <div *ngIf="submitted && f.plantHeightMax.errors" class="invalid-feedback">
                          <div *ngIf="f.plantHeightMax.errors.maxlength">This field is limited to 11 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Zone Low:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="18" class="w-100 form-control form-control-sm" name="zoneLow"
                               formControlName="zoneLow" [ngClass]="{ 'is-invalid': submitted && f.zoneLow.errors }">
                        <div *ngIf="submitted && f.zoneLow.errors" class="invalid-feedback">
                          <div *ngIf="f.zoneLow.errors.maxlength">This field is limited to 2 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Zone High:</label>
                      </div>
                      <div class="col-md-8">
                        <input tabindex="19" class="w-100 form-control form-control-sm" name="zoneHigh"
                               formControlName="zoneHigh" [ngClass]="{ 'is-invalid': submitted && f.zoneHigh.errors }">
                        <div *ngIf="submitted && f.zoneHigh.errors" class="invalid-feedback">
                          <div *ngIf="f.zoneHigh.errors.maxlength">This field is limited to 2 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Romantic Seasons:</label>
                      </div>
                      <div class="col-md-8">
                        <select tabindex="20" formControlName="romanticSeasons" class="form-control form-control-sm">
                          <option value="">Unspecified</option>
                          <option value="fall">Fall</option>
                          <option value="fall-winter">Fall-Winter</option>
                          <option value="winter">Winter</option>
                          <option value="winter-spring">Winter-Spring</option>
                          <option value="spring">Spring</option>
                          <option value="spring-summer">Spring-Summer</option>
                          <option value="summer">Summer</option>
                          <option value="summer-fall">Summer-Fall</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Notes</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Qualities:</label>
                    </div>
                    <div class="col-md-6">
                      <label>Culture Notes:</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="qualities" rows="6"></textarea>
                    </div>
                    <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="cultureNotes" rows="6"></textarea>
                    </div>
                  </div>
                  <div class="row">
					  <div class="col-md-6">
                      <label>Growing Notes:</label>
                    </div>

                    <div class="col-md-6">
                      <label>Plant Combinations:</label>
                    </div>
                  </div>
                  <div class="row">
					  <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="growingNotes" rows="6"></textarea>
                    </div>

                    <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="plantCombinations" rows="6"></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Color Contrast:</label>
                    </div>
                    <div class="col-md-6">
                      <label>Color Partners:</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="colorContrast" rows="6"></textarea>
                    </div>
                    <div class="col-md-6">
                      <textarea class="w-100 form-control form-control-sm" formControlName="colorPartners" rows="6"></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label>Geek Notes:</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <textarea class="w-100 form-control form-control-sm" formControlName="geekNotes" rows="6"></textarea>
                    </div>
                  </div>

                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink>Status</a>
                <ng-template ngbNavContent>
                  <div class="row">
					  <div class="col-md-2">
                    <label>Status:</label>
						  </div>
                      <div class="col-md-4">
                      <input tabindex="7" class="w-100 form-control form-control-sm" name="status"
                             formControlName="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                      <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                        <div *ngIf="f.status.errors.maxlength">This field is limited to 45 characters</div>
						  </div>
                    </div>
                     <div class="col-md-2">
                      <label>GPP Year:</label>
                    </div>
                    <div class="col-md-4">
                      <input tabindex="9" class="w-100 form-control form-control-sm" name="gppYear"
                             formControlName="gppYear" [ngClass]="{ 'is-invalid': submitted && f.gppYear.errors }">
                      <div *ngIf="submitted && f.gppYear.errors" class="invalid-feedback">
                        <div *ngIf="f.gppYear.errors.maxlength">This field is limited to 4 characters</div>
                      </div>
                    </div>
					</div>

                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink>Advanced Search</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="advForm" method="post" accept-charset="utf-8" class="adv-search">
                        <div class="twocol">
                          <h3 class="font-weight-bold">Water</h3>
                          <fieldset class="mb-2">
                          <div *ngFor="let water of waterData; let i=index" class="d-inline-block">
                            <label class="mr-3">
                              <input type="checkbox" [value]="water.value" (change)="onCheckboxChange($event, 'waterCheckArray')" [checked]="isChecked('waterCheckArray', water.value)"/>
                              {{water.name}}
                            </label>
                          </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Sun/Shade</h3>
                          <fieldset class="mb-2">
                          <div *ngFor="let sun of sunData; let i=index" class="d-inline-block">
                            <label class="mr-3">
                              <input type="checkbox" [value]="sun.value" (change)="onCheckboxChange($event, 'sunCheckArray')" [checked]="isChecked('sunCheckArray', sun.value)" />
                              {{sun.name}}
                            </label>
                          </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Soil</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let soil of soilData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="soil.value" (change)="onCheckboxChange($event, 'soilCheckArray')" [checked]="isChecked('soilCheckArray', soil.value)" />
                                {{soil.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Growth Habit</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let habit of habitData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="habit.value" (change)="onCheckboxChange($event, 'habitCheckArray')" [checked]="isChecked('habitCheckArray', habit.value)" />
                                {{habit.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Wildlife and Pollinators</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let wildlife of wildlifeData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="wildlife.value" (change)="onCheckboxChange($event, 'wildlifeCheckArray')" [checked]="isChecked('wildlifeCheckArray', wildlife.value)" />
                                {{wildlife.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Pest Resistance</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let pest of pestData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="pest.value" (change)="onCheckboxChange($event, 'pestResistanceCheckArray')" [checked]="isChecked('pestResistanceCheckArray', pest.value)" />
                                {{pest.name}}
                              </label>
                            </div>
                          </fieldset>

                          <h3 class="font-weight-bold">Flower Time</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let ftime of flowerTimeData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="ftime.value" (change)="onCheckboxChange($event,'flowerTimeCheckArray')" [checked]="isChecked('flowerTimeCheckArray', ftime.value)" />
                                {{ftime.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Flower Color</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let fcolor of flowerColorData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="fcolor.value" (change)="onCheckboxChange($event,'flowerColorCheckArray')" [checked]="isChecked('flowerColorCheckArray', fcolor.value)" />
                                {{fcolor.name}}
                              </label>
                            </div>
                          </fieldset>
                          <div class="fieldgroup">
                            <h3 class="font-weight-bold">Foliage Color</h3>
                            <fieldset class="mb-2">
                              <div *ngFor="let foColor of foliageColorData; let i=index" class="d-inline-block">
                                <label class="mr-3">
                                  <input type="checkbox" [value]="foColor.value" (change)="onCheckboxChange($event,'foliageColorCheckArray')" [checked]="isChecked('foliageColorCheckArray', foColor.value)" />
                                  {{foColor.name}}
                                </label>
                              </div>
                            </fieldset>
                          </div>
                          <h3 class="font-weight-bold">Foliage Texture</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let texture of foliageTextureData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="texture.value" (change)="onCheckboxChange($event,'foliageTextureCheckArray')" [checked]="isChecked('foliageTextureCheckArray', texture.value)" />
                                {{texture.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Design Use</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let use of designUseData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="use.value" (change)="onCheckboxChange($event,'designUseCheckArray')" [checked]="isChecked('designUseCheckArray', use.value)" />
                                {{use.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Fragrance</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let scent of scentData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="scent.value" (change)="onCheckboxChange($event,'scentCheckArray')" [checked]="isChecked('scentCheckArray', scent.value)" />
                                {{scent.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Spring</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let spring of springData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="spring.value" (change)="onCheckboxChange($event,'springCheckArray')" [checked]="isChecked('springCheckArray', spring.value)" />
                                {{spring.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Theme</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let theme of themeData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="theme.value" (change)="onCheckboxChange($event,'themeCheckArray')" [checked]="isChecked('themeCheckArray', theme.value)" />
                                {{theme.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Bright</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let bright of brightData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="bright.value" (change)="onCheckboxChange($event,'brightCheckArray')" [checked]="isChecked('brightCheckArray', bright.value)" />
                                {{bright.name}}
                              </label>
                            </div>
                          </fieldset>
                          <h3 class="font-weight-bold">Romantic Seasons</h3>
                          <fieldset class="mb-2">
                            <div *ngFor="let aSeason of romanticSeasonsData; let i=index" class="d-inline-block">
                              <label class="mr-3">
                                <input type="checkbox" [value]="aSeason.value" (change)="onCheckboxChange($event,'romanticSeasonsCheckArray')" [checked]="isChecked('romanticSeasonsCheckArray', aSeason.value)" />
                                {{aSeason.name}}
                              </label>
                            </div>
                          </fieldset>

                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="6">
                <a ngbNavLink>Common Names</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <h4>Current Common Names</h4>
                    </div>
                  </div>
                  <div class="row">
                    <ul class="col-md-12 ml-5">
                      <li *ngFor="let cname of commonNamesArray" class="mb-3">
                        <div  class="d-inline-flex">
                          <span [innerHTML]="cname" class="align-self-center"></span>
                          <button type="button" class="btn btn-info ml-3" (click)="editCommon(cname)">Edit</button>
                          <button type="button" class="btn btn-info ml-2" (click)="deleteCommon(cname)">Delete</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="commonNamesArray.length === 0" class="row">
                    <div class="col-md-10 ml-2">
                      No Common Names associated with this plant
                    </div>
                  </div>
                  <form [formGroup]="cnameForm" method="post" accept-charset="utf-8" class="adv-search">
                    <input type="hidden" name="cnameId" formControlName="cnameId">
                    <div class="row mt-4">
                      <div class="col-md-3">
                        <button type="button" class="btn btn-info" (click)="addNewCommon()">Add a new common name</button>
                      </div>
                    </div>
                    <hr>
                    <div *ngIf="showCommonForm" class="row">
                      <div class="col-md-2">
                        Common Name:
                      </div>
                      <div class="col-md-6">
                        <input class="w-100 form-control form-control-sm" name="commonName"
                               formControlName="commonName" [ngClass]="{ 'is-invalid': cnamesubmitted && cnf.commonName.errors }">
                        <div *ngIf="cnamesubmitted && cnf.commonName.errors" class="invalid-feedback">
                          <div *ngIf="cnf.commonName.errors.required">This field is required</div>
                        </div>
                        <input type="hidden" name="plantId" formControlName="plantId">
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-info ml-2" (click)="saveCName()" [disabled]="currentPlantId === 0">
                          <span *ngIf="!editingCommon">Save Common Name</span>
                          <span *ngIf="editingCommon">Update Common Name</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </li>
              <li [ngbNavItem]="7">
                <a ngbNavLink>Synonyms</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-12">
                      <h4>Current Synonyms</h4>
                    </div>
                  </div>
                  <div class="row">
                    <ul class="col-md-12 ml-5">
                      <li *ngFor="let synonym of synonyms" class="mb-3">
                        <div  class="d-inline-flex">
                          <span [innerHTML]="renderName(synonym)" class="align-self-center"></span>
                          <button type="button" class="btn btn-info ml-3" (click)="editSyn(synonym)">Edit</button>
                          <button type="button" class="btn btn-info ml-2" (click)="deleteSyn(synonym)">Delete</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="synonyms.length === 0" class="row">
                    <div class="col-md-10 ml-2">
                      No synonyms associated with this plant
                    </div>
                  </div>
                  <form  [formGroup]="synForm" method="post" accept-charset="utf-8" class="adv-search">
                    <input type="hidden" name="plantId" formControlName="plantId">
                    <div class="row mt-4">
                      <div class="col-md-3">
                        <button type="button" class="btn btn-info" (click)="addNewSyn()">Add a new synonym</button>
                      </div>
                    </div>
                    <div *ngIf="showSynForm">
                      <div class="row">
                        <div class="col-md-2">
                          Family:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="family"
                                 formControlName="family">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Family Common Name:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="familyCommonName"
                                 formControlName="familyCommonName">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Genus:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="genus"
                                 formControlName="genus" [ngClass]="{ 'is-invalid': synsubmitted && synf.genus.errors }">
                          <div *ngIf="synsubmitted && synf.genus.errors" class="invalid-feedback">
                            <div *ngIf="synf.genus.errors.required">This field is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Cross Genus:
                        </div>
                        <div class="col-md-6">
                          <select formControlName="crossGenus" class="w-100 form-control form-control-sm">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Cross Specific Epithet:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="crossSpecificEpithet"
                                 formControlName="crossSpecificEpithet">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Specific Epithet:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="specificEpithet"
                                 formControlName="specificEpithet">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Infraspecific Epithet Designator:
                        </div>
                        <div class="col-md-6">
                          <select formControlName="infraspecificEpithetDesignator" class="w-100 form-control form-control-sm">
                            <option value="">None</option>
                            <option value="var.">var.</option>
                            <option value="ssp.">ssp.</option>
                            <option value="f.">f.</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Infraspecific Epithet:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="infraspecificEpithet"
                                 formControlName="infraspecificEpithet" [ngClass]="{ 'is-invalid': synsubmitted && synf.infraspecificEpithet.errors }">
                          <div *ngIf="synsubmitted && synf.infraspecificEpithet.errors" class="invalid-feedback">
                            <div *ngIf="synf.infraspecificEpithet.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Cross Species:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="crossSpecies"
                                 formControlName="crossSpecies" [ngClass]="{ 'is-invalid': synsubmitted && synf.crossSpecies.errors }">
                          <div *ngIf="synsubmitted && synf.crossSpecies.errors" class="invalid-feedback">
                            <div *ngIf="synf.crossSpecies.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Cultivar:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="cultivar"
                                 formControlName="cultivar" [ngClass]="{ 'is-invalid': synsubmitted && synf.cultivar.errors }">
                          <div *ngIf="synsubmitted && synf.cultivar.errors" class="invalid-feedback">
                            <div *ngIf="synf.cultivar.errors.maxlength">This field is limited to 200 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Trade Name:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="tradeName"
                                 formControlName="tradeName" [ngClass]="{ 'is-invalid': synsubmitted && synf.tradeName.errors }">
                          <div *ngIf="synsubmitted && synf.tradeName.errors" class="invalid-feedback">
                            <div *ngIf="synf.tradeName.errors.maxlength">This field is limited to 55 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Trademark Name:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="trademarkName"
                                 formControlName="trademarkName" [ngClass]="{ 'is-invalid': synsubmitted && synf.trademarkName.errors }">
                          <div *ngIf="synsubmitted && synf.trademarkName.errors" class="invalid-feedback">
                            <div *ngIf="synf.trademarkName.errors.maxlength">This field is limited to 50 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          Registered Name:
                        </div>
                        <div class="col-md-6">
                          <input class="w-100 form-control form-control-sm" name="registeredName"
                                 formControlName="registeredName" [ngClass]="{ 'is-invalid': synsubmitted && synf.registeredName.errors }">
                          <div *ngIf="synsubmitted && synf.registeredName.errors" class="invalid-feedback">
                            <div *ngIf="synf.registeredName.errors.maxlength">This field is limited to 55 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-3">
                          <button class="btn btn-info ml-2" (click)="saveSyn()" [disabled]="currentPlantId === 0">
                            <span *ngIf="!editingCommon">Save Synonym</span>
                            <span *ngIf="editingCommon">Update Synonym</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </li>
              <li [ngbNavItem]="8">
                <a ngbNavLink>Images</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-4 border-left border-right text-center">
                      <p>Primary Image</p>
                      <div *ngIf="primaryImage.length > 0">
                        <div class="mt-2 mb-3">
                          <button class="btn btn-info ml-2" (click)="deletePrimary()">Delete</button>
                        </div>
                        <img class="w-50" [src]="primaryImage">
                      </div>
                    </div>
                    <div class="col-md-4 border-right text-center">
                      <p>Landscape Image</p>
                      <div *ngIf="landscapeImage.length > 0">
                        <div class="mt-2 mb-3">
                          <button class="btn btn-info ml-2" (click)="deleteLandscape()">Delete</button>
                        </div>
                        <img class="w-50" [src]="landscapeImage">
                      </div>
                    </div>
                    <div class="col-md-4 border-right text-center">
                      <p>Detail Image</p>
                      <div *ngIf="detailImage.length > 0">
                        <div class="mt-2 mb-3">
                          <button class="btn btn-info ml-2" (click)="deleteDetail()">Delete</button>
                        </div>
                        <img class="w-50" [src]="detailImage">
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="mx-auto col-md-5">
                      <h3 class="text-left">Upload a Plant Image.</h3>
                      <p class="text-left">Maximum file size = 4MB. Supported file types: JPG and PNG.</p>
                      <div>
                        <app-image-upload-custom [max]="1" class="customUploadClass d-block"
                                                 [extensions]="['image/jpg','image/png','image/jpeg']"
                                                 [url]="'/server/uploadplantpic.php'"
                                                 [buttonCaption]="'Select Plant Image'"
                                                 (uploadFinished)="onUploadFinished($event)"
                                                 [dropBoxMessage]="'Drop an image of the plant here.'"></app-image-upload-custom>
                        <p class="text-danger font-weight-bold" *ngIf="uploadFailed">{{ uploadResponse }}</p>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      <form [formGroup]="imageForm" method="post" accept-charset="utf-8" class="adv-search">
                        <div class="row mt-2">
                          <div class="col-md-2">
                            <label>Description:</label>
                          </div>
                          <div class="col-md-8">
                            <input class="w-100 form-control form-control-sm" name="description"
                                   formControlName="description">
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-2">
                            <label>Copyright:</label>
                          </div>
                          <div class="col-md-8">
                            <input class="w-100 form-control form-control-sm" name="copyright"
                                   formControlName="copyright">
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-2">
                            <label>Photographer:</label>
                          </div>
                          <div class="col-md-8">
                            <input class="w-100 form-control form-control-sm" name="photographer"
                                   formControlName="photographer">
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-2">
                            <label>Season:</label>
                          </div>
                          <div class="col-md-2">
                            <select formControlName="season" class="form-control form-control-sm">
                              <option value="">Choose</option>
                              <option value="Spring">Spring</option>
                              <option value="Summer">Summer</option>
                              <option value="Fall">Fall</option>
                              <option value="Winter">Winter</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-2">
                            <h3 class="font-weight-bold m">Categories</h3>
                          </div>
                          <div class="col-md-8">
                            <fieldset class="mb-2">
                              <div *ngFor="let category of typeData; let i=index" class="d-inline-block">
                                <label class="mr-3">
                                  <input type="checkbox" [value]="category.value" (change)="onCategoryCheckboxChange($event)" [checked]="isCategoryChecked(category.value)" [disabled]="categoryAlreadyHasAnImage(category.value)" />
                                  {{category.name}}
                                </label>
                              </div>
                              <div *ngIf="imagesubmitted && showCatError"
                                   style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
                                <div>You must select at least one category first</div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12 text-center">
                            <button class="btn btn-info ml-2" (click)="saveImage()" [disabled]="currentPlantId === 0">Save Image</button>
                            <div *ngIf="imagesubmitted && (imf.filename.errors || imf.typeArray.errors)"
                                 style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
                              <div *ngIf="imf.filename.errors.required">You must upload an image first</div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2 bg-light p-2"></div>
          </div>
        </div>
      </fieldset>
    </form>
    <div class="row" *ngIf="currentPlantId > 0">
      <div class="col-md-12 text-right">
        <button class="btn btn-danger"
                (click)="deletePlant()">Delete this plant
        </button>

      </div>
    </div>
  </div>
</div>
