<section class="top-nav">

  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark w-100">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span><span class="toggler-label">MENU</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav menu">
        <li class="nav-item" routerLinkActive="current" [routerLinkActiveOptions]="{exact: true}" data-toggle="collapse"
            data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown" routerLinkActive="current">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            About
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" data-toggle="collapse"
               data-target=".navbar-collapse.show">
            <a class="dropdown-item" routerLink="/about">About GPP</a>
            <a class="dropdown-item" routerLink="/about/criteria">Criteria</a>
            <a class="dropdown-item" routerLink="/about/committee">Selection Committee</a>
            <a class="dropdown-item" routerLink="/about/groups">Advisors</a>
            <a class="dropdown-item" routerLink="/about/staff">Staff</a>
          </div>
        </li>
        <li class="nav-item dropdown" routerLinkActive="current">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Plant Lists
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2" data-toggle="collapse"
               data-target=".navbar-collapse.show">
            <a class="dropdown-item" routerLink="/searchnav">Helpful Lists</a>
            <a class="dropdown-item" routerLink="/search/advanced">Plant Search</a>
            <a class="dropdown-item" routerLink="/search/plantNotListed">Why Can't I Find My Plant?</a>
          </div>
        </li>
        <li class="nav-item dropdown" routerLinkActive="current">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink3" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Resources
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink3" data-toggle="collapse"
               data-target=".navbar-collapse.show">
            <a class="dropdown-item" routerLink="/resources/nurserylist">Nursery Directory</a>
            <a class="dropdown-item" routerLink="/resources/links">Helpful Links</a>
            <a class="dropdown-item" routerLink="/resources/glossary">Glossary</a>
          </div>
        </li>
        <li class="nav-item dropdown" routerLinkActive="current">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink4" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Media
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink4" data-toggle="collapse"
               data-target=".navbar-collapse.show">
            <a class="dropdown-item" routerLink="/press">Press</a>
            <a class="dropdown-item" routerLink="/poster">Posters</a>
            <a class="dropdown-item" routerLink="/press/terms">Image &amp; Logo Use</a>
          </div>
        </li>
        <li class="nav-item" routerLinkActive="current" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/contact">Contact</a>
        </li>
        <li class="nav-item" routerLinkActive="current" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" routerLink="/donate">Donate</a>
        </li>
        <li *ngIf="authService.isLoggedIn()" class="nav-item dropdown" routerLinkActive="current">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink5" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Admin
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink5" data-toggle="collapse"
               data-target=".navbar-collapse.show">
            <a class="dropdown-item" routerLink="/plant/add">Add Plant</a>
            <a class="dropdown-item" routerLink="/nursery/add">Add Nursery</a>
            <a class="dropdown-item" routerLink="/logout" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</section>
<div *ngIf="showLogo" id="banner-bg">
  <div id="logo">
    <a [routerLink]="" (click)="gotoHome()">
      <img alt="Great Plant Picks logo" [src]="logoType">
    </a>
  </div>
  <a *ngIf="showFindPlant" [routerLink]="" (click)="gotoSearch()" class="findPlant">
    <div id="plant-link-all" class="blockhead">
      <span class="blocktext">FIND YOUR PLANT</span>
    </div>
  </a>
</div>
