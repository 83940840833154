import { Component, NgZone, OnInit } from '@angular/core';
import { PlantData, PlantThumbnail } from '../models/plant.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantService } from '../services/plant.service';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss']
})
export class PrintViewComponent implements OnInit {

  currentPlant: PlantData;
  currentPlantId: number;
  imageThumbnails: PlantThumbnail[];
  imageSource: string;
  imageThumbSource: string;
  comboArray: any[];
  flowerColorString: string;
  wildlifeString: string;
  pestString: string;
  maxCategories: boolean;
  botanicalName: string;
  showCombos: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private plantService: PlantService)
  {
    this.currentPlant = { };
    this.comboArray = [];
    this.flowerColorString = '';
    this.wildlifeString = '';
    this.pestString = '';
    this.maxCategories = false;
    this.showCombos = false;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.ngZone.run(() => {
        this.currentPlantId =  +params.get('plantid'); // (+) converts string 'id' to a number
        this.plantService.getPlantById(this.currentPlantId)
          .subscribe((res) => {
            this.ngZone.run(() => {
              if (res.hasOwnProperty('plant')) {
                this.currentPlant = res.plant;
                if (this.currentPlant.hasOwnProperty('plant_combinations')) {
                  if (this.currentPlant.plant_combinations !== null) {
                    this.getCombos(this.currentPlant.plant_combinations);
                  }
                }
                this.botanicalName = this.plantService.render_botanical_name(null, null, this.currentPlant);
              }
              if (res.hasOwnProperty('imageThumbnails')) {
                this.imageThumbnails = res.imageThumbnails;
                if (this.imageThumbnails.length > 0) {
                  this.imageThumbSource = this.imageThumbnails[0].sourceFinder;
                }
              }
              if (res.hasOwnProperty('imageSource')) {
                this.imageSource = res.imageSource;
              }
              const flowerColorList = this.currentPlant.plantFlowerColor;
              const colorArray = [];
              flowerColorList.forEach((aColor) => {
                colorArray.push(aColor.name);
              });
              this.flowerColorString = colorArray.join(', ');
              const wildlifeList = this.currentPlant.plantWildlife;
              const wildlifeArray = [];
              wildlifeList.forEach((aWildlife) => {
                wildlifeArray.push(aWildlife.name);
              });
              this.wildlifeString = wildlifeArray.join(', ');

              const pestList = this.currentPlant.plantPestResistance;
              const pestArray = [];
              pestList.forEach((aPest) => {
                pestArray.push(aPest.name);
              });
              this.pestString = pestArray.join(', ');
              const plantCategory = this.currentPlant.plant_type;
              this.maxCategories = (['tree', 'shrub', 'conifer', 'bamboo', 'vine'].indexOf(plantCategory) > -1);
              if ((this.currentPlant.plant_combinations != null) &&
                ((this.currentPlant.plant_combinations.length > 0) ||
                  (this.currentPlant.color_contrast.length > 0) ||
                  (this.currentPlant.color_partners.length > 0))) {
                this.showCombos = true;
              }
            });
          });
      });
    });
  }
  getCombos(linksString) {
    // split the string into multiple strings (comma separated)
    const linkArray = linksString.split(',');
    if (linkArray.length > 0) {
      linkArray.forEach((aLink) => {
        const regexp = /(\d+)">([^<]+)/gm;
        let m;

        while ((m = regexp.exec(aLink)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regexp.lastIndex) {
            regexp.lastIndex++;
          }

          const newLink = {plantId: null, name: null};
          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            switch (groupIndex) {
              case 1:
                newLink.plantId = match;
                break;
              case 2:
                newLink.name = match;
                break;
            }
          });
          this.comboArray.push(newLink);
        }
      });
    }
  }

  backToDetails() {
    this.router.navigateByUrl('/search/plant-details/' + this.currentPlantId);
  }
  printWindow() {
    window.print();
  }
  gotoPlantDetails(plantid) {
    this.router.navigateByUrl('/search/plant-details/' + plantid);
  }
  feet_to_feet_inches(infeet) {
    if (infeet == null) {
      return '0 ft. 0 in.';
    } else {
      // check if infeet starts with a dot (such as .5 rather than 0.5)
      if (infeet.charAt(0) === '.') {
        infeet = '0' + infeet;
      }
      const feet = parseFloat(infeet);
      const inches = feet * 12;
      const feetInInches = Math.floor(inches / 12);
      const feetRound = Math.floor(feet);
      const newInches = Math.round(inches - (12 * feetInInches));
      return feetRound + ' ft. ' + newInches + ' in.';
    }
  }
  feet_to_meters(feet) {
    const meters = feet * 0.3048;
    return meters.toFixed(1);  // limit to one decimal place for plant list results page
  }

  feet_to_meters2(infeet) {
    if (infeet == null) {
      return 0.00;
    } else {
      // check if infeet starts with a dot (such as .5 rather than 0.5)
      if (infeet.charAt(0) === '.') {
        infeet = '0' + infeet;
      }
      const feet = parseFloat(infeet);
      const meters = feet * 0.3048;
      return meters.toFixed(2);
    }
  }

}
