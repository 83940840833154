import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    if (navigator.cookieEnabled) {
      const idToken = localStorage.getItem('id_token');

      if (idToken) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + idToken)
        });

        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
