import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Plant } from '../models/plant.model';
import { PlantService } from '../services/plant.service';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';
import { until } from 'selenium-webdriver';
import titleContains = until.titleContains;
import { reduce } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  mySubscription: any;
  dtOptions: DataTables.Settings = {};
  private plantList: Plant[];
  totalFound: number;
  noData: boolean;
  that: SearchResultsComponent;
  sco: string;
  ado: string;
  emailHref: string;
  searchCategory: string;
  pathString: string;

  @ViewChild('searchButton', { static: true }) searchButtonElement: ElementRef;
  basicSearchTermsInput = new FormControl();

  options = [];
  basicSearchTerms = '';
  keyword = 'common_name';
  placeholder = 'Enter botanical or common name';


  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private service: ApiService,
              private authService: AuthService,
              private plantService: PlantService,
              private changeDetector: ChangeDetectorRef) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.that = this;
    this.plantList = [];
    this.totalFound = 0;
    this.noData = false;

    this.sco = '';
    this.ado = '';
    this.searchCategory = '';
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.sco = params.sco || '';
        this.ado = params.ado || '';
        this.searchCategory = params.scat || '';
        if (this.sco !== '') {
          this.plantService.searchCommonOptions = this.sco;
          this.plantService.advancedSearchOptions = '';
        } else if (this.ado !== '') {
          const adoDecoded = decodeURIComponent(this.ado);
          this.plantService.advancedSearchOptions = adoDecoded;
          this.plantService.searchCommonOptions = '';
        }
        if (this.searchCategory !== '') {
          this.plantService.searchCategory = decodeURI(this.searchCategory);
        } else {
          this.searchCategory = this.plantService.searchCategory;
        }
      });

    const emailPath = document.location.href + encodeURIComponent('?sco=' + this.plantService.searchCommonOptions +
      // tslint:disable-next-line:max-line-length
      '&ado=' + encodeURIComponent(this.plantService.advancedSearchOptions) + '&scat=' + encodeURIComponent(this.plantService.searchCategory));
    this.pathString = document.location.href + '?sco=' + encodeURIComponent(this.plantService.searchCommonOptions) +
      '&ado=' + encodeURIComponent(this.plantService.advancedSearchOptions) + '&scat=' + encodeURIComponent(this.plantService.searchCategory);
    this.emailHref = 'mailto:?subject=Great Plant Picks Plant List&body=' + emailPath;
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'rtip',
      pageLength: 500,
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.ngZone.run(() => {
          this.plantService.getPlantsTable(
            dataTablesParameters, this.authService.isLoggedIn())
            .subscribe(resp => {
              if (resp.hasOwnProperty('data')) {
                this.plantList = resp.data;
                this.totalFound = resp.recordsFiltered;
                if (this.plantList.length > 0) {
                  this.noData = false;
                } else {
                  this.noData = true;
                }
              } else {
                resp.data = [];
              }

              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data
              });
//              console.log(resp.data);
              this.changeDetector.markForCheck();
            });
        });
      },
      order: [[0, 'asc']],
      columns: [{
        title: 'Plant Name',
        data: 'botanical_name',
        render: this.plantService.render_botanical_name
      }, {
        title: 'Common Name(s)',
        data: 'commonName',
        render(data, type, full, meta) {
          return '<div style="white-space: normal;width:400px;">' + data + '</div>';
        }
      }, {
        title: 'Mature Height (ft.)',
        data: 'feet'
      }, {
        title: '(m.)',
        data: 'meters'
      }, {
        title: 'Type',
        data: 'type'
      }, {
        title: 'id',
        name: 'id',
        data: 'id',
        visible: false,
      }, {
        title: 'CrossGenus',
        name: 'cross_genus',
        data: 'cross_genus',
        visible: false
      }, {
        title: 'Genus',
        name: 'genus',
        data: 'genus',
        visible: false
      }, {
        title: 'Species',
        name: 'species',
        data: 'specific_epithet',
        visible: false
      }, {
        title: 'cross_species',
        name: 'cross_species',
        data: 'cross_species',
        visible: false
      }, {
        title: 'Cultivar',
        name: 'cultivar',
        data: 'cultivar',
        visible: false
      }, {
        title: 'cross_specific_epithet',
        name: 'cross_specific_epithet',
        data: 'cross_specific_epithet',
        visible: false
      }, {
        title: 'specific_epithet',
        name: 'specific_epithet',
        data: 'specific_epithet',
        visible: false
      }, {
        title: 'infraspecific_epithet',
        name: 'infraspecific_epithet',
        data: 'infraspecific_epithet',
        visible: false
      }, {
        title: 'infraspecific_epithet_designator',
        name: 'infraspecific_epithet_designator',
        data: 'infraspecific_epithet_designator',
        visible: false
      }, {
        title: 'trade_name',
        name: 'trade_name',
        data: 'trade_name',
        visible: false
      }, {
        title: 'plant_breeders_rights',
        name: 'plant_breeders_rights',
        data: 'plant_breeders_rights',
        visible: false
      }, {
        title: 'trademark_name',
        name: 'trademark_name',
        data: 'trademark_name',
        visible: false
      }, {
        title: 'registered_name',
        name: 'registered_name',
        data: 'registered_name',
        visible: false
      }, {
        title: 'plantname_group',
        name: 'plantname_group',
        data: 'plantname_group',
        visible: false
      }, {
        title: 'form',
        name: 'form',
        data: 'form',
        visible: false
      }, {
        title: 'Published',
        name: 'publish',
        data: 'publish',
        visible: this.authService.isLoggedIn(),
      }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        // const ordernumeric = data['orderDate'];
        // const orderDate = moment(ordernumeric);
        // $('td:eq(1)', row).text(orderDate.format('LLL'));
        return row;
      }
    };

  }

  someClickHandler(info: any): void {
    // const orderId = info.id;
    // this.router.navigateByUrl('order-detail/' + orderId);
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
    this.gotoPlantDetails(info.id);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  basicSearch() {
    this.plantService.searchCommonOptions = encodeURI(this.basicSearchTerms);
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }

  basicSearchMobile() {
    this.plantService.searchCommonOptions = encodeURI(this.basicSearchTermsInput.value);
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }

  gotoPlantDetails(plantid) {
    this.router.navigateByUrl('/search/plant-details/' + plantid);
  }

  gotoAdvSearch() {
    this.router.navigateByUrl('/search/advanced');
  }

  gotoSearch() {
    this.router.navigateByUrl('/searchnav');
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.basicSearchTerms = val;
    this.plantService.searchCommonOptions = this.basicSearchTerms;
    if (val.length > 0) {
      this.plantService.getPlantAutoComplete(this.authService.isLoggedIn())
        .subscribe((res) => {
          this.options = res.plants;
          this.changeDetector.markForCheck();
        });
    } else {
      this.options = [];
      this.changeDetector.markForCheck();
    }
  }
  selectEvent(item) {
    // do something with selected item
    setTimeout(() => {
      this.searchButtonElement.nativeElement.click();
    }, 500);
    this.options = [];
  }

  openInNewTab(item) {
  }

  onFocused(e) {
    // do something when input is focused
  }

  copyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.pathString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
