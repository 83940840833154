<div class="home-image" style="background-image: url(../../assets/plants/clematis-montana-tetrarose.jpg)">	
	<h1>Contact Us</h1>
</div> 
<div class="wrapper">
    <h1>Contact Great Plant Picks</h1>
	 <div class="content">
    <div class="text-wrap">
    <p><strong>Great Plant Picks originates at the Elisabeth C. Miller Botanical Garden in Seattle, Washington.</strong></p>
		<p><strong>By Mail:</strong></p>
        <p>Elisabeth C. Miller Botanical Garden<br>
        Great Plant Picks<br>
        PO Box 77377<br>
        Seattle, WA 98177</p>

        <p><strong>By Phone:</strong> (206) 362-8612</p>
        <p><strong>By Fax:</strong> (206) 362-4136</p>
        <p><strong>By Email:</strong> <a href="mailto:rickp@millergarden.org"> rickp@millergarden.org</a></p>
 
      <p><strong>For other gardening questions:</strong></p>
      <p>Visit the <a href="http://depts.washington.edu/hortlib/collections/pal.shtml">Plant Answer Line</a>, a wonderful resource provided by the Elisabeth C. Miller Library at the Center for Urban Horticulture, or visit our <a [routerLink]="" (click)="gotoResources()">Resources</a> page for a list of helpful links and information sources.</p>
    		</div>
		 </div>
   </div>