import {
  Plant,
  PlantAutoCompleteResponse,
  PlantData,
  PlantDataResponse,
  PlantTable,
  RawPlantDataResponse
} from '../models/plant.model';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PlantService {
  private plantList: BehaviorSubject<Plant[]>;
  private plantTable: Observable<PlantTable>;

  private _searchCommonOptions: string;
  private _advancedSearchOptions: string;
  private _searchCategory: string;
  private botanicalName: string;
  private cookieVal: string;

  constructor(private apiService: ApiService,
              private ngZone: NgZone) {
    this.plantList = new BehaviorSubject<Plant[]>([]);
    this._searchCommonOptions = '';
    this._advancedSearchOptions = '';
    this._searchCategory = '';
    this.cookieVal = '';
    const value = '; ' + document.cookie;
    const parts = value.split('; search=');
    if (parts.length === 2) {
      // found a search in a cookie, try using it
      this.cookieVal = parts.pop().split(';').shift();
      console.log('AppComponent constructor found a cookie with a search string');
      const searchParts = this.cookieVal.split(':');
      if (searchParts.length === 3) {
        this._searchCommonOptions = decodeURI(searchParts[0]);
        this._advancedSearchOptions = decodeURI(searchParts[1]);
        this._searchCategory = decodeURI(searchParts[2]);
      }
    }
  }

  public getPlantList(): BehaviorSubject<Plant[]> {
    return this.plantList;
  }

  public getPlantById(plantId: number): Observable<PlantDataResponse> {
    return this.apiService.get('/plants/' + plantId);
  }

  public getRawPlantById(plantId: number): Observable<RawPlantDataResponse> {
    return this.apiService.get('/plants/' + plantId + '/raw');
  }


  public getPlantAutoComplete(isLoggedIn: boolean): Observable<PlantAutoCompleteResponse> {
    const plantOptionString = isLoggedIn ? '&all=1' : '&unpublished=0' ;
    return this.apiService.get('/plants/autocomplete?search=' + this._searchCommonOptions + plantOptionString);
  }

  public getPlantsTable(body: any, isLoggedIn: boolean): Observable<PlantTable> {
    let plantOptionString = isLoggedIn ? '?all=1' : '?unpublished=0' ;
    if (this._searchCommonOptions.length > 0) {
      plantOptionString = plantOptionString + '&common=' + this._searchCommonOptions;
    } else if (this._advancedSearchOptions.length > 0) {
      plantOptionString = plantOptionString + this.advancedSearchOptions;
    }
    return this.apiService.post('/plantstable' + plantOptionString, body);
  }

  addNewPlant(plantData: HttpParams): Observable<any> {
    return this.apiService.postForm('/plants', plantData);
  }
  updatePlant(plantId: number, plantData: HttpParams): Observable<any> {
    return this.apiService.putForm('/plants/' + plantId, plantData);
  }
  deletePlant(plantId: number): Observable<any> {
    return this.apiService.delete('/plants/' + plantId);
  }

  addNewCommonName(commonData: HttpParams): Observable<any> {
    return this.apiService.postForm('/plants/commonNames', commonData);
  }
  updateCommonName(commonData: HttpParams, cnameId: number): Observable<any> {
    return this.apiService.putForm('/plants/commonNames/' + cnameId, commonData);
  }
  deleteCommonName(cnameId: number): Observable<any> {
    return this.apiService.delete('/plants/commonNames/' + cnameId);
  }
  addNewSynonym(synData: HttpParams): Observable<any> {
    return this.apiService.postForm('/plants/synonyms', synData);
  }
  updateSynonym(synData: HttpParams, synonymId: number): Observable<any> {
    return this.apiService.putForm('/plants/synonyms/' + synonymId, synData);
  }
  deleteSynonym(synonymId: number): Observable<any> {
    return this.apiService.delete('/plants/synonyms/' + synonymId);
  }
  deleteImage(imageId: number): Observable<any> {
    return this.apiService.delete('/plants/images/' + imageId);
  }

  saveImage(plantId: number, imageData: HttpParams): Observable<any> {
    return this.apiService.postForm('/plants/' + plantId + '/images', imageData);
  }

  public render_botanical_name(data, type, row) {
    function renderGenus(record: any) {
      if ((record.genus != null) && (record.genus !== '')) {
        const genusCross = record.cross_genus ? '&times; ' : '';
        const genus = record.genus.charAt(0).toUpperCase() + record.genus.slice(1);
        return '<span class="crossgenus">' + genusCross + '</span> <span class="genus">' + genus + ' </span>';
      } else {
        return '';
      }
    }

    function renderCSE(record: any) {
      if ((record.cross_specific_epithet != null) && (record.cross_specific_epithet === 'Yes')) {
        return '&times; ';
      } else {
        return '';
      }
    }

    function renderSE(record: any) {
      if ((record.specific_epithet != null) && (record.specific_epithet !== '')) {
        const cross = record.cross_species ? '&times; $cross' : '';
        const species = record.specific_epithet.toLowerCase();
        return '<span class="species">' + species + ' </span> ' + cross;
      } else {
        return '';
      }
    }

    function renderISE(record: any) {
      if ((record.infraspecific_epithet != null) && (record.infraspecific_epithet !== '') &&
        (record.infraspecific_epithet_designator != null)) {
        const spp = record.infraspecific_epithet.toLowerCase();
        const designator = record.infraspecific_epithet_designator.toLowerCase();
        return '<span class="subspecies">' + designator + ' </span> <span class="designate">' + spp + ' </span>';
      } else {
        return '';
      }
    }
    function renderCult(record: any) {
      if ((record.cultivar != null) && (record.cultivar !== '')) {
        const cultivar = '&#8216;' + record.cultivar + '&#8217;';
        return '<span class="cultivar">' + cultivar + ' </span>';
      } else {
        return '';
      }
    }
    function renderTradeName(record: any) {
      if ((record.trade_name != null) && (record.trade_name !== '')) {
        return '<span class="trade-name">' + record.trade_name + ' </span>';
      } else {
        return '';
      }
    }
    function renderRights(record: any) {
      if ((record.plant_breeders_rights != null) && (record.plant_breeders_rights !== '')) {
        return '<span class="breeders-rights">PBR</span>';
      } else {
        return '';
      }
    }
    function renderTMName(record: any) {
      if ((record.trademark_name != null) && (record.trademark_name !== '')) {
        const trademarkname = record.trademark_name.toUpperCase();
        return '<span class="trademark-name">' + trademarkname + ' &#8482; </span>';
      } else {
        return '';
      }
    }
    function renderRegName(record: any) {
      if ((record.registered_name != null) && (record.registered_name !== '')) {
        const registeredname = record.registered_name.toUpperCase();
        return '<span class="registered-name">' + registeredname + '</span><span class="super">&#174; </span>';
      } else {
        return '';
      }
    }
    function renderPlantNameGroup(record: any) {
      if ((record.plantname_group != null) && (record.plantname_group !== '')) {
        const pname = record.plantname_group + ' Group ';
        return '<span class="plantname-group" style="text-transform:capitalize;">' + pname + '</span>';
      } else {
        return '';
      }
    }
    function renderForm(record: any) {
      if ((record.form != null) && (record.form !== '')) {
        const fname = record.form;
        return '<span class"plantname-group">' + fname + ' </span>';
      } else {
        return '';
      }
    }
    let botanicalName = '';
    botanicalName += renderGenus(row);
    botanicalName += renderCSE(row);
    botanicalName += renderSE(row);
    botanicalName += renderISE(row);
    botanicalName += renderCult(row);
    botanicalName += renderTradeName(row);
    botanicalName += renderRights(row);
    botanicalName += renderTMName(row);
    botanicalName += renderRegName(row);
    botanicalName += renderPlantNameGroup(row);
    botanicalName += renderForm(row);

    return '<div style="white-space: normal;width:400px;">' + botanicalName + '</div>';
  }

  get searchCommonOptions(): string {
    return this._searchCommonOptions;
  }

  set searchCommonOptions(value: string) {
    this._searchCommonOptions = value;
    this.setCookieVal();
  }

  get advancedSearchOptions(): string {
    return this._advancedSearchOptions;
  }

  set advancedSearchOptions(value: string) {
    this._advancedSearchOptions = value;
    this.setCookieVal();
  }

  get searchCategory(): string {
    return this._searchCategory;
  }

  set searchCategory(value: string) {
    this._searchCategory = value;
    this.setCookieVal();
  }

  public setCookieVal() {
    const date = new Date();
    const knumDays = 30;
    date.setTime(date.getTime() + (knumDays * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = 'search=' + encodeURI(this._searchCommonOptions) +
      ':' + encodeURI(this._advancedSearchOptions) +
      ':' + encodeURI(this._searchCategory) + expires + '; path=/';
  }

}
