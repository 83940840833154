<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ confirmTitle }}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p><strong>Are you sure you want to {{ confirmMessage }}?</strong></p>
  <p>  <span class="text-danger">This operation can not be undone.</span></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="decline()">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="accept()">Ok</button>
</div>
