<div class="home-image" style="background-image: url(../../assets/plants/Corylopsis-pauciflora-RAS.jpg)">	
	<h1>GPP Selection Committee</h1>
</div> 
<div class="wrapper">
     <div class="breadcrumbs">
<ul>
        <li><a [routerLink]="" (click)="gotoAbout()">About GPP</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCriteria()">Selection Criteria</a></li>
        <li><a [routerLink]="" id="current">Selection Committees</a></li>
        <li><a [routerLink]="" (click)="gotoAboutGroups()">Advisors</a></li>
        <li><a [routerLink]="" (click)="gotoAboutStaff()">GPP Staff</a></li>
</ul>
      </div><!-- end breadcrumbs -->
      
<div class="content">
		 <div class="text-wrap">
     <h1>Great Plant Picks Selection Committee Members</h1>
      <p>The GPP selection committee is made up of horticulturists from Oregon, Washington, and British Columbia, west of the Cascade Mountains. Members represent wholesale and retail nurseries, garden designers, arboreta, and botanical gardens. The committee is divided into three subcommittees: Shrubs and Vines, Trees and Conifers, and Perennials and Bulbs.  Each subcommittee has a chair who is responsible for steering their subcommittee toward the final plant selections and ensuring that sufficient information on each selection has been recorded.  Selections are made by a majority vote after a series of roundtable discussions.</p>
      <h3>Field Evaluations</h3>
      <p>In addition to committee meetings, selection committee members visit special collections to survey particular genera, especially those genera containing numerous species or cultivars. Participating members fill out evaluation sheets, rating the merits of each plant.  The compiled results of these evaluations are provided to selection committee members. This aspect of the program is of great value to home gardeners and nursery and educational professionals alike.</p>
      <img class="aligncenter size-full" alt="" title="Great Plant Picks Selection Committee" src="assets/gpp-selection-committee-2013.jpg">
      <p class="img-caption">Great Plant Picks Selection Committee, photo by Richie Steffen</p>
      <h3>Perennials and Bulbs</h3>
      <ul>
        <li><em>Greg Graves</em>, Old Goat Farm, Washington</li>
        <li><em>Lucy Hardiman</em>, Perennial Partners, Oregon</li>
        <li><em>Maurice Horn</em>, Joy Creek Nursery, Oregon</li>
        <li><em>Alex LaVilla</em>, Swanson&#8217;s Nursery, Seattle, Washington</li>
        <li><em>Gary Lewis</em>, Phoenix Perennials and Specialty Plants, British Columbia</li>
        <li><em>Gwen Odermatt</em>, Petals and Butterflies, British Columbia</li>
        <li><em>Wendy Welch</em>, Wendy Welch Garden Design, Washington</li>
      </ul>

      <h3>Shrubs and Vines</h3>
      <ul>
        <li><em>Paul Bonine</em>, Xera Plants, Oregon</li>
		  <li><em>Rachel Burlington</em>, Portland Rose Garden, Oregon</li>
        <li><em>Steven Folkerts</em>, Linnea Nurseries, British Columbia</li>
		  <li><em>Leslie Gover</em>, Arborist, City of Happy Valley, Oregon</li>
        <li><em>Polly Hankin</em>, Landscape Architect, Washington</li>
		<li><em>Bryon Jones</em>, Point Defiance Zoo &amp; Aquarium, Washington</li>
        <li><em>Ray Larson</em>, University of Washington Botanic Gardens, Washington</li>
        <li><em>Angelina Seah</em>, Bowen Plants, British Columbia</li>
        <li><em>Richie Steffen</em>, E.C. Miller Botanical Garden, Washington</li>
      </ul>

      <h3>Trees and Conifers</h3>
      <ul>
        <li><em>Ian Connor</em>, Connor Bamboo, Oregon</li>
        <li><em>Randall Hitchin</em>, Washington Park Arboretum, Washington</li>
        <li><em>Douglas Justice</em>, UBC Botanical Garden, British Columbia</li>
        <li><em>Claude LeDoux</em>, New Westminster Parks, British Columbia</li>
        <li><em>Guy Meacham</em>, J. Frank Schmidt &amp; Son Co., Oregon</li>
        <li><em>Martin Nicholson</em>, Hoyt Arboretum, Oregon</li>
        <li><em>Fred Nilsen</em>, Portland Parks &amp; Recreation, retired, Oregon</li>
        <li><em>Lou Stubecki</em>, City of Seattle arborist, Washington</li>
      </ul>
      <h3>Members at Large</h3>
      <ul>
        <li><em>Roger Gossler</em>, Gossler Farms Nursery, Oregon</li>
      </ul>
    </div>
  </div>
	</div><!-- .wrapper -->
