<div class="wrapper nobanner">
  <div class="text-wrap"><!-- display for PUBLIC plant list and search function -->

    <button id="printview" class="search-button" (click)="gotoPrintView()">Printer-Friendly View</button>
    <button *ngIf="authService.isLoggedIn()" id="editplant" class="search-button float-right mr-3"
            (click)="editPlant()">Edit This Plant</button>

    <div class="titles">
      <h5 [innerHtml]="botanicalName"></h5>
      <h5 *ngIf="errorString.length > 0">{{ errorString }}</h5>

      <p class="common-name" *ngFor="let commonName of currentPlant.commonNames">{{ commonName.common_name }}</p>
    </div>
    <div id="imageview">

      <img *ngIf="imageSource !== null" [src]="imageSource" alt="" id="main-img" />
      <ul>
        <li *ngFor="let aThumb of imageThumbnails">
          <div class='thumbnail' (click)="substituteImage(aThumb)">
            <img [src]="aThumb.imageThumbnail"/>
            <p [innerHTML]="aThumb.sourceFinder"></p>
          </div>
        </li>
      </ul>
    </div><!-- end image view -->

    <div class="plantinfo">

      <p *ngIf="synString.length > 0">
        <em>Synonyms:</em>
        <span [innerHtml]="synString"></span>
      </p>
      <h3 *ngIf="errorString.length === 0">Outstanding Qualities</h3>
      <p>{{ currentPlant.qualities }}</p>
      <div *ngIf="errorString.length === 0" class="quickfacts">
        <h3>Quick Facts</h3>
        <p><strong>Plant Type:</strong>  {{ currentPlant.plant_type }}</p>
        <p *ngIf="currentPlant.growth_habit != null"><strong>Growth Habit:</strong> {{ currentPlant.growth_habit }} </p>
        <p><strong><a (click)="gotoFoliage()" [routerLink]="">Foliage Type:</a></strong> {{ currentPlant.foliage_type }} </p>
        <p><strong><a (click)="gotoHeight()" [routerLink]="">Plant Height (10-year):</a></strong> {{ feet_to_feet_inches(currentPlant.plant_height_at_10) }} ({{ feet_to_meters2(currentPlant.plant_height_at_10) }} meters)</p>
        <p><strong><a (click)="gotoWidth()" [routerLink]="">Plant Width/Spread (10-year):</a></strong> {{ feet_to_feet_inches(currentPlant.plant_width_at_10) }} ({{ feet_to_meters2(currentPlant.plant_width_at_10) }} meters)</p>

        <p *ngIf="maxCategories"><strong><a (click)="gotoHeightMature()" [routerLink]="">Plant Height-Mature:</a></strong> {{ feet_to_feet_inches(currentPlant.plant_height_max)}} ({{ feet_to_meters2(currentPlant.plant_height_max) }} meters)</p>
        <p *ngIf="maxCategories"><strong><a (click)="gotoWidthMature()" [routerLink]="">Plant Width-Mature:</a></strong> {{ feet_to_feet_inches(currentPlant.plant_width_max)}} ({{ feet_to_meters2(currentPlant.plant_width_max) }} meters)</p>
        <p><strong><a (click)="gotoHardiness()" [routerLink]="">Hardiness:</a></strong> USDA Zones {{ currentPlant.zone_low }} to {{currentPlant.zone_high}}</p>
        <p *ngIf="flowerColorString.length > 0"><strong>Flower Color:</strong> {{ flowerColorString }} </p>
        <p *ngIf="currentPlant.flower_time != null && currentPlant.flower_time.length > 0"><strong>Flowering Time:</strong> {{ currentPlant.flower_time }}</p>
        <p><strong><a (click)="gotoLight()" [routerLink]="">Sun/Light Exposure:</a></strong> {{ currentPlant.sun_exposure }}</p>
        <p><strong><a (click)="gotoWater()" [routerLink]="">Water Requirements:</a></strong> {{ currentPlant.water_requirements }}</p>
        <p *ngIf="currentPlant.seasonal_interest != null && currentPlant.seasonal_interest.length > 0"><strong>Seasonal Interest:</strong> {{ currentPlant.seasonal_interest }}</p>
        <p *ngIf="wildlifeString.length > 0"><strong>Wildlife Associations:</strong> {{ wildlifeString }}</p>
        <p *ngIf="pestString.length > 0"><strong>Resistant to:</strong> {{ pestString }}</p>
      </div>
      <div *ngIf="showCombos">
        <div class="quickfacts">
          <h3>Colors &amp; Combos</h3>
          <p><strong>Great Plant Combinations:</strong></p>
          <ul>
            <li *ngFor="let aLink of comboArray;">
              <a (click)="gotoPlantDetails(aLink.plantId)" [routerLink]="">{{ aLink.name }}</a>
            </li>
          </ul>
          <p><strong>Great Color Contrasts:</strong> {{ currentPlant.color_contrast}}</p>
          <p><strong>Great Color Partners:</strong> {{ currentPlant.color_partners}}</p>
        </div>
      </div>

      <h3 *ngIf="errorString.length === 0 && currentPlant.culture_notes != null && currentPlant.culture_notes.length > 0">Culture Notes</h3>
      <p>{{ currentPlant.culture_notes }}</p>
      <h3 *ngIf="errorString.length === 0 && currentPlant.geek_notes != null && currentPlant.geek_notes.length > 0">Geek Notes</h3>
      <p>{{ currentPlant.geek_notes }}</p>
    </div><!-- end plantinfo -->

  </div><!-- end content -->

</div><!-- end wrapper -->


