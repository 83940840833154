import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Nursery, NurseryDataResponse, NurseryResponse } from '../models/nursery_model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class NurseryService {

  constructor(private apiService: ApiService,
              private ngZone: NgZone) {
  }

  getNurseryByState(state: string, getAll: boolean): Observable<NurseryResponse> {
    const allString = 'all=' + (getAll ? 'true' : 'false');
    if (state !== '') {
      return this.apiService.get('/nurseries?state=' + state + '&' + allString);
    } else {
      return this.apiService.get('/nurseries?' + allString);
    }
  }

  getNurseryById(id: number): Observable<NurseryDataResponse> {
    return this.apiService.get('/nurseries/' + id);
  }

  addNewNursery(nurseryData: HttpParams): Observable<any> {
    return this.apiService.postForm('/nurseries', nurseryData);
  }
  updateNursery(nurseryId: number, nurseryData: HttpParams): Observable<any> {
    return this.apiService.putForm('/nurseries/' + nurseryId, nurseryData);
  }
  deleteNursery(nurseryId: number): Observable<any> {
    return this.apiService.delete('/nurseries/' + nurseryId);
  }
}
