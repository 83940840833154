import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { PlantDetailsComponent } from './plant-details/plant-details.component';
import { ResourcesComponent } from './resources/resources.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { PressComponent } from './press/press.component';
import { ContactComponent } from './contact/contact.component';
import { DonateComponent } from './donate/donate.component';
import { NurseryListComponent } from './nursery-list/nursery-list.component';
import { LinksComponent } from './links/links.component';
import { DataTablesModule } from 'angular-datatables';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './services/rollbar.service';
import { PlantService } from './services/plant.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CriteriaComponent } from './about/criteria/criteria.component';
import { CommitteeComponent } from './about/committee/committee.component';
import { GroupsComponent } from './about/groups/groups.component';
import { StaffComponent } from './about/staff/staff.component';
import { FilterPipe } from './services/filter.pipe';
import { PlantNotListedComponent } from './plant-not-listed/plant-not-listed.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { NurseryService } from './services/nursery.service';
import { GlossaryComponent } from './glossary/glossary.component';
import { TermsComponent } from './terms/terms.component';
import { PosterComponent } from './poster/poster.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SearchnavComponent } from './searchnav/searchnav.component';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events-service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './services/Auth.interceptor';
import { AuthService } from './services/auth.service';
import { AddNurseryComponent } from './add-nursery/add-nursery.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AddPlantComponent } from './add-plant/add-plant.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageService } from './image-upload/image.service';
import { FileDropDirective } from './services/file-drop.directive';
import { NoCacheHeadersInterceptor } from './services/nocache-headers.interceptor';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    AboutComponent,
    SearchComponent,
    SearchResultsComponent,
    PlantDetailsComponent,
    ResourcesComponent,
    AdvancedSearchComponent,
    PressComponent,
    ContactComponent,
    DonateComponent,
    NurseryListComponent,
    LinksComponent,
    CriteriaComponent,
    CommitteeComponent,
    GroupsComponent,
    StaffComponent,
    FilterPipe,
    PlantNotListedComponent,
    PrintViewComponent,
    GlossaryComponent,
    TermsComponent,
    PosterComponent,
    ThankyouComponent,
    SearchnavComponent,
    PageNotFoundComponent,
    LoginComponent,
    AddNurseryComponent,
    InfoModalComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    AddPlantComponent,
    ImageUploadComponent,
    FileDropDirective
  ],
  entryComponents: [LoginComponent, InfoModalComponent, ErrorModalComponent, ConfirmModalComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    NgbModule
  ],
  providers: [ImageService, ApiService, PlantService, NurseryService, GoogleAnalyticsEventsService, AuthService, ConfirmationDialogService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
