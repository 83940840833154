import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logoType: string;
  showFindPlant: boolean;
  showLogo: boolean;
  menuToggled: boolean;

  constructor(private router: Router,
              public authService: AuthService) {
    this.menuToggled = false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showLogo = true;
        if (event.url === '/') {
          this.logoType = 'assets/gpp-logo-wide.png';
        } else if (event.url.indexOf('/print-view') !== -1) {
          this.logoType = 'assets/gpp-print-logo.png';
          this.showLogo = false;
        } else {
          this.logoType = 'assets/gpp-logo-wide.png';
        }
        this.showFindPlant = ((event.url.indexOf('plant-details') === -1) && (event.url.indexOf('print-view') === -1) &&
          (event.url.indexOf('searchnav') === -1) && (event.url.indexOf('advanced') === -1));

        // handle search cookie
        /*        if (event.url !== '/search/results') {
                  // clear cookies
                  const date = new Date();
                  const knumDays = 30;
                  date.setTime(date.getTime() - (knumDays * 24 * 60 * 60 * 1000));
                  const expires = '; expires=' + date.toUTCString();
                  document.cookie = 'search=' + expires + '; path=/';
        */
      }
    });

  }

  ngOnInit() {
  }

  toggleVisibility(e) {
    this.menuToggled = e.target.checked;
  }
  closeMenu() {
    this.menuToggled = false;
  }
  gotoHome() {
    this.menuToggled = false;
    this.router.navigateByUrl('/');
  }

  gotoAbout() {
    this.menuToggled = false;
    this.router.navigateByUrl('/about');
  }

  gotoSearch() {
    this.router.navigateByUrl('/searchnav');
  }

  gotoAdvSearch() {
    this.router.navigateByUrl('/search/results');
  }

  gotoResources() {
    this.router.navigateByUrl('/resources');
  }

  gotoPress() {
    this.router.navigateByUrl('/press');
  }

  gotoContact() {
    this.router.navigateByUrl('/contact');
  }

  gotoGlossary() {
    this.router.navigateByUrl('/resources/glossary');
  }

  gotoDonate() {
    this.router.navigateByUrl('/donate');
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
