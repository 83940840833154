import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plant-not-listed',
  templateUrl: './plant-not-listed.component.html',
  styleUrls: ['./plant-not-listed.component.scss']
})
export class PlantNotListedComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoAboutCriteria() { this.router.navigateByUrl('/about/criteria'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoPlantNotListed() { this.router.navigateByUrl('/search/plantNotListed'); }

}
