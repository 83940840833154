import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoTerms() { this.router.navigateByUrl('/press/terms'); }
  gotoPoster() {
    this.router.navigateByUrl('/poster');
  }

}
