<div class="wrapper" style="margin-top: 160px;">
  <div class="text-wrap">

    <h1>Add or Edit a Nursery Listing</h1>
    <p style="text-align:center;">Use this form to add a new nursery or edit an existing nursery. To edit a nursery, visit the Nursery Directory page and click the "Edit" button for that nursery (last column on the right).</p>
  </div>

  <div class="link-container bg-light p-3" style="box-sizing: border-box;">
    <form [formGroup]="nurseryForm">
      <fieldset>
        <legend>Nursery Information</legend>
        <div class="row">
          <div class="col-md-1">
            <label>Nursery Name:</label>
          </div>
          <div class="col-md-5">
            <input class="w-100 form-control form-control-sm" name="nurseryName" formControlName="nurseryName" [ngClass]="{ 'is-invalid': submitted && f.nurseryName.errors }">
            <div *ngIf="submitted && f.nurseryName.errors" class="invalid-feedback">
              <div *ngIf="f.nurseryName.errors.required">Nursery Name is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Street Address:</label>
          </div>
          <div class="col-md-5">
            <input class="w-100 form-control form-control-sm" name="address" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">Nursery Address is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
              <label>City:</label>
          </div>
          <div class="col-md-2">
            <input class="w-100 form-control form-control-sm" name="city" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
              <div *ngIf="f.city.errors.required">City is required</div>
            </div>
          </div>
          <div class="col-md-1">
            <label>State/Province:</label>
          </div>
          <div class="col-md-1">
            <select formControlName="state" class="w-100 form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
              <option value="">Choose</option>
              <option value="WA">WA</option>
              <option value="OR">OR</option>
              <option value="BC">BC</option>
            </select>
            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
              <div *ngIf="f.state.errors.required">State is required</div>
            </div>
          </div>
          <div class="col-md-1">
            <label>Zip/Postal Code:</label>
			</div>
			<div class="col-md-2">
            <input class="w-50 ml-2 form-control form-control-sm" name="zipcode" formControlName="zipCode" [ngClass]="{ 'is-invalid': submitted && f.zipCode.errors }">
            <div *ngIf="submitted && f.zipCode.errors" class="invalid-feedback">
              <div *ngIf="f.zipCode.errors.required">Zipcode is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Public Phone:</label>
          </div>
          <div class="col-md-1">
            <input class="w-100 form-control form-control-sm" placeholder="Area code" name="areaCode" formControlName="areaCode" [ngClass]="{ 'is-invalid': submitted && f.areaCode.errors }">
            <div *ngIf="submitted && f.areaCode.errors" class="invalid-feedback">
              <div *ngIf="f.areaCode.errors.required">Area Code is required</div>
              <div *ngIf="f.areaCode.errors.maxlength">The Area Code must be 3 numbers long</div>
            </div>
          </div>
          <div class="col-md-1">
            <input class="w-100 form-control form-control-sm" placeholder="Prefix" name="prefix" formControlName="prefix" [ngClass]="{ 'is-invalid': submitted && f.prefix.errors }">
            <div *ngIf="submitted && f.prefix.errors" class="invalid-feedback">
              <div *ngIf="f.prefix.errors.required">Phone number prefix is required</div>
              <div *ngIf="f.prefix.errors.maxlength">The Prefix must be 3 numbers long</div>
            </div>
          </div>
          <div class="col-md-1">
            <input class="w-100 form-control form-control-sm" placeholder="Number" name="number" formControlName="number" [ngClass]="{ 'is-invalid': submitted && f.number.errors }">
            <div *ngIf="submitted && f.number.errors" class="invalid-feedback">
              <div *ngIf="f.number.errors.required">Phone number is required</div>
              <div *ngIf="f.number.errors.maxlength">The Phone number must be 4 numbers long</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Sales:</label>
          </div>
          <div class="col-md-1">
            <select formControlName="sales" class="w-100 form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.sales.errors }">
              <option value="">Choose</option>
              <option value="Retail">Retail</option>
              <option value="Wholesale">Wholesale</option>
            </select>
            <div *ngIf="submitted && f.sales.errors" class="invalid-feedback">
              <div *ngIf="f.sales.errors.required">Please select a sales type</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Website Url:</label>
          </div>
          <div class="col-md-3">
            <input class="w-100 form-control form-control-sm" name="webAddress" formControlName="webAddress" [ngClass]="{ 'is-invalid': submitted && f.webAddress.errors }">
            <div *ngIf="submitted && f.webAddress.errors" class="invalid-feedback">
              <div *ngIf="f.webAddress.errors.required">Nursery website url is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Contact Name:</label>
          </div>
          <div class="col-md-3">
            <input class="w-100 form-control form-control-sm" name="contactName" formControlName="contactName">
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Contact Email:</label>
          </div>
          <div class="col-md-3">
            <input class="w-100 form-control form-control-sm" name="contactEmail" formControlName="contactEmail">
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Contact Phone:</label>
          </div>
          <div class="col-md-3">
            <input class="w-100 form-control form-control-sm" name="contactPhone" formControlName="contactPhone">
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <label>Publish:</label>
          </div>
          <div class="col-md-1">
            <select formControlName="publish" class="w-100 form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.publish.errors }">
              <option value="">Choose</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            <div *ngIf="submitted && f.publish.errors" class="invalid-feedback">
              <div *ngIf="f.publish.errors.required">Please choose whether to publish this nursery</div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="form-buttons w-100 text-center">
        <button class="btn btn-primary mr-5"
                (click)="submit()">Save
        </button>
        <button class="btn btn-danger ml-5"
                (click)="delete()">Delete This Nursery
        </button>
      </div>
    </form>
  </div>
</div>
