<div class="home-image" style="background-image: url(../../../assets/plants/berberis-thunbergii-concorde.jpg)">
	<h1>Glossary</h1>
</div>
<div class="wrapper">
  <div class="breadcrumbs">
    <ul>
      <li><a [routerLink]="" (click)="gotoNursery()">Nursery Directory</a></li>
      <li><a [routerLink]="" (click)="gotoLinks()">Helpful Links &amp; Resources</a></li>
      <li><a [routerLink]="" id="current" (click)="gotoGlossary()">Glossary of Common Terms</a></li>
    </ul>
  </div><!-- end breadcrumbs -->

<h1>Glossary of Common Terms</h1>

  <dl class="glossary">
    <dt id="clay_soil">clay soil</dt>
    <dd>Clay soils are composed of very fine particles that when wet feel sticky. Clay typically feels very heavy and
      holds water longer than other soil types.
    </dd>
    <dt id="compacted_soil">compacted soil</dt>
    <dd>Compaction occurs when soil particles are pressed together reducing the infiltration of water and air. This can
      be caused by construction, vehicles, or heavy foot traffic.
    </dd>
    <dt id="dappled_shade">dappled shade</dt>
    <dd>Dappled shade is a garden site under a canopy of trees and this area receives about two to three hours of
      sunlight filtered through the branches above. When one looks up into the canopy between one-quarter to one-third
      of the sky is still visible through the foliage.
    </dd>
    <dt id="deciduous_plant">deciduous plant</dt>
    <dd>Plants that are deciduous completely lose all of their leaves in autumn and new foliage grows back in
      springtime.
    </dd>
    <dt id="deep_shade">deep shade</dt>
    <dd>This type of shade is dense with little to no direct sunlight, usually occurring from an almost impenetrable
      overhead canopy of trees with less than one quarter of the sky being visible.
    </dd>
    <dt id="deer_resistance"><a name="deer" id="deer">deer resistance</a></dt>
    <dd>Virtually no plant is invulnerable to being damaged by deer if population pressures or severe weather conditions
      prevail. However, there are a number of plants that are generally less favored and are rarely disturbed by deer.
      If a Great Plant Picks selection is listed as resistant to deer, it will seldom, if ever, be affected by them.
    </dd>
    <dt id="drought_tolerant">drought tolerant</dt>
    <dd>Plants only require supplemental water about once a month during the dry season after being established
      (typically two years of regular supplemental watering during the dry season).
    </dd>
    <dt id="evergreen_plant">evergreen plant</dt>
    <dd>Plants that are evergreen retain leaves throughout the year.</dd>
    <dt id="foliage_deciduous_plant"><a name="foliage" id="foliage"></a>foliage&#8212;deciduous plant</dt>
    <dd>Plants that are deciduous completely lose all of their leaves in autumn and new foliage grows back in
      springtime.
    </dd>
    <dt id="foliage_evergreen_plant">foliage&#8212;evergreen plant</dt>
    <dd>Plants that are evergreen retain leaves throughout the year.</dd>
    <dt id="foliage_semi_evergreen_plant">foliage&#8212;semi-evergreen plant</dt>
    <dd>Plants that are semi-evergreen lose some leaves in the autumn, but retain some throughout the winter.</dd>
    <dt id="full_sun">full sun</dt>
    <dd>A garden site with full sun receives six or more hours of direct sunlight each day.</dd>
    <dt id='hardiness_zones'><a name="hardiness" id="hardiness"></a>hardiness zones</dt>
    <dd>Hardiness zones provide the minimum average winter temperature for a given area. This is only one factor of many
      that account for a plant&#8217;s survival. Local nurseries or the cooperative extension service can provide your
      hardiness zone.
    </dd>
    <dt id="light_requirements_full_sun"><a name="light" id="light"></a>light requirements&#8212;full sun</dt>
    <dd>A garden site with full sun receives six or more hours of direct sunlight each day.</dd>
    <dt id="light_requirements_dappled_shade">light requirements&#8212;dappled shade</dt>
    <dd>Dappled shade is a garden site under a canopy of trees and this area receives about two to three hours of
      sunlight filtered through the branches above. When one looks up into the canopy between one-quarter to one-third
      of the sky is still visible through the foliage.
    </dd>
    <dt id="light_requirements_deep_shade">light requirements&#8212;deep shade</dt>
    <dd>This type of shade is dense with little to no direct sunlight, usually occurring from an almost impenetrable
      overhead canopy of trees with less than one quarter of the sky being visible.
    </dd>
    <dt id="light_requirements_light_shade">light requirements&#8212;light shade</dt>
    <dd>Light shade offers the most versatility for a wide range of plants to thrive. These areas receive about four to
      six hours of direct sunlight every day.
    </dd>
    <dt id="light_requirements_open_shade">light requirements&#8212;open shade</dt>
    <dd>Open shade and light shade are often considered interchangeable, but there are distinct differences. An area of
      the landscape considered to have open shade is one that is exposed to the sky but with little to no direct
      sunlight. This usually occurs when a structure or building blocks any direct sunlight, but the area does not have
      a foliar canopy above it.
    </dd>
    <dt id="moist_watering">moist watering</dt>
    <dd>Plants require supplemental water 2 to 3 times a week during the dry season.</dd>
    <dt id="occasional_watering">occasional watering</dt>
    <dd>Plants require supplemental water every 10 to 14 days during the dry season.</dd>
    <dt id="open_shade">open shade</dt>
    <dd>Open shade and light shade are often considered interchangeable, but there are distinct differences. An area of
      the landscape considered to have open shade is one that is exposed to the sky but with little to no direct
      sunlight. This usually occurs when a structure or building blocks any direct sunlight, but the area does not have
      a foliar canopy above it.
    </dd>
    <dt id="plant_height"><a name="height" id="height"></a>plant height</dt>
    <dd>Approximate plant height, depending on growing conditions. For trees, conifers, shrubs, bamboos and woody vines,
      this is estimated height at 10 years of age.
    </dd>
    <dt id="plant_height_mature">plant height&#8212;mature</dt>
    <dd>Approximate plant height at maturity, for trees, conifers, shrubs, bamboos and woody vines above 10 years of
      age.
    </dd>
    <dt id="plant_width"><a name="width" id="width"></a>plant width</dt>
    <dd>Approximate plant width, depending on growing conditions. For trees, conifers, shrubs, bamboos and woody vines,
      this is estimated height at 10 years of age.
    </dd>
    <dt id="plant_width_mature">plant width&#8212;mature</dt>
    <dd>Approximate plant width at maturity, for trees, conifers, shrubs, bamboos and woody vines above 10 years of
      age.
    </dd>
    <dt id="regular_watering">regular watering</dt>
    <dd>Plants require supplemental water once per week during the dry season.</dd>
    <dt id="sandy_soil">sandy soil</dt>
    <dd>Sandy soils are composed of larger particles that feel granular and grainy. Sand is typically well-drained and
      does not retain water well.
    </dd>
    <dt id="semi_evergreen_plant">semi-evergreen plant</dt>
    <dd>Plants that are semi-evergreen lose some leaves in the autumn, but retain some throughout the winter.</dd>
    <dt id="shade_dappled">shade&#8212;dappled</dt>
    <dd>Dappled shade is a garden site under a canopy of trees and this area receives about two to three hours of
      sunlight filtered through the branches above. When one looks up into the canopy between one-quarter to one-third
      of the sky is still visible through the foliage.
    </dd>
    <dt id="shade_deep">shade&#8212;deep</dt>
    <dd>This type of shade is dense with little to no direct sunlight, usually occurring from an almost impenetrable
      overhead canopy of trees with less than one quarter of the sky being visible.
    </dd>
    <dt id="shade_light">shade&#8212;light</dt>
    <dd>Light shade offers the most versatility for a wide range of plants to thrive. These areas receive about four to
      six hours of direct sunlight everyday.
    </dd>
    <dt id="shade_open">shade&#8212;open</dt>
    <dd>Open shade and light shade are often considered interchangeable, but there are distinct differences. An area of
      the landscape considered to have open shade is one that is exposed to the sky but with little to no direct
      sunlight. This usually occurs when a structure or building blocks any direct sunlight, but the area does not have
      a foliar canopy above it.
    </dd>
    <dt id="soil_clay">soil&#8212;clay</dt>
    <dd>Clay soils are composed of very fine particles that when wet feel sticky. Clay typically feels very heavy and
      holds water longer than other soil types.
    </dd>
    <dt id="soil_compacted">soil&#8212;compacted</dt>
    <dd>Compaction occurs when soil particles are pressed together reducing the infiltration of water and air. This can
      be caused by construction, vehicles, or heavy foot traffic.
    </dd>
    <dt id="soil_sandy">soil&#8212;sandy</dt>
    <dd>Sandy soils are composed of larger particles that feel granular and grainy. Sand is typically well-drained and
      does not retain water well.
    </dd>
    <dt id="soil_well_drained">soil&#8212;well-drained</dt>
    <dd>Well-drained soils allow water to move through reasonably quickly and not stand and pool. Puddles will not last
      longer than an hour or two.
    </dd>
    <dt id="soil_wet">soil&#8212;wet</dt>
    <dd>Wet soils will puddle during winter rains and the puddles remain for longer than one day. Even during dry
      summers the soil often remains moist.
    </dd>
    <dt id="watering_drought_tolerant"><a name="water" id="water"></a>watering&#8212;drought tolerant</dt>
    <dd>Plants only require supplemental water about once a month during the dry season after being established
      (typically two years of regular supplemental watering during the dry season).
    </dd>
    <dt id="watering_frequent">watering&#8212;frequent</dt>
    <dd>Plants require supplemental water 2 to 3 times a week during the dry season.</dd>
    <dt id="watering_occasional">watering&#8212;occasional</dt>
    <dd>Plants require supplemental water every 10 to 14 days during the dry season.</dd>
    <dt id="watering_regular">watering&#8212;regular</dt>
    <dd>Plants require supplemental water once per week during the dry season.</dd>
    <dt id="well_drained_soil">well-drained soil</dt>
    <dd>Well-drained soils allow water to move through reasonably quickly and not stand and pool. Puddles will not last
      longer than an hour or two.
    </dd>
    <dt id="wet_soil">wet soil</dt>
    <dd>Wet soils will puddle during winter rains and the puddles remain for longer than one day. Even during dry
      summers the soil often remains moist.
    </dd>
  </dl>

  <div class="clear"></div>
</div><!-- end wrapper -->
