<div class="home-image" style="background-image: url(../../../assets/plants/berberis-darwinii.jpg)">	  
	<h1>Helpful Links &amp; Resources</h1>
</div> 
<div id="wrapper">
  
  <div class="breadcrumbs">
    <ul>
      <li><a [routerLink]="" (click)="gotoNursery()">Nursery Directory</a></li>
      <li><a [routerLink]="" id="current" (click)="gotoLinks()">Helpful Links &amp; Resources</a></li>
      <li><a [routerLink]="" (click)="gotoGlossary()">Glossary of Common Terms</a></li>
    </ul>
  </div><!-- end breadcrumbs -->
  
  <div class="content">
    <div class="text-wrap">
      <p><strong><a href="http://depts.washington.edu/hortlib/collections/pal.shtml">Plant Answer Line</a></strong><br>This
        unique resource is provided by the Elisabeth C. Miller Library at the Center for Urban Horticulture in Seattle,
        Washington. The Plant Answer Line provides quick answers to gardening questions. Using many resources found in
        the Miller Library and on the internet, this resource is tailored to the needs of Pacific Northwest Gardeners.
        Call the Plant Answer Line at <em>206-897-5268</em>, email to <a href="mailto:hortlib@uw.edu">hortlib@uw.edu</a>,
        or visit the Miller Library website and submit your questions <a
          href="https://depts.washington.edu/hortlib/resources-info/pal/">here</a>.</p>
      <p><strong><a href="https://forums.botanicalgarden.ubc.ca/">University of British Columbia Garden
        Forum</a></strong><br>
        The UBC Garden Forum is maintained by UBC Botanical Garden and Centre for Plant Research to promote backyard
        biodiversity and habitats in Canada as part of its educational mission. Although small compared to other
        Internet gardening discussion forums, the UBC Botanical Garden Forum is seemingly the largest of any
        plant-related forum that fosters communication between the public, other plant professionals and UBC Botanical
        Garden researchers, staff and volunteers.</p>
      <p><strong>Cooperative Extension Service</strong><br>
        This service is a partnership between the United States Department of Agriculture and agricultural colleges in
        every state, begun in the 19th century and early 20th century. The service was created to provide the general
        public with the knowledge gained from research to improve agriculture and horticulture practices primarily, but
        also offering information on home and family, the environment, and community economic development. Follow the
        Extension Service links for more information:</p>
      <ul>
        <li><a href="http://ext.wsu.edu/">Washington State University Cooperative Extension</a></li>
        <li><a href="https://extension.oregonstate.edu/">Oregon State University Cooperative Extension</a></li>
        <li>In British Columbia, Canada, the Ministry of Agriculture provides agricultural and horticultural information
          to the general public:
        </li>
        <li><a
          href="https://www2.gov.bc.ca/gov/content/governments/organizational-structure/ministries-organizations/ministries/agriculture">British
          Columbia Agriculture</a></li>
      </ul>
      <p><strong>Master Gardener Program</strong><br>
        The origin of this now internationally widespread program began in the Pacific Northwest. In the early 1970s
        Cooperative Extension Service Agents in the Washington state counties of King and Pierce began to experience an
        increasingly high demand for information from urban gardeners. This prompted the development of the Master
        Gardener program to train volunteers in the basics of horticulture and, in return for their education,
        volunteers commit to answering questions from the gardening public, thus allowing the Extension Agents the time
        to fulfill broader educational programming. To contact a Master Gardener:</p>
      <ul>
        <li>Washington: <a href="http://mastergardener.wsu.edu/program/county/">WSU Master Gardener Program by
          county</a></li>
        <li>Oregon: <a href="http://extension.oregonstate.edu/mg">OSU Master Gardener Program</a></li>
        <li>British Columbia: <a href="http://www.mgabc.org">Master Gardeners Association of BC</a></li>
      </ul>
      <p><strong>Garden Exhibitions &amp; Nursery Trade Fairs</strong></p>
      <ul>
        <li><a href="https://www.gardenshow.com">Northwest Flower &amp; Garden Show</a></li>
        <li><a href="https://farwestshow.com/">FarWest Show</a></li>
      </ul>
      <p><strong>Nursery Organizations</strong></p>
      <ul>
        <li><a href="https://www.oan.org">Oregon Association of Nurseries</a></li>
        <li><a href="https://www.wsnla.org">Washington State Nursery &amp; Landscape Association</a></li>
        <li><a href="https://bclna.com/">British Columbia Landscape &amp; Nursery Association</a></li>
      </ul>
      <p><strong>Botanical Gardens</strong></p>
      <ul>
        <li><a href="https://bellevuebotanical.org/">Bellevue Botanical Garden</a></li>
        <li><a href="https://www.hoytarboretum.org/">Hoyt Arboretum</a></li>
		  <li><a href="https://pugetsoundgardens.org/">Puget Sound Public Gardens</a></li>
        <li><a href="https://botanicgardens.uw.edu/">University of Washington Botanic Gardens</a></li>
        <li><a href="https://botanicalgarden.ubc.ca/">University of British Columbia Botanical Garden &amp; Centre for
          Plant Research</a></li>
      </ul>
      <p><strong>Horticulture Societies</strong></p>
      <ul>
        <li><a href="https://www.arboretumfoundation.org/">Arboretum Foundation</a></li>
        <li><a href="https://www.gardenconservancy.org/preservation/northwest-network">Garden Conservancy Northwest
          Network</a></li>
        <li><a href="https://hardyferns.org/">Hardy Fern Foundation</a></li>
        <li><a href="https://www.hardyplantsociety.org/">Hardy Plant Society of Oregon</a></li>
        <li><a href="http://www.hardyplantsocietywa.org/">Hardy Plant Society of Washington</a></li>
        <li><a href="https://www.northwesthort.org/">Northwest Horticultural Society</a></li>
        <li><a href="https://www.thehardyplantgroup.org/">Willamette Valley Hardy Plant Group</a></li>
      </ul>
    </div><!-- end content -->
  </div>
</div><!-- #wrapper -->
