<div class="home-image" style="background-image: url(../../assets/plants/Primula-japonica-RAS.jpg)">	  <h1>Why Can&#8217;t I Find My Plant?</h1>
</div> 
<div class="wrapper">
       <div class="breadcrumbs">
<ul>
        <li><a [routerLink]="" (click)="gotoSearch()">Plant Lists/Search by Name</a></li>
        <li><a [routerLink]="" (click)="gotoAdvSearch()">Advanced Search</a></li>
        <li><a [routerLink]="" (click)="gotoPlantNotListed()" id="current">Why Can&#8217;t I Find My Plant?</a></li>
</ul>
</div> <!-- end breadcrumbs -->
	<div class="content">
      <h2>Why Can&#8217;t I Find My Plant?</h2>
		<div class="text-wrap">
<p>The generally mild climate of the Pacific Northwest allows for a very diverse range of plants to be grown in this region. There are potentially tens of thousands of different plants available to the gardening public from local and mail order nurseries. If a plant you are looking for is not listed as a Great Plant Pick, it is for one of the following reasons:</p>
<ul>
  <li>If you are searching by plant name, check the spelling.</li>
  <li>If you are working from the Advanced Search page, you may have selected too many plant attributes at one time&#8212;try clicking on fewer check boxes.</li>
  <li>The plant has not yet been considered by the GPP selection committee.</li>
  <li>The plant does not meet the <a [routerLink]="" (click)="gotoAboutCriteria()">GPP Selection Criteria</a>.</li>
  <li>The plant is currently under evaluation by the GPP Selection Committee.</li>
</ul>

		</div><!-- .text-wrap -->
		</div><!-- .content -->
</div><!-- .wrapper -->
