<div class="home-image" style="background-image: url(../../../assets/plants/berberis-thunbergii-goruzam-golden-ruby.jpg)">
	<h1>Nursery Directory</h1>
</div>
<div class="wrapper">
     <div class="breadcrumbs" id="start">
<ul>
        <li><a [routerLink]="" (click)="gotoNursery()" id="current">Nursery Directory</a></li>
        <li><a [routerLink]="" (click)="gotoLinks()">Helpful Links &amp; Resources</a></li>
        <li><a [routerLink]="" (click)="gotoGlossary()">Glossary of Common Terms</a></li>
	</ul>
      </div><!-- end breadcrumbs -->

<div class="text-wrap" #topContent >
<p>The following nurseries and retail centers have indicated to Great Plant Picks that they carry a number of GPP selections. As availability changes regularly throughout the year, it is wise to contact a nursery directly to check on plants for purchase.</p>
      <p><em>Note to Nurseries:</em> Listing in the GPP Nursery Directory is free.  If you would like to be added to the GPP Nursery Directory, please contact the GPP manager at <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a> or phone 206-362-8612.</p>
</div><!-- .text-wrap -->
<div class="table-wrap">
      <h2>Washington Nurseries</h2>
<table class="nursery responsive-sm table-striped w-auto">
      <thead>
      <tr>
        <th [ngClass]="{'sorting_asc' : waNameActive}" style="cursor: pointer;" (click)="sortByName(waNurseries, 'wa')">Nursery Name</th>
        <th [ngClass]="{'sorting_asc' : waLocationActive}" style="cursor: pointer;" (click)="sortByLocation(waNurseries, 'wa')">Location</th>
        <th>Phone</th><th>Website</th>
        <th *ngIf="authService.isLoggedIn()">Published</th>
        <th *ngIf="authService.isLoggedIn()">Edit</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let nursery of waNurseries">
        <td>{{ nursery.nursery_name }}</td>
        <td>{{ nursery.city }}</td>
        <td>{{ nursery.phone_area_code }}-{{ nursery.phone_prefix }}-{{nursery.phone_number }}</td>
        <td><a href="http://{{ nursery.website_url }}" target="_blank">{{nursery.website_url}}</a></td>
        <td *ngIf="authService.isLoggedIn()">{{ nursery.publish }}</td>
        <td *ngIf="authService.isLoggedIn()"><a [routerLink]="" (click)="editNursery(nursery.id)">Edit</a> </td>
      </tr>
      </tbody>
</table><h2>Oregon Nurseries</h2>
<table class="nursery responsive-sm table-striped w-auto">
      <thead>
      <tr>
        <th [ngClass]="{'sorting_asc' : orNameActive}" style="cursor: pointer;" (click)="sortByName(orNurseries, 'or')">Nursery Name</th>
        <th [ngClass]="{'sorting_asc' : orLocationActive}" style="cursor: pointer;" (click)="sortByLocation(orNurseries, 'or')">Location</th>
        <th>Phone</th>
        <th>Website</th>
        <th *ngIf="authService.isLoggedIn()">Published</th>
        <th *ngIf="authService.isLoggedIn()">Edit</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let nursery2 of orNurseries">
        <td>{{ nursery2.nursery_name }}</td>
        <td>{{ nursery2.city }}</td>
        <td>{{ nursery2.phone_area_code }}-{{ nursery2.phone_prefix }}-{{nursery2.phone_number }}</td>
        <td><a href="http://{{ nursery2.website_url }}" target="_blank">{{nursery2.website_url}}</a></td>
        <td *ngIf="authService.isLoggedIn()">{{ nursery2.publish }}</td>
        <td *ngIf="authService.isLoggedIn()"><a [routerLink]="" (click)="editNursery(nursery2.id)">Edit</a> </td>
      </tr>
      </tbody>
    </table><h2>British Columbia Nurseries</h2>
<table class="nursery responsive-sm table-striped w-auto">
      <thead>
      <tr>
        <th  [ngClass]="{'sorting_asc' : bcNameActive}" style="cursor: pointer;" (click)="sortByName(bcNurseries, 'bc')">Nursery Name</th>
        <th [ngClass]="{'sorting_asc' : bcLocationActive}" style="cursor: pointer;" (click)="sortByLocation(bcNurseries, 'bc')">Location</th>
        <th>Phone</th>
        <th>Website</th>
        <th *ngIf="authService.isLoggedIn()">Published</th>
        <th *ngIf="authService.isLoggedIn()">Edit</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let nursery3 of bcNurseries">
        <td>{{ nursery3.nursery_name }}</td>
        <td>{{ nursery3.city }}</td>
        <td>{{ nursery3.phone_area_code }}-{{ nursery3.phone_prefix }}-{{nursery3.phone_number }}</td>
        <td><a href="http://{{ nursery3.website_url }}" target="_blank">{{nursery3.website_url}}</a></td>
        <td *ngIf="authService.isLoggedIn()">{{ nursery3.publish }}</td>
        <td *ngIf="authService.isLoggedIn()"><a [routerLink]="" (click)="editNursery(nursery3.id)">Edit</a> </td>
      </tr>
      </tbody>
    </table><h2>Other Nurseries</h2>
<table class="nursery responsive-sm table-striped w-auto">
      <thead>
      <tr>
        <th [ngClass]="{'sorting_asc' : otherNameActive}" style="cursor: pointer;" (click)="sortByName(otherNurseries,'other')">Nursery Name</th>
        <th [ngClass]="{'sorting_asc' : otherLocationActive}" style="cursor: pointer;" (click)="sortByLocation(otherNurseries, 'other')">Location</th>
        <th>Phone</th>
        <th>Website</th>
        <th *ngIf="authService.isLoggedIn()">Published</th>
        <th *ngIf="authService.isLoggedIn()">Edit</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let nursery4 of otherNurseries">
        <td>{{ nursery4.nursery_name }}</td>
        <td>{{ nursery4.city }}</td>
        <td>{{ nursery4.phone_area_code }}-{{ nursery4.phone_prefix }}-{{nursery4.phone_number }}</td>
        <td><a href="http://{{ nursery4.website_url }}" target="_blank">{{nursery4.website_url}}</a></td>
        <td *ngIf="authService.isLoggedIn()">{{ nursery4.publish }}</td>
        <td *ngIf="authService.isLoggedIn()"><a [routerLink]="" (click)="editNursery(nursery4.id)">Edit</a> </td>
      </tr>
      </tbody>
    </table>
		<button class="top-button aligncenter"><a [routerLink]="" (click)="goBackToTop()">Back to top</a></button>

</div><!-- .table-wrap -->

</div>
