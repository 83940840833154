import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  showFooter: boolean;

  constructor(private router: Router) {
    this.showFooter = true;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.showFooter = true;
        } else if (event.url.indexOf('/print-view') !== -1) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      }
    });

  }

  ngOnInit() {
  }

  gotoHome() { this.router.navigateByUrl('/'); }
  gotoAbout() { this.router.navigateByUrl('/about'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoResources() { this.router.navigateByUrl('/resources'); }
  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }
}
