<div class="home-image" style="background-image: url(../../assets/plants/cistus-hybridus.jpg)">	   
	<h1>Image &amp; Logo Use</h1>
</div> 
<div class="wrapper">
  <div class="breadcrumbs">
    <ul>
      <li><a [routerLink]="" (click)="gotoPress()">Press</a></li>
	  <li><a [routerLink]="" (click)="gotoPoster()">Posters</a></li>	
      <li><a [routerLink]="" id="current" (click)="gotoTerms()">Image &amp; Logo Use Guidelines</a></li>
    </ul>
  </div><!-- end breadcrumbs -->

  
	<div class="text-wrap">
  <h1>Logo Use</h1>
  <ul>
    <li>Please feel free to use the Great Plant Picks logo on your promotional literature, plant tags and other point of sale information. The logo may not be attached to any plant that has not received the Great Plant Picks designation.</li>
    <li>The logo is copyrighted and may not be altered in any way. One exception is made for black-and-white printing.</li>
    <li>By using the logo, you agree to this use policy. If you have other use proposals or need help access the logo, please contact us at <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a> or (206) 362-8612.</li>
    <li>Click on the text links below to download the logo you would like to use.</li>
  </ul>
  <div class="grid half">
    <a href="../assets/gpp-logo-bw.png" download><img class="logo-download aligncenter" src="../assets/gpp-in-bw.png" alt="black-and-white GPP logo">
    <p>Download black-and-white (421kb)</p></a>
  </div>
  <div class="grid half">
    <a href="../assets/gpp-logo-color.png" download><img class="logo-download aligncenter" src="../assets/gpp-in-color.png" alt="color GPP logo">
    <p>Download color logo (1.01Mb)</p></a>
  </div>
  <div class="clear"></div>
  <h1>Image Use</h1>
  <ul>
    <li>Great Plant Picks plant images may be downloaded free from the Great Plant Picks website for use in other publications. The correct credit string must be published with any image used. If the term &#8220;Great Plant Picks&#8221; is not already included in the credit string, please add &#8220;courtesy Great Plant Picks&#8221; after the credit string to acknowledge the program. By using an image or images, you agree to this policy.</li>
    <li>Many of these images are also available in a high-resolution format. Please contact Great Plant Picks at <a href="mailto:rickp@millergarden.org">rickp@millergarden.org</a> or (206) 362-8612 for the image usage policy and rates.
    </li>
  </ul>
</div><!-- .text-wrap -->
</div><!-- .wrapper -->
