import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
  }
  gotoHome() { this.router.navigateByUrl('/'); }
  gotoAbout() { this.router.navigateByUrl('/about'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoResources() { this.router.navigateByUrl('/resources'); }
  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }
  gotoNursery() { this.router.navigateByUrl('/resources/nurserylist'); }
  gotoLinks() {
    this.router.navigateByUrl('/resources/links');
  }

}
