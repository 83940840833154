import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantData, PlantThumbnail } from '../models/plant.model';
import { PlantService } from '../services/plant.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-plant-details',
  templateUrl: './plant-details.component.html',
  styleUrls: ['./plant-details.component.scss']
})
export class PlantDetailsComponent implements OnInit {

  currentPlant: PlantData;
  currentPlantId: number;
  imageThumbnails: PlantThumbnail[];
  imageSource: string;
  comboArray: any[];
  botanicalName: string;
  synString: string;
  maxCategories: boolean;
  flowerColorString: string;
  wildlifeString: string;
  pestString: string;
  showCombos: boolean;
  errorString: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              public authService: AuthService,
              private plantService: PlantService) {
    this.currentPlant = { };
    this.comboArray = [];
    this.synString = '';
    this.flowerColorString = '';
    this.wildlifeString = '';
    this.pestString = '';
    this.maxCategories = false;
    this.showCombos = false;
    this.errorString = '';
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.ngZone.run(() => {
        this.currentPlantId =  +params.get('plantid'); // (+) converts string 'id' to a number
        this.plantService.getPlantById(this.currentPlantId)
          .subscribe((res) => {
            this.ngZone.run(() => {
              if (res.statusCode === 200) {
                this.errorString = '';
                if (res.hasOwnProperty('plant')) {
                  this.currentPlant = res.plant;
                  if (this.currentPlant.hasOwnProperty('plant_combinations')) {
                    if (this.currentPlant.plant_combinations !== null) {
                      this.getCombos(this.currentPlant.plant_combinations);
                    }
                  }
                  this.botanicalName = this.plantService.render_botanical_name(null, null, this.currentPlant);
                  const synList = this.currentPlant.synonyms;
                  synList.forEach((aSyn) => {
                    this.synString += this.plantService.render_botanical_name(null, null, aSyn);
                  });
                  const flowerColorList = this.currentPlant.plantFlowerColor;
                  const colorArray = [];
                  flowerColorList.forEach((aColor) => {
                    colorArray.push(aColor.name);
                  });
                  this.flowerColorString = colorArray.join(', ');
                  const wildlifeList = this.currentPlant.plantWildlife;
                  const wildlifeArray = [];
                  wildlifeList.forEach((aWildlife) => {
                    wildlifeArray.push(aWildlife.name);
                  });
                  this.wildlifeString = wildlifeArray.join(', ');

                  const pestList = this.currentPlant.plantPestResistance;
                  const pestArray = [];
                  pestList.forEach((aPest) => {
                    pestArray.push(aPest.name);
                  });
                  this.pestString = pestArray.join(', ');

                  const plantCategory = this.currentPlant.plant_type;
                  this.maxCategories = (['tree', 'shrub', 'conifer', 'bamboo', 'vine'].indexOf(plantCategory) > -1);
                  if ((this.currentPlant.plant_combinations != null) &&
                    ((this.currentPlant.plant_combinations.length > 0) ||
                      (this.currentPlant.color_contrast.length > 0) ||
                      (this.currentPlant.color_partners.length > 0))) {
                    this.showCombos = true;
                  }
                }
                if (res.hasOwnProperty('imageThumbnails')) {
                  this.imageThumbnails = res.imageThumbnails;
                }
                if (res.hasOwnProperty('imageSource')) {
                  this.imageSource = res.imageSource;
                }
              } else {
                if (res.statusCode === 404) {
                  this.errorString = 'Unable to find selected plant. Please go back and try again.';
                } else {
                  this.errorString = 'An unexpected error occurred. Please try again';
                }
              }
            });
          });
      });
    });
  }
  substituteImage(thumb: PlantThumbnail) {
    this.imageSource = thumb.imageThumbnail.replace('thumbs/', '');
  }
  gotoPrintView() {
    this.router.navigateByUrl('/search/print-view/' + this.currentPlantId);
  }
  gotoFoliage() {
//    this.router.navigateByUrl('/resources/glossary');
    this.router.navigate(['/resources/glossary'], {fragment: 'foliage_deciduous_plant'});
  }
  gotoHeight() {
    this.router.navigate(['/resources/glossary'], {fragment: 'plant_height'});
  }
  gotoWidth() {
    this.router.navigate(['/resources/glossary'], {fragment: 'plant_width'});
  }
  gotoHeightMature() {
    this.router.navigate(['/resources/glossary'], {fragment: 'plant_height_mature'});
  }
  gotoWidthMature() {
    this.router.navigate(['/resources/glossary'], {fragment: 'plant_width_mature'});
  }
  gotoHardiness() {
    this.router.navigate(['/resources/glossary'], {fragment: 'hardiness_zones'});
  }
  gotoLight() {
    this.router.navigate(['/resources/glossary'], {fragment: 'light_requirements_full_sun'});
  }
  gotoWater() {
    this.router.navigate(['/resources/glossary'], {fragment: 'watering_drought_tolerant'});
  }

  gotoPlantDetails(plantid) {
    this.router.navigateByUrl('/search/plant-details/' + plantid);
  }

  editPlant() {
    this.router.navigateByUrl('/plant/edit/' + this.currentPlantId);
  }

  getCombos(linksString) {
    // split the string into multiple strings (comma separated)
    const linkArray = linksString.split(',');
    if (linkArray.length > 0) {
      linkArray.forEach((aLink) => {
        const regexp = /(\d+)">([^<]+)/gm;
        let m;

        while ((m = regexp.exec(aLink)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regexp.lastIndex) {
            regexp.lastIndex++;
          }

          const newLink = {plantId: null, name: null};
          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            switch (groupIndex) {
              case 1:
                newLink.plantId = match;
                break;
              case 2:
                newLink.name = match;
                break;
            }
          });
          this.comboArray.push(newLink);
        }
      });
    }
  }
  feet_to_feet_inches(infeet) {
    if (infeet == null) {
      return '0 ft. 0 in.';
    } else {
      // check if infeet starts with a dot (such as .5 rather than 0.5)
      if (infeet.charAt(0) === '.') {
        infeet = '0' + infeet;
      }
      const feet = parseFloat(infeet);
      const inches = feet * 12;
      const feetInInches = Math.floor(inches / 12);
      const feetRound = Math.floor(feet);
      const newInches = Math.round(inches - (12 * feetInInches));
      return feetRound + ' ft. ' + newInches + ' in.';
    }
  }
  feet_to_meters(feet) {
    const meters = feet * 0.3048;
    return meters.toFixed(1);  // limit to one decimal place for plant list results page
  }

  feet_to_meters2(infeet) {
    if (infeet == null) {
      return 0.00;
    } else {
      // check if infeet starts with a dot (such as .5 rather than 0.5)
      if (infeet.charAt(0) === '.') {
        infeet = '0' + infeet;
      }
      const feet = parseFloat(infeet);
      const meters = feet * 0.3048;
      return meters.toFixed(2);
    }
  }

}
