import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  gotoResources() {
    this.router.navigateByUrl('/resources');
  }

  gotoGlossary() {
    this.router.navigateByUrl('/resources/glossary');
  }
  gotoLinks() {
    this.router.navigateByUrl('/resources/links');
  }
  gotoNursery() {
    this.router.navigateByUrl('/resources/nurserylist');
  }
}
