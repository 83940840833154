import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  gotoAbout() {
    this.router.navigateByUrl('/about');
  }
  gotoAboutCriteria() {
    this.router.navigateByUrl('/about/criteria');
  }
  gotoAboutCommittee() {
    this.router.navigateByUrl('/about/committee');
  }
  gotoAboutGroups() {
    this.router.navigateByUrl('/about/groups');
  }
  gotoAboutStaff() {
    this.router.navigateByUrl('/about/staff');
  }
  gotoAboutExample1() {
    this.router.navigateByUrl('/search/plant-details/266');
  }
  gotoAboutExample2() {
    this.router.navigateByUrl('/search/plant-details/727');
  }
  gotoAboutExample3() {
    this.router.navigateByUrl('/search/plant-details/1566');
  }
}
