<div class="home-image" style="background-image: url(../../assets/plants/clematis-montana-tetrarose.jpg)">	
</div> 
<div class="wrapper">
	 <div class="content">
    <div class="text-wrap">

  <div class="link-container">
    <form [formGroup]="form">
      <fieldset>
        <legend>Administrative Login</legend>
        <div class="form-field">
          <label>Email: </label>
          <input name="email" formControlName="email" class="login">
        </div>
        <div class="form-field">
          <label>Password: </label>
          <input name="password" formControlName="password"
                 type="password"  class="login">
        </div>
      </fieldset>
      <div class="form-buttons">
        <button class="button button-primary"
                (click)="login()">LOGIN
        </button>
      </div>
    </form>
  </div>
</div>
</div>
		 </div>