<div class="home-image" style="background-image: url(../../assets/plants/Camassia-quamash-RAS.jpg)">
</div> 
<div class="wrapper">
  <div class="breadcrumbs">
    <ul>
      <li><a [routerLink]="" id="current" (click)="gotoResources()">Resources</a></li>
      <li><a [routerLink]="" (click)="gotoNursery()">Nursery Directory</a></li>
      <li><a [routerLink]="" (click)="gotoLinks()">Helpful Links &amp; Resources</a></li>
      <li><a [routerLink]="" (click)="gotoGlossary()">Glossary of Common Terms</a></li>
    </ul>
  </div><!-- end breadcrumbs -->
  <h2>Resources &amp; Links</h2>
  <div class="link-container">
    <a [routerLink]="" (click)="gotoNursery()">
      <div class="grid third box-links bg-image link1">
        <h3>Nursery List</h3>
      </div>
    </a>
    <a [routerLink]="" (click)="gotoLinks()">
      <div class="grid third box-links bg-image link2">
        <h3>Helpful Links</h3>
      </div>
    </a>
    <a [routerLink]="" (click)="gotoGlossary()">
      <div class="grid third box-links bg-image link3">
        <h3>Glossary of Common Terms</h3>
      </div>
    </a>

  </div>
</div>

