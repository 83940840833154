import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PlantService } from '../services/plant.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Plant } from '../models/plant.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('searchButton', { static: true }) searchButtonElement: ElementRef;

  options = [];
  basicSearchTerms = '';
  keyword = 'common_name';
  placeholder = 'Enter botanical or common name';

  constructor(private router: Router,
              private plantService: PlantService,
              public  authService: AuthService,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }

  gotoHome() { this.router.navigateByUrl('/'); }
  gotoAbout() { this.router.navigateByUrl('/about'); }
  gotoSearch() { this.router.navigateByUrl('/searchnav'); }
  gotoAdvSearch() { this.router.navigateByUrl('/search/advanced'); }
  gotoResources() { this.router.navigateByUrl('/resources'); }
  gotoPress() { this.router.navigateByUrl('/press'); }
  gotoContact() { this.router.navigateByUrl('/contact'); }
  gotoGlossary() { this.router.navigateByUrl('/resources/glossary'); }
  gotoPlantNotListed() { this.router.navigateByUrl('/search/plantNotListed'); }

  gotoListByTheme(inTheme) {
    this.plantService.searchCommonOptions = '';
    this.plantService.advancedSearchOptions = '&theme=' + encodeURI(inTheme);
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }
  gotoListByType(inType) {
    this.plantService.searchCommonOptions = '';
    this.plantService.advancedSearchOptions = '&plantType=' + encodeURI(inType);
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }
  gotoListByOptions(...args: any[]) {
    let searchString = '';
    for (let i = 0; i < args.length; i = i + 2) {
      searchString += '&' + args[i] + '=' + encodeURI(args[i + 1]);
    }
    this.plantService.searchCommonOptions = '';
    this.plantService.advancedSearchOptions = searchString;
    this.plantService.searchCategory = '';
    this.router.navigateByUrl('/search/results');
  }
  basicSearch() {
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.plantService.searchCommonOptions = encodeURI(this.basicSearchTerms);
    this.router.navigateByUrl('/search/results');
  }
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.plantService.advancedSearchOptions = '';
    this.plantService.searchCategory = '';
    this.basicSearchTerms = val;
    this.plantService.searchCommonOptions = this.basicSearchTerms;
    if (val.length > 0) {
      this.plantService.getPlantAutoComplete(this.authService.isLoggedIn())
        .subscribe((res) => {
          this.options = res.plants;
        });
    } else {
      this.options = [];
    }
  }

  selectEvent(item) {
    // do something with selected item
    setTimeout(() => {
      this.searchButtonElement.nativeElement.click();
    }, 500);
    this.options = [];
  }

  onFocused(e){
    // do something when input is focused
  }
}
