import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Nursery } from '../models/nursery_model';
import { NurseryService } from '../services/nursery.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nursery-list',
  templateUrl: './nursery-list.component.html',
  styleUrls: ['./nursery-list.component.scss']
})
export class NurseryListComponent implements OnInit {

  @ViewChild('topContent', { static: true }) topElement: ElementRef;
  waNurseries: Nursery[];
  orNurseries: Nursery[];
  bcNurseries: Nursery[];
  otherNurseries: Nursery[];
  allNurseries: Nursery[];
  waNameActive: boolean;
  orNameActive: boolean;
  bcNameActive: boolean;
  otherNameActive: boolean;
  waLocationActive: boolean;
  orLocationActive: boolean;
  bcLocationActive: boolean;
  otherLocationActive: boolean;

  constructor(private nurseryService: NurseryService,
              private router: Router,
              public authService: AuthService,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.waNurseries = [];
    this.orNurseries = [];
    this.bcNurseries = [];
    this.otherNurseries = [];
    this.waNameActive = true;
    this.waLocationActive = false;
    this.orNameActive = true;
    this.orLocationActive = false;
    this.bcNameActive = true;
    this.bcLocationActive = false;
    this.otherNameActive = true;
    this.otherLocationActive = false;
    this.nurseryService.getNurseryByState('', this.authService.isLoggedIn())
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.allNurseries = res.nurseries;
          this.allNurseries.forEach((aNursery) => {
            switch (aNursery.state_province) {
              case 'WA':
                this.waNurseries.push(aNursery);
                break;
              case 'OR':
                this.orNurseries.push(aNursery);
                break;
              case 'BC':
                this.bcNurseries.push(aNursery);
                break;
              default:
                this.otherNurseries.push(aNursery);
                break;
            }
          });
        });
      });
  }

  goBackToTop() {
    // scroll to top
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }
  gotoGlossary() {
    this.router.navigateByUrl('/resources/glossary');
  }
  gotoLinks() {
    this.router.navigateByUrl('/resources/links');
  }
  gotoNursery() {
    this.router.navigateByUrl('/resources/nurserylist');
  }
  gotoResources() {
    this.router.navigateByUrl('/resources');
  }

  sortByName(nurseryList, whichList) {
    this.changeSortOrder(whichList, true);
    nurseryList.sort((aNursery, bNursery) => {
      return aNursery.nursery_name.localeCompare(bNursery.nursery_name);
    });
  }
  sortByLocation(nurseryList, whichList) {
    this.changeSortOrder(whichList, false);
    nurseryList.sort((aNursery, bNursery) => {
      return aNursery.city.localeCompare(bNursery.city);
    });
  }

  changeSortOrder(whichList, byName) {
    switch (whichList) {
      case 'wa':
        this.waNameActive = byName;
        this.waLocationActive = !byName;
        break;
      case 'or':
        this.orNameActive = byName;
        this.orLocationActive = !byName;
        break;
      case 'bc':
        this.bcNameActive = byName;
        this.bcLocationActive = !byName;
        break;
      case 'other':
        this.otherNameActive = byName;
        this.otherLocationActive = !byName;
        break;
    }

  }

  editNursery(nurseryId) {
    this.router.navigateByUrl('/nursery/edit/' + nurseryId);
  }
}
