<div class="home-image" style="background-image: url(../../assets/plants/athyrium-otophorum.jpg)">	  
	<h1>GPP Advisors</h1>
</div> 
<div class="wrapper">
     <div class="breadcrumbs">
<ul>

        <li><a [routerLink]="" (click)="gotoAbout()">About GPP</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCriteria()">Selection Criteria</a></li>
        <li><a [routerLink]="" (click)="gotoAboutCommittee()">Selection Committees</a></li>
        <li><a [routerLink]="" id="current">Advisors</a></li>
        <li><a [routerLink]="" (click)="gotoAboutStaff()">GPP Staff</a></li>
</ul>
      </div><!-- end breadcrumbs -->
      
		  <div class="content">
		 <div class="text-wrap">
      
      <p>For very large groups of plants such as ferns, rhododendrons, or roses, GPP contacts specialty plant experts and works with them to prepare a list of recommendations for GPP selections.</p>

      <h2>Current active Advisors include:</h2>

      <h3>Clematis</h3>
      <ul>
        <li><em>Linda Beutler</em>, Friends of the Rogerson Clematis Collection, Oregon</li>
        <li><em>Maurice Horn</em>, Joy Creek Nursery, Oregon</li>
      </ul>
			 
	<h3>Ferns</h3>
      <ul>
        <li><em>Michelle Bundy</em>, Casa Flora Nursery, Washington</li>
        <li><em>Kirk Hansen &amp; Thomas Johnson</em>, Sebright Nursery, Oregon</li>
		  <li><em>Sue Olsen</em>, Foliage Gardens Nursery, Washington</li>
		  <li><em>Richie Steffen</em>, Elisabeth C. Miller Botanical Garden, Washington</li>
		  <li><em>John van den Meerendonk</em>, Botanica Landscaping - retired, Washington</li>
      </ul>		 
  </div>
</div>
	</div><!-- .wrapper -->